import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { getFilterOptions } from '../../Helpers/filterHelpers';
import { UserContext } from '../../Contexts/UserContext';
import { useFormik } from 'formik';
import { InputCheckbox, BlockButton } from '../Common/Inputs';

const FilterPanel = ({ showFilter, setShowFilter, data, dataRecent, filter, setFilter }) => {
  const [formData, setFormData] = useState({});
  const [filterOptions, setFilterOptions] = useState({});
  const { level1Name, level2Name, hasLevel1, hasLevel2 } = useContext(UserContext);

  useEffect(() => {
    const temp = [...data, ...(dataRecent || [])];
    setFilterOptions(getFilterOptions(temp));

    const initial = {};
    if (filter) {
      filter.level1s.forEach((x) => {
        initial[`level1_${x}`] = true;
      });
      filter.level2s.forEach((x) => {
        initial[`level2_${x}`] = true;
      });
      filter.bys.forEach((x) => {
        initial[`by_${x}`] = true;
      });
    }
    setFormData(initial);
  }, [data, dataRecent, filter]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData,
    onSubmit: async (values) => {
      const filter = {
        level1s: [],
        level2s: [],
        bys: [],
      };

      if (values.SubmitType === 'Apply') {
        for (const [k, v] of Object.entries(values)) {
          if (v) {
            const parts = k.split('_');

            switch (parts[0]) {
              case 'level1':
                filter.level1s.push(parts[1]);
                break;
              case 'level2':
                filter.level2s.push(parts[1]);
                break;
              case 'by':
                filter.bys.push(parts[1]);
                break;
              default:
                break;
            }
          }
        }
        localStorage.setItem('DT_Filter', JSON.stringify(filter));
        localStorage.setItem('DT_FilterOptions', JSON.stringify(filterOptions));
      } else {
        localStorage.removeItem('DT_Filter');
        localStorage.removeItem('DT_FilterOptions');
      }

      if (setFilter) {
        setFilter(filter);
      }

      formik.handleReset();
      setShowFilter(false);
    },
  });

  const handleSubmitButton = (type) => () => {
    formik.setFieldValue('SubmitType', type, false);
    formik.handleSubmit();
  };

  return (
    <Transition.Root show={showFilter} as={Fragment}>
      <Dialog as="div" static className="fixed inset-0 overflow-hidden z-60" open={showFilter} onClose={setShowFilter}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full">
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col py-4 sm:py-6 bg-white shadow-xl">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">Filter Deadlines</Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={() => setShowFilter(false)}>
                          <span className="sr-only">Close panel</span>
                          <i className="fad fa-fw fa-times fa-lg text-indigo-800 hover:text-indigo-600" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 flex px-4 sm:px-6 overflow-y-auto">
                    <div className="flex flex-grow flex-col">
                      <div className="flex-grow overflow-y-auto ">
                        <form>
                          {filterOptions && filterOptions.level1s && (
                            <>
                              {hasLevel1 && level1Name && (
                                <>
                                  <h3 className="text-lg leading-6 mt-8 mb-4 font-medium text-gray-900 border-b border-indigo-700">{level1Name} Filter</h3>
                                  {filterOptions.level1s.length > 0 ? (
                                    <div>
                                      <div className="mt-4 sm:mt-0 sm:col-span-2">
                                        <div className="max-w-lg space-y-4">
                                          {filterOptions.level1s.map((i) => (
                                            <InputCheckbox key={i.id} formik={formik} label={i.name} field={`level1_${i.id}`} />
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="mt-4 sm:mt-0 sm:col-span-2">
                                      <div className="max-w-lg space-y-4">None available</div>
                                    </div>
                                  )}
                                </>
                              )}
                              {hasLevel2 && level2Name && (
                                <>
                                  <h3 className="text-lg leading-6 mt-8 mb-4 font-medium text-gray-900 border-b border-indigo-700">{level2Name} Filter</h3>
                                  {filterOptions.level2s.length > 0 ? (
                                    <div>
                                      <div className="mt-4 sm:mt-0 sm:col-span-2">
                                        <div className="max-w-lg space-y-4">
                                          {filterOptions.level2s.map((i) => (
                                            <InputCheckbox key={i.id} formik={formik} label={i.name} field={`level2_${i.id}`} />
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="mt-4 sm:mt-0 sm:col-span-2">
                                      <div className="max-w-lg space-y-4">None available</div>
                                    </div>
                                  )}
                                </>
                              )}
                              {filterOptions.byNames.length > 0 && (
                                <>
                                  <h3 className="text-lg leading-6 mt-8 mb-4 font-medium text-gray-900 border-b border-indigo-700">By Filter</h3>
                                  <div>
                                    <div className="mt-4 sm:mt-0 sm:col-span-2">
                                      <div className="max-w-lg space-y-4">
                                        <InputCheckbox formik={formik} label="(Unassigned)" field="by_(Unassigned)" />
                                        {filterOptions.byNames.map((i) => (
                                          <InputCheckbox key={i.id} formik={formik} label={i.name} field={`by_${i.id}`} />
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </form>
                      </div>
                      <div className="flex-grow-0 mt-4 inline-flex w-full space-x-1">
                        <BlockButton text="Apply" icon="filter" type="button" groupPosition="left" onClick={handleSubmitButton('Apply')} />
                        <BlockButton text="Clear" icon="eraser" type="button" groupPosition="right" onClick={handleSubmitButton('Clear')} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FilterPanel;

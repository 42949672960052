import React from 'react';
import BlockButton from '../Common/BlockButton';
import PageTitle from '../Common/PageTitle';

const HelpSubscriptionChoose = () => {
  return (
    <>
      <PageTitle title="Help : Change Subscription" />
      <div className="help-card">
        <article className="help">
          <div className="help-title">Change Subscription Details</div>
          <div>To pick a subscription after your free trial has expired or to upgrade from a free subscription:</div>
          <div>
            1. In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-sliders-v" />
              Admin
            </div>
            then
            <div className="help-nav">
              <i className="fad fa-file-certificate" />
              Subscription Details
            </div>
          </div>
          <div>2. This will take you to a page detailing what subscription package you are on, the monthly charge, when the next payment is due under expiry date and the features of that package.</div>
          <div>
            3. To change the subscription package, click
            <div className="help-button">
              <BlockButton text="Purchase Subscription" icon="credit-card" type="button" mt={0} buttonSize="small" />
            </div>
          </div>
          <div>
            4. Select which package you desire using the
            <div className="help-button-small">
              <BlockButton text="Select" type="button" mt={0} buttonSize="small" />
            </div>
            button.
          </div>
          <div>
            5. If you have chosen the wrong package then you can click the
            <div className="help-button">
              <BlockButton text="Alternatives" type="button" mt={0} buttonSize="small" />
            </div>
            button to re-choose, otherwise enter your card details.
          </div>
        </article>
      </div>
    </>
  );
};

export default HelpSubscriptionChoose;

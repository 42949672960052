import { apiUrl } from '../config';
import axios from 'axios';
import { formattedDate } from '../Helpers/dateHelpers';

export const apiDeadlineInstances = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/User/DeadlineInstances',
    baseURL: apiUrl(),
  });

  return result.data || [];
};

export const apiDeadlineInstancesMonth = async (fromDate) => {
  const result = await axios({
    method: 'get',
    url: 'v1/User/DeadlineInstances/Month',
    baseURL: apiUrl(),
    params: {
      fromDate,
    },
  });

  return result.data || [];
};

export const apiDeadlineInstancesRecent = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/User/DeadlineInstances',
    baseURL: apiUrl(),
    params: { recent: true },
  });

  return result.data || [];
};

export const apiDeadlineInstancesCompleted = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/User/DeadlineInstances',
    baseURL: apiUrl(),
    params: { complete: true },
  });

  return result.data || [];
};

export const apiDeadlineInstanceComplete = async (deadline) => {
  await axios({
    method: 'post',
    url: 'v1/User/DeadlineInstance/Complete',
    baseURL: apiUrl(),
    data: {
      id: deadline.deadlineInstanceId,
      deadlineId: deadline.deadlineId,
    },
  });
};

export const apiDeadlineInstanceStartNow = async (deadline) => {
  await axios({
    method: 'post',
    url: 'v1/User/DeadlineInstance/StartNow',
    baseURL: apiUrl(),
    data: {
      id: deadline.deadlineInstanceId,
      deadlineId: deadline.deadlineId,
    },
  });
};

export const apiDeadlineInstanceDelete = async (deadline) => {
  await axios({
    method: 'post',
    url: 'v1/User/DeadlineInstance/Delete',
    baseURL: apiUrl(),
    data: {
      id: deadline.deadlineInstanceId,
      deadlineId: deadline.deadlineId,
    },
  });
};

export const apiDeadlineInstanceReactivate = async (deadline) => {
  await axios({
    method: 'post',
    url: 'v1/User/DeadlineInstance/Reactivate',
    baseURL: apiUrl(),
    data: {
      id: deadline.deadlineInstanceId,
      deadlineId: deadline.deadlineId,
    },
  });
};

export const apiDeadlineDelete = async (deadline) => {
  await axios({
    method: 'post',
    url: 'v1/Manager/Deadline/Delete',
    baseURL: apiUrl(),
    data: {
      Id: deadline.deadlineId,
    },
  });
};

export const apiDeadlineAssign = async (deadline, assignTo) => {
  await axios({
    method: 'post',
    url: 'v1/Manager/Deadline/Assign',
    baseURL: apiUrl(),
    data: {
      Id: deadline.deadlineId,
      assignTo,
    },
  });
};

export const apiDeadlineInstanceAssign = async (deadline, assignTo) => {
  await axios({
    method: 'post',
    url: 'v1/Manager/DeadlineInstance/Assign',
    baseURL: apiUrl(),
    data: {
      id: deadline.deadlineInstanceId,
      deadlineId: deadline.deadlineId,
      assignTo,
    },
  });
};

export const apiDeadlineInstanceSnooze = async (deadline, snoozeDate) => {
  snoozeDate = formattedDate(snoozeDate);

  await axios({
    method: 'post',
    url: 'v1/User/DeadlineInstance/Snooze',
    baseURL: apiUrl(),
    data: {
      id: deadline.deadlineInstanceId,
      deadlineId: deadline.deadlineId,
      snoozeDate,
    },
  });
};

export const apiDeadlineInstanceExtend = async (deadline, newDeadlineDate) => {
  newDeadlineDate = formattedDate(newDeadlineDate);

  await axios({
    method: 'post',
    url: 'v1/User/DeadlineInstance/Extend',
    baseURL: apiUrl(),
    data: {
      id: deadline.deadlineInstanceId,
      deadlineId: deadline.deadlineId,
      newDeadlineDate,
    },
  });
};

export const apiDeadlineInstanceTag = async (deadline, tag) => {
  await axios({
    method: 'post',
    url: 'v1/User/DeadlineInstance/Tag',
    baseURL: apiUrl(),
    data: {
      id: deadline.deadlineInstanceId,
      deadlineId: deadline.deadlineId,
      tag,
    },
  });
};

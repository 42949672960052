import {
  apiDeadlineAssign,
  apiDeadlineDelete,
  apiDeadlineInstanceAssign,
  apiDeadlineInstanceComplete,
  apiDeadlineInstanceDelete,
  apiDeadlineInstanceExtend,
  apiDeadlineInstanceReactivate,
  apiDeadlineInstanceSnooze,
  apiDeadlineInstanceStartNow,
  apiDeadlineInstanceTag,
} from '../../../APIs/dashboard';

export const processAction = async (deadline, actionName, extra) => {
  switch (actionName) {
    case 'Done':
      await apiDeadlineInstanceComplete(deadline);
      break;
    case 'StartNow':
      await apiDeadlineInstanceStartNow(deadline);
      break;
    case 'DeleteOne':
      await apiDeadlineInstanceDelete(deadline);
      break;
    case 'Reactivate':
      await apiDeadlineInstanceReactivate(deadline);
      break;
    case 'DeleteAll':
      await apiDeadlineDelete(deadline);
      break;
    case 'Unsnooze':
      await apiDeadlineInstanceSnooze(deadline, null);
      break;
    case 'Snooze':
      await apiDeadlineInstanceSnooze(deadline, extra);
      break;
    case 'Extend':
      await apiDeadlineInstanceExtend(deadline, extra);
      break;
    case 'AssignAll':
      await apiDeadlineAssign(deadline, extra);
      break;
    case 'AssignOne':
      await apiDeadlineInstanceAssign(deadline, extra);
      break;
    case 'Tag':
      await apiDeadlineInstanceTag(deadline, extra);
      break;
    default:
      console.error(`Unknown Action: ${actionName}`);
      break;
  }
};

import React from 'react';

const FontAwesomeButton = ({ onClick, text, icon, spin, fullWidth, size, type, center, colour, m = 2, extraClasses = '', textAlways = false }) => {
  return (
    <button onClick={onClick} className={`${colour || 'text-indigo-800'} action-button pl-0 pr-1 mx-${m} ${fullWidth ? 'w-full' : ''} ${center ? 'text-center' : 'text-left'} ${extraClasses}`} type={type}>
      <i className={`fad fa-fw fa-${icon} ${size ? `fa-${size}` : 'fa-lg'} mr-0 ${spin ? 'fa-spin' : ''}`} />
      {text && <span className={textAlways ? 'px-1' : 'hidden md:inline-block md:px-1'}>{text}</span>}
    </button>
  );
};

export default FontAwesomeButton;

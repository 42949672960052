import React, { useState, useEffect } from 'react';

const CardErrors = ({ errors }) => {
  const [errorsArray, setErrorsArray] = useState([]);

  useEffect(() => {
    if (!errors) {
      setErrorsArray([]);
      return;
    }

    if (errors.errors) {
      setErrorsArray(errors.errors);
      return;
    }

    if (typeof errors === 'string') {
      setErrorsArray([errors]);
      return;
    }

    setErrorsArray(errors);
  }, [errors]);

  return (
    <>
      {errors && (
        <>
          <div className="bg-red-100 text-red-700 p-3 relative -mx-2 my-2" role="alert">
            <strong className="font-bold block">Error</strong>
            {errorsArray.map((item, index) => {
              return (
                <p className="text-red-600" key={index}>
                  {typeof item === 'string' ? item : item.message ?? item.description ?? item.errorMessage}
                </p>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default CardErrors;

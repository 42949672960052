import React, { Fragment } from 'react';
import FontAwesomeButton from './FontAwesomeButton';

const InputSelect = ({ formik, label, optionalLabel, field, disabled = false, data, outerClassName = '', optional = true, valueField = 'id', textField = 'name', onChangeExtra, postShow = false, postIcon, postClick, hintText }) => {
  const onChangeHandle = (e) => {
    formik.handleChange(e);
    if (onChangeExtra) {
      onChangeExtra(e);
    }
  };

  return (
    <div className={outerClassName}>
      <label className="input-label" htmlFor={field}>
        {label}
      </label>
      <div className="input-text">
        <select className="focus:outline-none" id={field} {...formik.getFieldProps(field)} disabled={disabled} onChange={(e) => onChangeHandle(e)}>
          {optional && <option value="">{optionalLabel || label}</option>}
          {data.map((item, i) => (
            <Fragment key={i}>{typeof item === 'string' ? <option value={item}>{item}</option> : <option value={item[valueField]}>{item[textField]}</option>}</Fragment>
          ))}
        </select>
        {postShow && <FontAwesomeButton type="button" icon={postIcon} onClick={postClick} m={0} size="2x" extraClasses="py-1" />}
      </div>
      {hintText && <div className="text-xs text-indigo-700 text-right -mt-3 mb-3">{hintText}</div>}
      {formik.touched[field] && formik.errors[field] ? <div className="input-error">{formik.errors[field]}</div> : null}
    </div>
  );
};

export default InputSelect;

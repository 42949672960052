import React from 'react';

const InputCheckbox = ({ formik, label, field, disabled = false, rightJustify = false, outerClassName = '', children }) => {
  return (
    <>
      <div className={`flex items-center ${rightJustify ? 'flex-row-reverse' : ''} ${outerClassName}`}>
        <input id={field} type="checkbox" className="form-checkbox ml-2 my-1 h-6 w-6 text-indigo-800 transition duration-150 ease-in-out" {...formik.getFieldProps(field)} defaultChecked={formik.values[field]} disabled={disabled} />
        <label htmlFor={field} className="ml-2 block text-sm leading-5 text-gray-900">
          {label}
          {children}
        </label>
      </div>
      {formik.touched[field] && formik.errors[field] ? <div className="input-error my-0">{formik.errors[field]}</div> : null}
    </>
  );
};

export default InputCheckbox;

import React from 'react';
import PageTitle from '../Common/PageTitle';

const HelpRoles = () => {
  return (
    <>
      <PageTitle title="Help : Roles" />
      <div className="help-card">
        <article className="help">
          <div className="help-title">Roles</div>
          <div>There are 3 types of user roles which can be allocated to users</div>
          <div className="help-subtitle">Admin</div>
          <div>
            This user can see all levels and categories, they can add, edit, delete, complete, extend and snooze all level categories and deadlines, they can assign users to deadlines, they can change or cancel subscription settings, they can set
            their personal preferences in settings, they can create and disable new users
          </div>
          <div className="help-subtitle">Manager</div>
          <div>
            This user can see the levels and categories they have been given access to only, they can add, edit, delete, complete, extend and snooze level categories and deadlines that they have been assigned too, they can assign users to deadlines ,
            they can set their personal preferences in settings
          </div>
          <div className="help-subtitle">User</div>
          <div>
            This user can see the levels and categories they have been given access to only, they can delete this occurrence only, complete, extend and snooze level categories and deadlines that they have been assigned too, they can set their
            personal preferences in settings
          </div>
          <div className="help-subtitle">Permissions</div>
          <div>
            <table className="stripe w-full lg:w-3/4 xl:w-1/2 border-b border-primary">
              <thead>
                <tr className="bg-indigo-800 text-indigo-50">
                  <th className="w-auto p-2 rounded-tl-md"></th>
                  <th className="w-auto p-2">Admin</th>
                  <th className="w-auto p-2">Manager</th>
                  <th className="w-auto p-2 rounded-tr-md">User</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="p-4 text-left">Manage Categories</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Manage Users</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Manage User Permissions</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Change Subscription Details</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Manage Deadlines</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Complete, Extend or Snooze a Deadline</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Delete a Deadline Instance</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Delete a Deadline (All Instances)</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Assign a User to a Deadline</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">See All Deadline Instances</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-red-500 fa-2x fa-times-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">See Only Permitted Deadlines</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                </tr>
                <tr>
                  <th className="p-4 text-left">Set Personal Email Preferences</th>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                  <td className="p-4 text-center">
                    <i className="fad text-green-500 fa-2x fa-check-circle" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </div>
    </>
  );
};

export default HelpRoles;

import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext';

const SubscriptionUpgradeRequired = ({ match, history }) => {
  const { userIsAdmin } = useContext(UserContext);

  const handleUpgradeClick = () => {
    history.push('/subscription/change');
  };

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Subscription Limit Reached</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm leading-5 text-gray-500">
            {match.params.reason === 'deadline-limit' && <p>Maximum of Deadlines Reached</p>}
            {match.params.reason === 'user-limit' && <p>Maximum of Users Reached</p>}
          </div>
          {userIsAdmin && (
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              <span className="inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={handleUpgradeClick}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                  Upgrade
                </button>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(SubscriptionUpgradeRequired);

import React from 'react';
import SubscriptionOption from './SubscriptionOption';
import { licenseTypes } from '../../Helpers/constants';
import PageTitle from '../Common/PageTitle';

export default function SubscriptionOptions({ onSelect, selected, excludeFree }) {
  return (
    <>
      {selected ? (
        <PageTitle title="Subscription" />
      ) : (
        <PageTitle title="Subscription">
          <div>The right option for you, whoever you are</div>
        </PageTitle>
      )}
      <div className="mt-4 pb-4 bg-gray-200 sm:mt-6 sm:pb-6 lg:mt-8 lg:pb-8">
        <div className="relative">
          <div className="absolute inset-0 h-3/4 bg-gray-200"></div>
          <div className="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-md mx-auto md:max-w-5xl md:grid md:grid-cols-2 md:gap-5">
              {licenseTypes.map((item, index) => (
                <SubscriptionOption key={index} data={item} onSelect={onSelect} selected={selected} disabled={item.id === '1' && excludeFree} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

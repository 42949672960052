import React from 'react';
import moment from 'moment';

export const calculateDaysInMonth = (month) => {
  return new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();
};

export const calculateSpacerDays = (month) => {
  let dayNum = month.getDay();
  if (dayNum === 0) {
    dayNum = 7;
  }
  return dayNum - 1;
};

export const getSpacerClass = (i) => {
  return i < 6 ? 'day-spacer-tile' : 'weekend-spacer-tile';
};

export const getDayClass = (d, month, day) => {
  switch (new Date(month.getFullYear(), month.getMonth(), d).getDay()) {
    case 0:
    case 6:
      return 'weekend-tile' + (d === day ? ' day-selected' : '');
    default:
      return 'day-tile' + (d === day ? ' day-selected' : '');
  }
};

export const getDayDataClass = (d, month, day) => {
  switch (new Date(month.getFullYear(), month.getMonth(), d).getDay()) {
    case 0:
    case 6:
      return 'weekend-data';
    default:
      return 'day-data';
  }
};

export const filterData = (data, by, level1, level2) => {
  return data
    .filter((x) => !by || x.assignedToDisplayName === by || (by === '(Unassigned)' && !x.assignedToDisplayName))
    .filter((x) => level1 === 'All' || level1 === (x.level1Name ?? ''))
    .filter((x) => level2 === 'All' || level2 === (x.level2Name ?? ''));
};

export const filterData2 = (data, filter) => {
  return data.filter((item) => {
    if (!filter) {
      return true;
    }

    if (filter.level1s.length > 0 && !filter.level1s.includes(item.level1Id) && !filter.level1s.includes(item.level1GroupName)) {
      return false;
    }

    if (filter.level2s.length > 0 && !filter.level2s.includes(item.level2Id) && !filter.level2s.includes(item.level2GroupName)) {
      return false;
    }

    if (filter.bys.length > 0) {
      if (!item.assignedTo && filter.bys.includes('(Unassigned)')) {
        return true;
      }
      if (filter.bys.includes(item.assignedTo)) {
        return true;
      }

      return false;
    }

    return true;
  });
};

export const dayFilteredData = (day, month, data, by, level1, level2) => {
  return filterData(data, by, level1, level2).filter(
    (x) =>
      moment(x.deadlineDate).isSame(new Date(month.getFullYear(), month.getMonth(), day)) ||
      moment(x.warnDate).isSame(new Date(month.getFullYear(), month.getMonth(), day)) ||
      moment(x.openDate).isSame(new Date(month.getFullYear(), month.getMonth(), day)) ||
      moment(x.snoozeDate).isSame(new Date(month.getFullYear(), month.getMonth(), day))
  );
};

export const dayFilteredData2 = (day, month, data, filter) => {
  return filterData2(data, filter).filter(
    (x) =>
      moment(x.deadlineDate).isSame(new Date(month.getFullYear(), month.getMonth(), day)) ||
      moment(x.warnDate).isSame(new Date(month.getFullYear(), month.getMonth(), day)) ||
      moment(x.openDate).isSame(new Date(month.getFullYear(), month.getMonth(), day)) ||
      moment(x.snoozeDate).isSame(new Date(month.getFullYear(), month.getMonth(), day))
  );
};

export const Counts = ({ d, data, month, by, level1, level2 }) => {
  const tempData = filterData(data, by, level1, level2);

  const dCount = tempData.filter((x) => moment(x.deadlineDate).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;
  const wCount = tempData.filter((x) => moment(x.warnDate).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;
  const oCount = tempData.filter((x) => moment(x.openDate).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;
  const sCount = tempData.filter((x) => x.snoozeDate && moment(x.snoozeDate).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;

  return (
    <>
      {dCount > 0 && <div className="text-red-700 font-mono px-1 sm:px-2 h-6 flex items-center">{dCount}</div>}
      {wCount > 0 && <div className="text-orange-500 font-mono px-1 sm:px-2 h-6 flex items-center">{wCount}</div>}
      {oCount > 0 && <div className="text-green-700 font-mono px-1 sm:px-2 h-6 flex items-center">{oCount}</div>}
      {sCount > 0 && <div className="text-gray-600 font-mono px-1 sm:px-2 h-6 flex items-center">{sCount}</div>}
    </>
  );
};

export const Counts2 = ({ d, data, month, filter }) => {
  const tempData = filterData2(data, filter);

  const dCount = tempData.filter((x) => moment(x.deadlineDate).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;
  const wCount = tempData.filter((x) => moment(x.warnDate).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;
  const oCount = tempData.filter((x) => moment(x.openDate).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;
  const sCount = tempData.filter((x) => x.snoozeDate && moment(x.snoozeDate).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;

  return (
    <>
      {dCount > 0 && <div className="text-red-700 font-mono px-1 sm:px-2 h-6 flex items-center">{dCount}</div>}
      {wCount > 0 && <div className="text-orange-500 font-mono px-1 sm:px-2 h-6 flex items-center">{wCount}</div>}
      {oCount > 0 && <div className="text-green-700 font-mono px-1 sm:px-2 h-6 flex items-center">{oCount}</div>}
      {sCount > 0 && <div className="text-gray-600 font-mono px-1 sm:px-2 h-6 flex items-center">{sCount}</div>}
    </>
  );
};

export const anyHolidays = (d, month, holidays, bankHolidays) => {
  const bhCount = bankHolidays.filter((x) => moment(x).isSame(new Date(month.getFullYear(), month.getMonth(), d))).length;
  const hCount = holidays.filter((x) => moment(new Date(month.getFullYear(), month.getMonth(), d)).isBetween(x.fromDate, x.toDate, 'day', '[]')).length;
  return hCount + bhCount > 0;
};

import React, { useState, useEffect, useContext } from 'react';
import LoadingCard from '../Common/LoadingCard';
import { licenseTypes } from '../../Helpers/constants';
import BlockButton from '../Common/BlockButton';
import { UserContext } from '../../Contexts/UserContext';
import { apiOrganisationMine } from '../../APIs/organisation';
import swal from 'sweetalert';
import { apiLicenseChange } from '../../APIs/license';

const SubscriptionChange = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const { licenseTypeId, LoadUserSettings } = useContext(UserContext);
  const [current, setCurrent] = useState({});
  const [data, setData] = useState({});
  const [licenseOptions, setLicenseOptions] = useState([]);

  useEffect(() => {
    const LoadData = async () => {
      const result = await apiOrganisationMine();
      setData(result);

      setCurrent(licenseTypes.find((x) => parseInt(x.id) === licenseTypeId));

      const options = [];
      licenseTypes.forEach((x) => {
        const option = {
          ...x,
        };

        if (x.userLimit && x.userLimit < result.currentUsers) {
          option.disallowed = true;
          option.disallowedUser = true;
        }

        if (x.deadlineLimit && x.deadlineLimit < result.currentDeadlines) {
          option.disallowed = true;
          option.disallowedDeadline = true;
        }

        options.push(option);
      });
      setLicenseOptions(options);

      setLoading(false);
    };

    LoadData();
  }, [licenseTypeId]);

  const doSwitch = async (newLicenseTypeId) => {
    setLoading(true);
    await apiLicenseChange(newLicenseTypeId);
    await LoadUserSettings();
    history.push('/subscription');
    setLoading(false);
  };

  const handleSwitch = (newLicenseTypeId) => async () => {
    if (newLicenseTypeId === 1 && data.currentLevels > 0) {
      swal({
        title: 'Are You Sure?',
        text: 'Categories will be removed as they are not supported with a Free Subscription.',
        icon: 'warning',
        buttons: {
          no: { text: 'No', value: false },
          cancelSub: { text: 'Yes', value: true },
        },
      }).then(async (value) => {
        if (value) {
          await doSwitch(newLicenseTypeId);
          return;
        }
      });
    } else {
      await doSwitch(newLicenseTypeId);
      return;
    }
  };

  const SwitchButton = ({ newLicenseType }) => {
    const newLicenseTypeId = parseInt(newLicenseType.id);

    if (newLicenseTypeId === licenseTypeId) {
      return <div className="text-center">Current</div>;
    }

    if (newLicenseType.disallowed) {
      return (
        <div className="text-center text-red-800 fa-2x">
          <i className="fad fa-ban"></i>
        </div>
      );
    }

    return <BlockButton mb={2} text="Switch" onClick={handleSwitch(newLicenseTypeId)} />;
  };

  const LimitText = ({ value, disallowed }) => {
    let className = disallowed ? 'text-red-800 font-extrabold' : '';

    return <span className={className}>{value ? `${value} Max` : 'Unlimited'}</span>;
  };

  return (
    <LoadingCard loading={loading} title="Subscription - Change">
      <div className="overflow-x-auto">
        <table className="w-full stripe">
          <thead>
            <tr className="bg-indigo-800 text-indigo-50">
              <th className="w-auto p-2 text-left rounded-tl-md">Subscription</th>
              <th className="w-auto p-2">Users</th>
              <th className="w-auto p-2">Deadlines</th>
              <th className="w-auto p-2">Categories</th>
              <th className="w-auto p-2">Price+VAT /mo</th>
              <th className="w-auto p-2 rounded-tr-md"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-indigo-700 font-semibold">
              <td className="p-4 font-semibold">Using: {current.name}</td>
              <td className="p-4 text-center">{data.currentUsers}</td>
              <td className="p-4 text-center">{data.currentDeadlines}</td>
              <td className="p-4 text-center">{data.currentLevels > 0 ? 'Yes' : 'No'}</td>
              <td className="p-4 text-center">£ {current.price}</td>
              <td>&nbsp;</td>
            </tr>
            {licenseOptions.map((item) => (
              <tr key={item.id}>
                <td className="p-4 font-semibold">{item.name}</td>
                <td className="p-4 text-center">
                  <LimitText value={item.userLimit} disallowed={item.disallowedUser} />
                </td>
                <td className="p-4 text-center">
                  <LimitText value={item.deadlineLimit} disallowed={item.disallowedDeadline} />
                </td>
                <td className="p-4 text-center">{item.categories ? 'Yes' : 'No'}</td>
                <td className="p-4 text-center">£ {item.price}</td>
                <td>
                  <SwitchButton newLicenseType={item} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <b>Note:</b> Price changes take effect from your next billing period.
      </div>
    </LoadingCard>
  );
};

export default SubscriptionChange;

import { apiUrl } from '../config';
import axios from 'axios';
import { formattedDate } from '../Helpers/dateHelpers';

export const apiOrganisationMine = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/Admin/Organisation/Mine',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiOrganisationMineSave = async (name, level1Name, level2Name, bankHolidayCountry, mfaEnabled) => {
  const result = await axios({
    method: 'post',
    url: 'v1/Admin/Organisation/Mine',
    data: { name, level1Name, level2Name, bankHolidayCountry, mfaEnabled },
    baseURL: apiUrl(),
  });

  if (result.status === 400) {
    return result.data;
  } else {
    return { isValid: true };
  }
};

export const apiOrganisations = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/GlobalAdmin/Organisations',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiOrganisation = async (id) => {
  const result = await axios({
    method: 'get',
    url: `v1/GlobalAdmin/Organisation/${id}`,
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiOrganisationSave = async (id, name, level1Name, level2Name, licenseExpiryDate, licenseTypeId, freeTrial, bankHolidayCountry, mfaEnabled) => {
  licenseExpiryDate = formattedDate(licenseExpiryDate);

  const result = await axios({
    method: 'post',
    url: 'v1/GlobalAdmin/Organisation/Save',
    baseURL: apiUrl(),
    data: { id, name, level1Name, level2Name, licenseExpiryDate, licenseTypeId, freeTrial, bankHolidayCountry, mfaEnabled },
  }).catch((error) => {
    return error.response;
  });

  return result.data;
};

export const apiOrganisationEnableDisable = async (id, enable) => {
  await axios({
    method: 'post',
    url: 'v1/GlobalAdmin/Organisation/EnableDisable',
    baseURL: apiUrl(),
    data: { id, enable },
  });
};

export const apiOrganisationSwitchTo = async (switchToId) => {
  const result = await axios({
    method: 'post',
    url: 'v1/GlobalAdmin/Organisation/SwitchTo',
    baseURL: apiUrl(),
    data: { switchToId },
  }).catch((error) => {
    return error.response;
  });

  return result.data;
};

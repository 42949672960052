import { useFormik } from 'formik';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { apiOrganisation, apiOrganisationSave } from '../../../APIs/organisation';
import { countries, licenseTypes } from '../../../Helpers/constants';
import BlockButton from '../../Common/BlockButton';
import CardErrors from '../../Common/CardErrors';
import { InputCheckbox, InputDate, InputSelect, InputText } from '../../Common/Inputs';
import LoadingCard from '../../Common/LoadingCard';

const OrganisationEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    addMode: true,
    name: '',
    level1Name: '',
    level2Name: '',
    licenseExpiryDate: undefined,
    freeTrial: true,
    licenseTypeId: 1,
    bankHolidayCountry: undefined,
    mfaEnabled: true,
  });
  const [serverErrors, setServerErrors] = useState(null);
  const addMode = !match.params.id;

  const GetData = useCallback(async () => {
    setLoading(true);

    const result = await apiOrganisation(match.params.id);

    if (result) {
      if (result.licenseExpiryDate) {
        result.licenseExpiryDate = new Date(result.licenseExpiryDate);
      }

      if (moment(result.licenseExpiryDate).year() === 2999) {
        result.licenseExpiryDate = undefined;
      }

      if (!result.bankHolidayCountry) {
        result.bankHolidayCountry = '';
      }
    }

    setData({ ...result, addMode: false });

    setLoading(false);
  }, [match]);

  useEffect(() => {
    if (addMode) {
      setLoading(false);
    } else {
      GetData();
    }
  }, [GetData, addMode]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      level1Name: Yup.string(),
      level2Name: Yup.string(),
      licenseExpiryDate: Yup.date(),
    }),
    onSubmit: async (values) => {
      setServerErrors(null);

      const id = match.params.id || null;
      var result = await apiOrganisationSave(id, values.name, values.level1Name, values.level2Name, values.licenseExpiryDate, values.licenseTypeId, values.freeTrial, values.bankHolidayCountry, values.mfaEnabled);

      if (result.isValid || typeof result === 'string') {
        history.push('/admin/organisations');
      } else {
        setServerErrors(result.errors);
      }
    },
  });

  const cancel = () => {
    history.push('/admin/organisations');
  };

  return (
    <LoadingCard loading={loading} title={`${addMode ? 'New' : 'Edit'} Organisation`}>
      {data && (
        <form onSubmit={formik.handleSubmit}>
          <InputText formik={formik} label="Name" field="name" />
          <InputText formik={formik} label="Level 1 Name" field="level1Name" />
          <InputText formik={formik} label="Level 2 Name" field="level2Name" />
          <div className="flex">
            <InputSelect formik={formik} label="License Type" data={licenseTypes} field="licenseTypeId" outerClassName="flex-grow" disabled={!addMode} />
            <InputCheckbox formik={formik} label="Free Trial" field="freeTrial" outerClassName="flex-grow-0" disabled={!addMode} />
          </div>
          <InputDate formik={formik} label="License Expiry Date" field="licenseExpiryDate" hintText="Clear for no expiry (31/12/2999)" />
          <InputSelect formik={formik} label="Country" field="bankHolidayCountry" data={countries} valueField="id" optional hintText="For Bank Holidays" optionalLabel="None" />
          <InputCheckbox formik={formik} label="Multi-Factor Authentication Enabled" field="mfaEnabled" />

          <CardErrors errors={serverErrors} />
          <div className="inline-flex w-full space-x-1">
            <BlockButton text="Save" icon="save" type="submit" groupPosition="left" />
            <BlockButton text="Cancel" icon="arrow-square-left" buttonType="secondary" groupPosition="right" type="button" onClick={() => cancel()} />
          </div>
        </form>
      )}
    </LoadingCard>
  );
};

export default OrganisationEdit;

import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../Contexts/UserContext';

export const ProtectedRoute = ({ admin, globalAdmin, manager, allowOnExpired, ...props }) => {
  const { settingsLoaded, userIsManager, userIsAdmin, userIsGlobalAdmin, HasLicenseExpired, UserIsAuthenticated } = useContext(UserContext);

  if (!settingsLoaded) {
    return null;
  }

  if (userIsGlobalAdmin) {
    allowOnExpired = true;
  }
  const allowed = UserIsAuthenticated() && (!manager || userIsManager) && (!admin || userIsAdmin) && (!globalAdmin || userIsGlobalAdmin);

  if (allowed && !allowOnExpired) {
    if (HasLicenseExpired()) {
      if (userIsAdmin) {
        return <Redirect to="/subscription" />;
      } else {
        return <Redirect to="/subscription/expired" />;
      }
    }
  }

  return allowed ? <Route {...props} /> : <Redirect to="/login" />;
};

import Cookies from 'js-cookie';
import moment from 'moment';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { apiUserSettings } from '../../APIs/user';
import { authExpiryCookieName, today } from '../../config';
import { licenseTypes } from '../../Helpers/constants';

export const Context = createContext({});

const defaultSettings = {
  inDev: false,
  inTest: false,
  inDevOrTest: false,
  hasLevel1: false,
  hasLevel2: false,
  level1Name: '',
  level2Name: '',
  level1Count: 0,
  level2Count: 0,
  organisationName: '',
  userCount: 0,
  userId: '',
  userRole: '',
  userEmail: '',
  level1Permissions: '',
  level2Permissions: '',
  emailMarketing: false,
  emailNotifications: true,
  licenseLevelsEnabled: false,
  licenseDeadlineLimit: 0,
  licenseUserLimit: 0,
  licenseTypeId: 1,
  licenseExpiryDate: undefined,
  licenseFreeTrial: false,
  stripeSubscriptionPaid: false,
  canFilter: false,
};

export const Provider = ({ children }) => {
  const [settings, setSettings] = useState(defaultSettings);
  const [settingsLoaded, setSettingsLoaded] = useState(false);

  const AllowedLevelUnassigned = (role, levelPermissions) => {
    if (role === 'Admin' || role === 'GlobalAdmin') {
      return true;
    }

    if (levelPermissions.includes('*') || levelPermissions.includes('_')) {
      return true;
    }

    return false;
  };

  function hasValue(value) {
    if (value) {
      return true;
    }
    return false;
  }

  const LoadUserSettings = useCallback(async () => {
    localStorage.removeItem('DeadlineTracker-UserSettings');

    if (UserIsAuthenticated()) {
      var data = await apiUserSettings();

      setSettings({
        organisationName: data.organisationName,
        hasLevel1: hasValue(data.level1Name),
        level1Name: data.level1Name,
        hasLevel2: hasValue(data.level2Name),
        level2Name: data.level2Name,
        level1Count: data.organisationLevel1Count,
        level2Count: data.organisationLevel2Count,
        userCount: data.organisationUserCount,
        inDev: data.inDev,
        inTest: data.inTest,
        inDevOrTest: data.inDevOrTest,
        userId: data.userId,
        userRole: data.userRole,
        userEmail: data.userEmail,
        level1Permissions: data.level1Permissions,
        level2Permissions: data.level2Permissions,
        emailMarketing: data.emailMarketing,
        emailNotifications: data.emailNotifications,
        licenseLevelsEnabled: data.licenseLevelsEnabled,
        licenseDeadlineLimit: data.licenseDeadlineLimit ?? 99999,
        licenseUserLimit: data.licenseUserLimit ?? 99999,
        licenseTypeId: data.licenseTypeId,
        licenseExpiryDate: data.licenseExpiryDate,
        licenseFreeTrial: data.licenseFreeTrial,
        stripeSubscriptionPaid: data.stripeSubscriptionPaid,
        userIsGlobalAdmin: data.userRole === 'GlobalAdmin',
        userIsAdmin: ['GlobalAdmin', 'Admin'].includes(data.userRole),
        userIsManager: ['GlobalAdmin', 'Admin', 'Manager'].includes(data.userRole),
        userAllowedLevel1Unassigned: AllowedLevelUnassigned(data.userRole, data.level1Permissions),
        userAllowedLevel2Unassigned: AllowedLevelUnassigned(data.userRole, data.level2Permissions),
        canFilter: data.licenseTypeId > 1 && (data.organisationLevel1Count > 0 || data.organisationLevel2Count > 0),
      });

      setSettingsLoaded(true);
    }
  }, []);

  const UserIsAuthenticated = () => {
    const date = Cookies.get(authExpiryCookieName());
    const now = moment();
    const expiry = moment(date, 'YYYYMMDDHHmm');

    return date && expiry.isAfter(now);
  };

  const HasLicenseExpired = useCallback(() => {
    const mExpiry = moment(settings.licenseExpiryDate, 'YYYY-MM-DD');
    const mToday = moment(today());

    return mExpiry.isSameOrBefore(mToday);
  }, [settings.licenseExpiryDate]);

  const HasLicenseExpiryDate = useCallback(() => {
    const mExpiry = moment(settings.licenseExpiryDate, 'YYYY-MM-DD');
    const maxDate = moment('2999-12-31');

    return mExpiry.isBefore(maxDate);
  }, [settings.licenseExpiryDate]);

  const HasLicensePaymentIssue = useCallback(() => {
    return !settings.stripeSubscriptionPaid;
  }, [settings.stripeSubscriptionPaid]);

  const GetLicense = useCallback(() => {
    return licenseTypes.find((x) => parseInt(x.id) === settings.licenseTypeId);
  }, [settings.licenseTypeId]);

  const userContext = {
    settingsLoaded,
    ...settings,
    UserIsAuthenticated,
    LoadUserSettings,
    HasLicenseExpired,
    HasLicenseExpiryDate,
    HasLicensePaymentIssue,
    GetLicense,
  };

  useEffect(() => {
    LoadUserSettings();
  }, [LoadUserSettings]);

  // pass the value in provider and return
  return <Context.Provider value={userContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

import React from 'react';
import PageTitle from './PageTitle';

const LoadingCard = ({ loading, title, help, children, titleChildren }) => {
  return (
    <>
      {title && (
        <PageTitle title={title} help={help}>
          {titleChildren}
        </PageTitle>
      )}
      <div className="card" style={{ position: 'relative' }}>
        {loading ? (
          <div className="p-10 w-full text-center">
            <i className="text-indigo-800 fad fa-spin fa-hurricane fa-2x" />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </>
  );
};

export default LoadingCard;

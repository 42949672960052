import React from 'react';

const InputText = ({ formik, label, field, disabled = false, type = 'text', icon, outerClassName = '', min, max, rightJustify = false, autoComplete, hintText }) => {
  return (
    <div className={outerClassName}>
      <label className="input-label" htmlFor={field}>
        {label}
      </label>
      <div className="input-text">
        {icon && <i className={`fad fa-${icon} fa-fw fa-lg ml-2`}></i>}
        <input className={`focus:outline-none ${rightJustify ? 'text-right' : ''}`} id={field} type={type} {...formik.getFieldProps(field)} disabled={disabled} min={min} max={max} autoComplete={autoComplete} />
      </div>
      {hintText && <div className="text-xs text-indigo-700 text-right -mt-3 mb-3">{hintText}</div>}
      {formik.touched[field] && formik.errors[field] ? <div className="input-error">{formik.errors[field]}</div> : null}
    </div>
  );
};

export default InputText;

import React from 'react';
import { GetDeadlineDate } from '../../Helpers/deadlineHelpers';
import Modal from '../Common/Modal';

const NotesModel = ({ notesDeadline, close }) => {
  if (!notesDeadline) {
    return null;
  }

  return (
    <Modal title={`${notesDeadline.deadlineName} - ${GetDeadlineDate(notesDeadline)}`} subtitle={notesDeadline.hasLevels && notesDeadline.levelName} show={true} hide={close} modalStyle="w-full max-w-md">
      <div className="m-3">
        <label className="input-label mx-0">Notes</label>
        {notesDeadline && notesDeadline.notes}
      </div>
    </Modal>
  );
};

export default NotesModel;

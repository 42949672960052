import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import { apiDeadlineInstanceReactivate, apiDeadlineInstancesCompleted } from '../../APIs/dashboard';
import { UserContext } from '../../Contexts/UserContext';
import { GetDeadlineDate } from '../../Helpers/deadlineHelpers';
import BlockButton from '../Common/BlockButton';
import FontAwesomeButton from '../Common/FontAwesomeButton';
import Modal from '../Common/Modal';
import PageTitle from '../Common/PageTitle';
import CompletedStatusFilter from './CompletedStatusFilter';
import DashboardItem2 from './DashboardItem2';
import FilterPanel from './FilterPanel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Completed2 = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState('All');
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [notesDeadline, setNotesDeadline] = useState({});
  const { level1Name, level2Name, canFilter } = useContext(UserContext);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState();

  const GetData = useCallback(async () => {
    if (isSubscribed) {
      setLoading(true);
      const result = await apiDeadlineInstancesCompleted();

      if (result && isSubscribed) {
        setData(result);
      }
    }
    setLoading(false);
    setInitialLoad(false);
  }, [isSubscribed]);

  useEffect(() => {
    try {
      const filterJSON = localStorage.getItem('DT_Filter') || undefined;
      if (filterJSON) {
        setFilter(JSON.parse(filterJSON));
      }
    } catch {
      localStorage.removeItem('DT_Filter');
      localStorage.removeItem('DT_FilterOptions');
      setFilter();
    }

    GetData();

    return () => setIsSubscribed(false);
  }, [GetData]);

  const notesClicked = (deadline) => {
    setNotesDeadline(deadline);
    setShowNotes(true);
  };

  const processAction = async (deadline, actionName, extra) => {
    switch (actionName) {
      case 'Reactivate':
        await apiDeadlineInstanceReactivate(deadline);
        break;
      default:
        console.error(`Unknown Action: ${actionName}`);
        break;
    }
    GetData();
  };

  const hasFilter = () => {
    if (!filter) {
      return false;
    }

    try {
      if (filter.level1s && filter.level1s.length > 0) {
        return true;
      }
      if (filter.level2s && filter.level2s.length > 0) {
        return true;
      }
      if (filter.bys && filter.bys.length > 0) {
        return true;
      }
    } catch {
      localStorage.removeItem('DT_Filter');
      localStorage.removeItem('DT_FilterOptions');
      setFilter();
    }

    return false;
  };

  return (
    <>
      <PageTitle title="Completed">{canFilter && <FontAwesomeButton icon="filter" text={`Filter ${hasFilter() ? ' (ON)' : ''}`} textAlways onClick={() => setShowFilter(!showFilter)} />}</PageTitle>
      <Modal title={`${notesDeadline.deadlineName} - ${GetDeadlineDate(notesDeadline)}`} subtitle={notesDeadline.hasLevels && notesDeadline.levelName} show={showNotes} hide={() => setShowNotes(false)} modalStyle="w-full max-w-md">
        <div className="m-3">
          <label className="input-label mx-0">Notes</label>
          {notesDeadline && notesDeadline.notes}
        </div>
      </Modal>
      <FilterPanel showFilter={showFilter} setShowFilter={setShowFilter} data={data} setFilter={setFilter} filter={filter} />
      <CompletedStatusFilter data={data} initialLoad={initialLoad} setTab={setTab} tab={tab} />
      <div className="card">
        <table className="w-full headless">
          <thead>
            <tr>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
              <th className="w-full"></th>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, row) => (
              <DashboardItem2
                row={row}
                key={item.deadlineInstanceId}
                item={item}
                tab={tab}
                filter={filter}
                byMode="Completed"
                notesClicked={notesClicked}
                processAction={processAction}
                optionsClicked={() => {
                  console.log('Error: 47B');
                }}
              />
            ))}
          </tbody>
        </table>
        <BlockButton text="Reload" onClick={() => GetData()} icon="hurricane" iconSpin={loading} mt={2} />
        <ExcelFile element={<BlockButton text="Export" icon="file-spreadsheet" className="mt-2" />} filename="Deadline Tracker Export">
          <ExcelSheet data={data} name="Completed">
            <ExcelColumn label="Deadline Name" value="deadlineName" />
            <ExcelColumn label="Deadline Date" value={(col) => new Date(col.deadlineDate.substring(0, 10))} />
            <ExcelColumn label="Open Date" value={(col) => new Date(col.openDate.substring(0, 10))} />
            <ExcelColumn label="Warn Date" value={(col) => new Date(col.warnDate.substring(0, 10))} />
            <ExcelColumn label="Assigned To" value="assignedToDisplayName" />
            <ExcelColumn label="Completed By" value="completedByDisplayName" />
            <ExcelColumn label="Completed Date" value={(col) => (col.completedDate ? new Date(col.completedDate.substring(0, 10)) : '')} />
            <ExcelColumn label="Deleted By" value="deletedByDisplayName" />
            <ExcelColumn label="Deleted Date" value={(col) => (col.deletedDate ? new Date(col.deletedDate.substring(0, 10)) : '')} />
            <ExcelColumn label="Notes" value="notes" />
            <ExcelColumn label={level1Name || ''} value="level1Name" />
            <ExcelColumn label={level2Name || ''} value="level2Name" />
          </ExcelSheet>
        </ExcelFile>
      </div>
    </>
  );
};

export default Completed2;

import React from 'react';
import { formatDateWithYear } from '../../Helpers/deadlineHelpers';

const UserBadge = ({ displayName, prefix, onHoliday, assignedInstance, date }) => {
  return (
    <div className="flex flex-wrap md:flex-no-wrap items-center my-1">
      {prefix && <span className="table-cell mr-2">{prefix}</span>}
      <span title={displayName} className={`flex items-center justify-center rounded-lg py-0 sm:py-1 px-2 text-center w-full uppercase font-mono ${onHoliday ? 'bg-red-600 text-gray-100' : 'bg-indigo-100 text-indigo-800'}`}>
        <span className="leading-5">
          {onHoliday && <i className="fad fa-gifts fa-fw mr-1" title="Holiday Conflict" />}
          {assignedInstance && <i className="fad fa-calendar-edit fa-fw mr-1" title="Instance Assigned" />}
        </span>
        <span className="inline md:hidden leading-4">{displayName}</span>
        <span className="hidden md:inline-block break-words leading-none w-full">{displayName}</span>
      </span>
      {date && <span className="table-cell ml-2 whitespace-no-wrap sm:font-mono">{formatDateWithYear(date)}</span>}
    </div>
  );
};

export default UserBadge;

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiResetPassword } from '../../APIs/auth';
import PageTitle from '../Common/PageTitle';
import BlockButton from '../Common/BlockButton';
import InputText from '../Common/InputText';
import CardErrors from '../Common/CardErrors';
import { InputCheckbox } from '../Common/Inputs';

const NewUser = () => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(undefined);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      emailMarketing: false,
      emailNotifications: true,
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .required('Required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase (A-Z) character')
        .matches(/[a-z]/, 'Password must contain at least one lowercase (a-z) character')
        .matches(/[0-9]/, 'Password must contain at least one numeruc (0-9) character')
        .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special (symbol) character'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      setError(undefined);

      const result = await apiResetPassword(values.email, values.password, values.emailMarketing, values.emailNotifications);

      if (result.successful) {
        setDone(true);
      } else {
        setError('Please check the email address entered above matches the email address from the Invite email you recieved.');
      }
    },
  });

  return (
    <div className="flex absolute w-screen top-0 left-0 h-screen align-middle">
      <div className="m-auto w-full sm:w-3/4 md:w-1/2">
        <PageTitle title="Welcome" withLogoCard />
        <div className="card">
          {done ? (
            <form method="get" action="/login">
              <p className="px-2 py-4">Your password has been set, please now login.</p>
              <BlockButton text="Return to Login" />
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <InputText formik={formik} label="Confirm Email Address" field="email" icon="at" />
              <InputText formik={formik} label="Password" field="password" icon="unlock-alt" type="password" />
              <InputText formik={formik} label="Confirm Password" field="confirmPassword" icon="check-double" type="password" />

              <InputCheckbox formik={formik} label="I'm happy to receive Hints & Tips and Marketing emails" field="emailMarketing" />
              <InputCheckbox formik={formik} label="Please send me email notifications about deadlines" field="emailNotifications" />

              <CardErrors errors={error} />
              <BlockButton text="Set Password" />
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewUser;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiTemplateDelete, apiTemplates } from '../../APIs/templates';
import { UserContext } from '../../Contexts/UserContext';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import LoadingCard from '../Common/LoadingCard';
import OptionsDropdown from '../Common/OptionsDropdown';

const TemplateList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [globalData, setGlobalData] = useState([]);
  const { level1Name, level2Name, level1Count, level2Count, licenseLevelsEnabled, licenseTypeId } = useContext(UserContext);

  const LoadData = useCallback(async () => {
    const result = await apiTemplates();
    setData(result.filter((x) => x.organisationId));
    setGlobalData(result.filter((x) => !x.organisationId));
    setLoading(false);
  }, []);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const handleDelete = (id) => async () => {
    await apiTemplateDelete(id);
    await LoadData();
  };

  const showLevel1 = () => {
    return licenseLevelsEnabled && level1Name && licenseTypeId > 2 && level1Count > 1;
  };

  const showLevel2 = () => {
    return licenseLevelsEnabled && level2Name && licenseTypeId > 2 && level2Count > 1;
  };

  return (
    <>
      <LoadingCard loading={loading} title="Templates" titleChildren={<FontAwesomeLink icon="plus-square" text="New Template" textAlways href="/template/Add" />}>
        {data.length === 0 && <div className="p-2 text-center">None found</div>}
        {data.length > 0 && (
          <table className="w-full stripe">
            <thead>
              <tr className="bg-indigo-800 text-indigo-50 rounded-md">
                <th className="w-full py-2 pl-4 text-left rounded-tl-md">New Deadline From</th>
                {showLevel1() && <th className="w-auto p-2 text-center">{level1Name}</th>}
                {showLevel2() && <th className="w-auto p-2 text-center">{level2Name}</th>}
                <th className="w-12 py-2 rounded-tr-md"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index} className={`${!item.isEnabled && 'text-gray-500'}`}>
                    <td className="whitespace-no-wrap py-3 flex items-center">
                      <Link to={`/deadline/fromtemplate/${item.id}`} className="text-indigo-800 flex items-center">
                        <span className="fad fa-2x fa-plus mx-2"></span>
                        {item.name}
                      </Link>
                    </td>
                    {showLevel1() && (
                      <td className="w-auto">
                        {!item.level1Id && (
                          <Link to={`/deadline/multifromtemplate/1/${item.id}`} className="text-indigo-800 flex items-center">
                            <span className="fad fa-2x fa-layer-plus mx-2"></span>
                            Multiple
                          </Link>
                        )}
                      </td>
                    )}
                    {showLevel2() && (
                      <td className="w-auto">
                        {!item.level2Id && (
                          <Link to={`/deadline/multifromtemplate/2/${item.id}`} className="text-indigo-800 flex items-center">
                            <span className="fad fa-2x fa-layer-plus fa-swap-opacity mx-2"></span>
                            Multiple
                          </Link>
                        )}
                      </td>
                    )}
                    <td>
                      <OptionsDropdown>
                        <Link className="text-gray-100 focus:outline-none block p-2" to={`/template/${item.id}`}>
                          Edit
                        </Link>
                        <button className="text-gray-100 focus:outline-none block p-2" onClick={handleDelete(item.id)}>
                          Delete
                        </button>
                      </OptionsDropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </LoadingCard>
      {globalData.length > 0 && (
        <div className="mt-6">
          <LoadingCard loading={loading} title="Global Templates">
            <table className="w-full stripe">
              <thead>
                <tr className="bg-indigo-800 text-indigo-50 rounded-md">
                  <th className="w-full py-2 pl-4 text-left rounded-tl-md">New Deadline From</th>
                  {showLevel1() && <th className="w-auto p-2 text-center">{level1Name}</th>}
                  {showLevel2() && <th className="w-auto p-2 text-center">{level2Name}</th>}
                  <th className="w-4 py-2 text-left rounded-tr-md">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {globalData.map((item, index) => {
                  return (
                    <tr key={index} className={`${!item.isEnabled && 'text-gray-500'}`}>
                      <td className="whitespace-no-wrap py-3 flex items-center">
                        <Link to={`/deadline/fromtemplate/${item.id}`} className="text-indigo-800 flex items-center">
                          <span className="fad fa-2x fa-plus mx-2"></span>
                          {item.name}
                        </Link>
                      </td>
                      {showLevel1() && (
                        <td className="w-auto">
                          {!item.level1Id && (
                            <Link to={`/deadline/multifromtemplate/1/${item.id}`} className="text-indigo-800 flex items-center">
                              <span className="fad fa-2x fa-layer-plus mx-2"></span>
                              Multiple
                            </Link>
                          )}
                        </td>
                      )}
                      {showLevel2() && (
                        <td className="w-auto">
                          {!item.level1Id && (
                            <Link to={`/deadline/multifromtemplate/2/${item.id}`} className="text-indigo-800 flex items-center">
                              <span className="fad fa-2x fa-layer-plus fa-swap-opacity mx-2"></span>
                              Multiple
                            </Link>
                          )}
                        </td>
                      )}
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </LoadingCard>
        </div>
      )}
    </>
  );
};

export default TemplateList;

import React, { useEffect } from 'react';
import { apiLogout } from '../../APIs/auth';
import PageTitle from '../Common/PageTitle';
import BlockButton from '../Common/BlockButton';

const Logout = () => {
  useEffect(() => {
    apiLogout();
  }, []);

  return (
    <div className="flex absolute w-screen top-0 left-0 h-screen align-middle">
      <div className="m-auto w-full sm:w-3/4 md:w-1/2">
        <PageTitle title="Logout" withLogoCard />
        <div className="card">
          <form method="get" action="/login">
            <p className="px-2 py-4">You have been successfully logged out.</p>

            <BlockButton text="Log Back In" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Logout;

import React, { useEffect, useState } from 'react';
import StatusCard from '../Common/StatusCard';
import { GetStatus } from '../../Helpers/deadlineHelpers';
import LoadingSpinner from '../Common/LoadingSpinner';

const StatusFilter = ({ data, dataRecent, initialLoad, tab, setTab }) => {
  const [allCount, setAllCount] = useState(<LoadingSpinner />);
  const [activeCount, setActiveCount] = useState(<LoadingSpinner />);
  const [warnedCount, setWarnedCount] = useState(<LoadingSpinner />);
  const [overdueCount, setOverdueCount] = useState(<LoadingSpinner />);
  const [snoozedCount, setSnoozedCount] = useState(<LoadingSpinner />);
  const [inProgressCount, setInProgressCount] = useState(<LoadingSpinner />);
  const [holidayCount, setHolidayCount] = useState(<LoadingSpinner />);
  const [recentCount, setRecentCount] = useState(<LoadingSpinner />);

  useEffect(() => {
    if (!data) {
      return;
    }

    let inProgress = 0;
    let warned = 0;
    let overdue = 0;
    let snoozed = 0;
    let holiday = data.filter((x) => x.assignedToOnHoliday).length;

    data.forEach((x) => {
      switch (GetStatus(x)) {
        case 'Overdue':
          overdue += 1;
          break;
        case 'Warned':
          warned += 1;
          break;
        case 'InProgress':
          inProgress += 1;
          break;
        case 'Snoozed':
          snoozed += 1;
          break;
        default:
          break;
      }
    });

    setAllCount(data.length);
    setActiveCount(overdue + warned + inProgress);
    setHolidayCount(holiday);
    setSnoozedCount(snoozed);
    setOverdueCount(overdue);
    setWarnedCount(warned);
    setInProgressCount(inProgress);

    if (tab === 'Snoozed' && snoozed === 0) {
      setTab('All');
    }

    if (tab === 'Overdue' && overdue === 0) {
      setTab('All');
    }

    if (tab === 'Warned' && warned === 0) {
      setTab('All');
    }

    if (tab === 'InProgress' && inProgress === 0) {
      setTab('All');
    }

    if (dataRecent) {
      setRecentCount(dataRecent.length);

      if (tab === 'Recent' && dataRecent.length === 0) {
        setTab('All');
      }
    }
  }, [data, dataRecent, tab, setTab]);

  const tabChange = (e) => {
    setTab(e.target.value);
  };

  return (
    <>
      <div className="md:hidden w-full shadow-md mb-2">
        {!initialLoad && (
          <select className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 sm:text-sm sm:leading-5" value={tab} onChange={tabChange}>
            <option value="Active">Status: Active ({activeCount})</option>
            <option value="All">Status: All ({allCount})</option>
            {overdueCount > 0 && <option value="Overdue">Status: Overdue ({overdueCount})</option>}
            {warnedCount > 0 && <option value="Warned">Status: Warned ({warnedCount})</option>}
            {inProgressCount > 0 && <option value="InProgress">Status: In Progress ({inProgressCount})</option>}
            {snoozedCount > 0 && <option value="Snoozed">Status: Snoozed ({snoozedCount})</option>}
            {recentCount > 0 && <option value="Recent">Status: Recent ({recentCount})</option>}
            {holidayCount > 0 && <option value="Holiday Conflict">Status: Holiday Conflicts ({holidayCount})</option>}
          </select>
        )}
      </div>
      <div className="hidden md:flex flex-col sm:flex-row w-full mb-2 sm:space-x-2">
        {!initialLoad && (
          <>
            <StatusCard title="Active" count={activeCount} showAlways className="text-gray-600 ml-0" selected={tab === 'Active'} onClick={() => setTab('Active')} />
            <StatusCard title="All" count={allCount} showAlways className="text-gray-600 ml-0" selected={tab === 'All'} onClick={() => setTab('All')} />
            <StatusCard title="Overdue" count={overdueCount} className="text-red-700" selected={tab === 'Overdue'} onClick={() => setTab('Overdue')} />
            <StatusCard title="Warned" count={warnedCount} className="text-orange-500" selected={tab === 'Warned'} onClick={() => setTab('Warned')} />
            <StatusCard title="In Progress" count={inProgressCount} className="text-green-700" selected={tab === 'InProgress'} onClick={() => setTab('InProgress')} />
            <StatusCard title="Snoozed" count={snoozedCount} className="text-gray-600 mr-0" selected={tab === 'Snoozed'} onClick={() => setTab('Snoozed')} />
            <StatusCard title="Holiday Conflicts" count={holidayCount} className="text-red-700" selected={tab === 'Holiday'} onClick={() => setTab('Holiday')} />
            <StatusCard title="Recent" count={recentCount} className="text-gray-600 mr-0" selected={tab === 'Recent'} onClick={() => setTab('Recent')} />
          </>
        )}
      </div>
    </>
  );
};

export default StatusFilter;

import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { apiUserSettingsSave } from '../../APIs/user';
import { UserContext } from '../../Contexts/UserContext';
import BlockButton from '../Common/BlockButton';
import { InputCheckbox } from '../Common/Inputs';
import PageTitle from '../Common/PageTitle';

const Settings = ({ history }) => {
  const { settingsLoaded, emailMarketing, emailNotifications, LoadUserSettings } = useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      emailMarketing,
      emailNotifications,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      await apiUserSettingsSave(values.emailMarketing, values.emailNotifications);
      await LoadUserSettings();
      toast('Settings Updated');
      history.push('/');
    },
  });

  const changePasswordHandler = () => {
    history.push('/change-password');
  };

  return (
    <>
      <PageTitle title="Settings" help="/help/settings" />
      {settingsLoaded && (
        <div className="card" style={{ position: 'relative' }}>
          <form onSubmit={formik.handleSubmit}>
            <label className="font-extrabold">Email Settings</label>
            <InputCheckbox formik={formik} label="I'm happy to receive Hints & Tips and Marketing emails" field="emailMarketing" />
            <InputCheckbox formik={formik} label="Please send me email notifications about deadlines" field="emailNotifications" />
            <div className="inline-flex w-full space-x-1">
              <BlockButton text="Save" icon="save" type="button" groupPosition="left" onClick={formik.handleSubmit} />
              <BlockButton text="Change Password" icon="unlock-alt" type="button" buttonType="secondary" groupPosition="right" onClick={changePasswordHandler} />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Settings;

import React, { useEffect, useState, useCallback, useContext } from 'react';
import PageTitle from '../../Common/PageTitle';
import { apiLevels } from '../../../APIs/levels';
import FontAwesomeLink from '../../Common/FontAwesomeLink';
import BlockButton from '../../Common/BlockButton';
import { UserContext } from '../../../Contexts/UserContext';
import HelperCard from '../../Common/HelperCard';
import HelpLevelsCreate from '../../help/HelpLevelsCreate';

const LevelList = ({ match }) => {
  const [loading, setLoading] = useState(true);
  const [levelName, setLevelName] = useState('');
  const [data, setData] = useState([]);
  const level = match.params.level;
  const { level1Name, level2Name } = useContext(UserContext);

  const GetData = useCallback(async () => {
    setLoading(true);
    var result = await apiLevels(level, null, true);

    setData(result);
    setLoading(false);
  }, [level]);

  useEffect(() => {
    setLevelName(level === '1' ? level1Name : level2Name);
  }, [level, level1Name, level2Name]);

  useEffect(() => {
    GetData();
  }, [match, GetData]);

  return (
    <>
      <PageTitle title={`${levelName} List`} help="/help/categories">
        <FontAwesomeLink icon="plus-square" text="New" textAlways href={`/admin/level/${level}/Add`} />
      </PageTitle>
      <HelperCard show={!loading && data.length === 0} more={<HelpLevelsCreate inline />}>
        Please click the <FontAwesomeLink icon="plus-square" text="New" textAlways href={`/admin/level/${level}/Add`} m={0} /> link above to add you first {levelName}.
      </HelperCard>
      <div className="card">
        {data && data.length > 0 && (
          <table className="w-full stripe">
            <thead>
              <tr className="bg-indigo-800 text-indigo-50 rounded-md">
                <th className="w-12 rounded-tl-md"></th>
                <td className="w-auto py-2 rounded-tr-md md:rounded-tr-none">Name</td>
                <td className="w-auto py-2 hidden md:table-cell rounded-tr-none md:rounded-tr-md">Group Name</td>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="py-3">
                      <FontAwesomeLink icon="pen-square" size="2x" href={`/admin/level/${level}/${item.id}`} />
                    </td>
                    <td className={`${item.isEnabled ? '' : 'disabled'}`}>
                      {item.name}
                      <div className="md:hidden text-xs">{item.groupName}</div>
                    </td>
                    <td className={`hidden md:table-cell ${item.isEnabled ? '' : 'disabled'}`}>{item.groupName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <BlockButton text="Reload" onClick={() => GetData()} icon="hurricane" iconSpin={loading} mt={2} />
      </div>
    </>
  );
};

export default LevelList;

import React from 'react';

export default function SubscriptionOption({ data, onSelect, selected, extraClasses = '', disabled = false }) {
  const handleSelect = () => {
    onSelect(data);
  };

  const handleClear = () => {
    onSelect(null);
  };

  const buttonClass = 'bg-gray-700 hover:bg-gray-600';
  const borderClass = selected && selected.id === data.id ? 'border-indigo-800 border-2' : '';
  const colSpan = selected ? 'col-span-2' : 'col-span-1';
  const plusVAT = data.price === '0' ? '' : '+VAT';

  if (selected && selected.id !== data.id) {
    return null;
  }

  return (
    <div className={`mt-4 rounded-lg shadow-lg overflow-hidden lg:mt-0 flex flex-col ${borderClass} ${colSpan} ${extraClasses}`}>
      <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6 flex-grow">
        <div>
          <h3 className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-enterprise">
            {data.name}
          </h3>
        </div>
        <div className="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
          £{data.price}
          <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">{plusVAT} /mo</span>
        </div>
        <p className="mt-5 text-lg leading-7 text-gray-500 flex-grow">{data.details}</p>
      </div>
      <div className="px-6 pt-6 pb-8 bg-indigo-50 sm:p-10 sm:pt-6 flex h-32">
        <ul className="w-full">
          {data.features.map((feature, index) => (
            <li key={index} className="flex items-start">
              <div className="flex-shrink-0 hidden sm:inline-block">
                <svg className="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <p className="ml-3 text-base leading-6 text-gray-700">{feature}</p>
            </li>
          ))}
        </ul>
        {onSelect && !disabled && (
          <>
            {selected ? (
              <div className="mt-6">
                <button
                  onClick={handleClear}
                  className={`flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white ${buttonClass} focus:outline-none focus:shadow-outline transition duration-150 ease-in-out ml-2`}
                  aria-describedby="tier-enterprise">
                  Alternatives
                </button>
              </div>
            ) : (
              <div className="mt-6">
                <button
                  onClick={handleSelect}
                  className={`flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white ${buttonClass} focus:outline-none focus:shadow-outline transition duration-150 ease-in-out`}
                  aria-describedby="tier-enterprise">
                  Select
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

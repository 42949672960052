export const BuildPermissionsString = (role, obj, levelNumber) => {
  if (role === 'Admin' || role === 'GlobalAdmin') {
    return '*';
  }

  if (obj[`level${levelNumber}All`] && obj[`level${levelNumber}All`] === true) {
    return '*';
  }

  let permissions = [];

  if (obj[`level${levelNumber}_Unassigned`] && obj[`level${levelNumber}_Unassigned`] === true) {
    permissions.push('_');
  }

  Object.keys(obj).forEach((key) => {
    if (key.startsWith(`level${levelNumber}`) && obj[key] === true) {
      const parts = key.split('_');

      if (parts[1] !== 'Unassigned') {
        permissions.push(parts[1]);
      }
    }
  });

  if (permissions.length === 0) {
    return '*';
  }

  return permissions.join('|');
};

export const PermissionsToObject = (level1Permissions, level2Permissions) => {
  let obj = {};

  if (level1Permissions) {
    if (level1Permissions === '*') {
      obj.level1All = true;
    } else {
      level1Permissions.split('|').forEach((p) => {
        if (p === '_') {
          obj.level1_Unassigned = true;
        } else {
          obj[`level1_${p}`] = true;
        }
      });
    }
  }

  if (level2Permissions) {
    if (level2Permissions === '*') {
      obj.level2All = true;
    } else {
      level2Permissions.split('|').forEach((p) => {
        if (p === '_') {
          obj.level2_Unassigned = true;
        } else {
          obj[`level2_${p}`] = true;
        }
      });
    }
  }

  return obj;
};

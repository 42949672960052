import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import React, { useContext, useEffect, useState } from 'react';
import { stripeAPIKey } from '../../config';
import { UserContext } from '../../Contexts/UserContext';
import { licenseTypes } from '../../Helpers/constants';
import CardForm from './CardForm';
import SubscriptionOptions from './SubscriptionOptions';

const stripePromise = loadStripe(stripeAPIKey());

const SubscriptionChoose = ({ history }) => {
  const [selected, setSelected] = useState(null);
  const { licenseTypeId, HasLicenseExpiryDate } = useContext(UserContext);

  useEffect(() => {
    setSelected(null);

    if (licenseTypeId !== 1) {
      var found = licenseTypes.find((x) => parseInt(x.id) === licenseTypeId);

      if (found) {
        setSelected(found);
      }
    }
  }, [licenseTypeId]);

  const handleSelect = (id) => {
    setSelected(id);
  };

  const handleOnOk = () => {
    history.push('/subscription');
  };

  return (
    <>
      <SubscriptionOptions onSelect={handleSelect} selected={selected} excludeFree={licenseTypeId === 1 && !HasLicenseExpiryDate()} />
      <Elements stripe={stripePromise}>{selected && <CardForm licenseType={selected} onOk={handleOnOk} />}</Elements>
    </>
  );
};

export default SubscriptionChoose;

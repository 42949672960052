import { apiUrl } from '../config';
import axios from 'axios';

export const apiTemplates = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/Manager/Templates',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiTemplate = async (id) => {
  var found = (await apiTemplates()).find((x) => x.id === id);

  found.level1Id = found.level1Id || '';
  found.level2Id = found.level2Id || '';
  found.notes = found.notes || '';

  return found;
};

export const apiTemplateSaveFormik = async (values) => {
  let level1Ids = [];
  if (values.level1Id) {
    level1Ids.push(values.level1Id);
  }
  Object.entries(values).forEach((entity) => {
    const [key, value] = entity;
    if (key.startsWith('level1_') && value) {
      level1Ids.push(key.replace('level1_', ''));
    }
  });

  let level2Ids = [];
  if (values.level2Id) {
    level2Ids.push(values.level2Id);
  }
  Object.entries(values).forEach((entity) => {
    const [key, value] = entity;
    if (key.startsWith('level2_') && value) {
      level2Ids.push(key.replace('level2_', ''));
    }
  });

  return await apiTemplateSave(
    values.id,
    values.name,
    level1Ids,
    level2Ids,
    values.repeatTypeCode,
    values.repeatStep,
    values.deadlineTypeCode,
    values.day,
    values.dayOfWeek,
    values.notes,
    values.openDays,
    values.openMonths,
    values.warnDays,
    values.warnMonths,
    values.adjustDays,
    values.workingDayRule
  );
};

export const apiTemplateSave = async (id, name, level1Ids, level2Ids, repeatTypeCode, repeatStep, deadlineTypeCode, day, dayOfWeek, notes, openDays, openMonths, warnDays, warnMonths, adjustDays, workingDayRule) => {
  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Manager/Template/Save',
      baseURL: apiUrl(),
      data: {
        id,
        name,
        level1Ids,
        level2Ids,
        repeatTypeCode,
        repeatStep,
        deadlineTypeCode,
        day,
        dayOfWeek,
        notes,
        openDays,
        openMonths,
        warnDays,
        warnMonths,
        adjustDays,
        workingDayRule,
      },
    });

    return result.data;
  } catch (err) {
    if (typeof err.data === 'string') {
      return { isValid: false, errors: err.data };
    }
    return err.data;
  }
};

export const apiTemplateDelete = async (id) => {
  await axios({
    method: 'post',
    url: 'v1/Manager/Template/Delete',
    baseURL: apiUrl(),
    data: {
      id,
    },
  });
};

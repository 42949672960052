import logo from '../../assets/logo.svg';
import React, { useContext, useEffect } from 'react';
import SidebarGroup from './SidebarGroup';
import SidebarItem from './SidebarItem';
import { UserContext } from '../../Contexts/UserContext';
import { showDebugFooter } from '../../config';

const Sidebar = ({ closeMenu }) => {
  const { level1Name, level2Name, level1Count, level2Count, inDevOrTest, licenseLevelsEnabled, licenseUserLimit, userIsAdmin, userIsGlobalAdmin, userIsManager, licenseTypeId, licenseFreeTrial, HasLicenseExpired } = useContext(UserContext);

  useEffect(() => {});

  return (
    <div className="overflow-y-auto">
      <img src={logo} title="Deadline Tracker" alt="Deadline Tracker" className="h-12 pl-8 my-4" />
      <hr />
      <nav className="flex-1 p-2 bg-primary">
        <SidebarItem to="/" icon="home" title="Deadlines" closeMenu={closeMenu} />
        <SidebarItem to="/completed" icon="check" title="Completed" closeMenu={closeMenu} />
        <SidebarItem to="/calendar" icon="calendar" title="Calendar" closeMenu={closeMenu} />

        <SidebarItem to="/admin/organisations" icon="sitemap" title="Organisations" closeMenu={closeMenu} show={userIsGlobalAdmin} />

        <SidebarGroup title="Admin" icon="sliders-v" show={userIsAdmin}>
          <SidebarItem to="/admin/levels/1" icon="list" title={`${level1Name} List`} closeMenu={closeMenu} show={licenseLevelsEnabled && level1Name} />
          <SidebarItem to="/admin/levels/2" icon="list fa-swap-opacity" title={`${level2Name} List`} closeMenu={closeMenu} show={licenseLevelsEnabled && level2Name} />
          <SidebarItem to="/admin/users" icon="users" title="Users" show={licenseUserLimit > 1 || userIsGlobalAdmin} closeMenu={closeMenu} />
          <SidebarItem to="/subscription" icon="file-certificate" title="Subscription Details" closeMenu={closeMenu} />
          <SidebarItem to="/admin/organisation-settings" icon="chart-network" title="My Organisation" closeMenu={closeMenu} />
        </SidebarGroup>

        {licenseTypeId < 2 ? (
          <SidebarItem to="/deadline/Add" icon="plus" title="New Deadline" closeMenu={closeMenu} show={userIsManager} />
        ) : (
          <SidebarGroup title="New Deadline" icon="plus" show={userIsManager} initialIsOpen>
            <SidebarItem to="/deadline/Add" icon="plus" title="Single" closeMenu={closeMenu} />
            <SidebarItem to="/deadline/multi/1" icon="layer-plus" title={`Multiple for ${level1Name}`} closeMenu={closeMenu} show={licenseLevelsEnabled && level1Name && licenseTypeId > 2 && level1Count > 1} />
            <SidebarItem to="/deadline/multi/2" icon="layer-plus fa-swap-opacity" title={`Multiple for ${level2Name}`} closeMenu={closeMenu} show={licenseLevelsEnabled && level2Name && licenseTypeId > 2 && level2Count > 1} />
            <SidebarItem to="/templates" icon="clone" title="From Template" closeMenu={closeMenu} />
          </SidebarGroup>
        )}

        <SidebarItem to="/adjust-date" icon="calendar-edit" title="Adjust Date" closeMenu={closeMenu} show={inDevOrTest && showDebugFooter()} />

        <SidebarItem to="/settings" icon="user-cog" title="Settings" closeMenu={closeMenu} />
        <SidebarItem to="/holidays" icon="gifts" title="Holidays" show={licenseTypeId > 2} closeMenu={closeMenu} />

        <SidebarGroup title="Help" icon="life-ring">
          <SidebarItem to="/help/categories" icon="list" title="Categories" show={licenseTypeId !== 1 && userIsAdmin} closeMenu={closeMenu} />
          <SidebarItem to="/help/deadlines" icon="home" title="Deadlines" closeMenu={closeMenu} />
          <SidebarItem to="/help/roles" icon="users" title="Roles" closeMenu={closeMenu} />
          <SidebarItem to="/help/settings" icon="user-cog" title="Settings" closeMenu={closeMenu} />
          {licenseFreeTrial || HasLicenseExpired() ? (
            <SidebarItem to="/help/subscription-choose" icon="file-certificate" title="Subscription" show={userIsAdmin} closeMenu={closeMenu} />
          ) : (
            <SidebarItem to="/help/subscription-change" icon="file-certificate" title="Subscription" show={userIsAdmin} closeMenu={closeMenu} />
          )}
          <SidebarItem to="/help/users" icon="users" title="Users" show={licenseTypeId > 2 && userIsAdmin} closeMenu={closeMenu} />
          <SidebarItem to="/help/support" icon="ticket-alt" title="Support" closeMenu={closeMenu} />
        </SidebarGroup>

        <SidebarItem to="/logout" icon="power-off" title="Log out" closeMenu={closeMenu} />
      </nav>
    </div>
  );
};

export default Sidebar;

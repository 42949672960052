import moment from 'moment';

export const environments = {
  Localhost: 'Localhost',
  Development: 'Development',
  Test: 'Test',
  Production: 'Production',
};

export const environmentGet = () => {
  const hostname = window && window.location && window.location.hostname;

  switch (hostname) {
    case 'localhost':
      return environments.Localhost;
    case 'app.deadlinetracker.co.uk':
      return environments.Production;
    case 'testapp.deadlinetracker.co.uk':
      return environments.Test;
    case 'devapp.deadlinetracker.co.uk':
    default:
      return environments.Development;
  }
};

export const apiUrl = () => {
  switch (environmentGet()) {
    case environments.Localhost:
      return '/';
    case environments.Production:
      return 'https://api.deadlinetracker.co.uk/api/';
    case environments.Test:
      return 'https://testapi.deadlinetracker.co.uk/api/';
    case environments.Development:
    default:
      return 'https://devapi.deadlinetracker.co.uk/api/';
  }
};

export const showDebugFooter = () => {
  if (environmentGet() === environments.Production) {
    return false;
  }

  var showDebug = localStorage.getItem('DeadlineTracker-ShowDebug');

  return showDebug === 'true';
};

export const stripeAPIKey = () => {
  switch (environmentGet()) {
    case environments.Localhost:
      return 'pk_test_51GqGkECWGZ7QMu0gK03APk1WoSp7Iapg9B7Mw9B9OQqpSEMNqzeNA1pW30mbtvvrXkifJMQv0dQl39irMqwTava100A3j53jjS';
    case environments.Production:
      return 'pk_live_51HIASsFIxUh1hnLpVRhKdC0TVhthxSimOnt9PmX6RYjXl6BCTNWOAgWrh1SHpfFF40UBnkhRg8TihnFCcZTKx3dS00pv7cOiMk';
    case environments.Test:
      return 'pk_test_51H7zd8CWJTiWksszvcbIDFFHgbulZHa6BKUCsNT2VShPQUds7SzBhDysBc9pSrKCsyTqbrBd1tZDxJUYnSmqfxzH00t3fy3Zr1';
    case environments.Development:
    default:
      return 'pk_test_51GqGkECWGZ7QMu0gK03APk1WoSp7Iapg9B7Mw9B9OQqpSEMNqzeNA1pW30mbtvvrXkifJMQv0dQl39irMqwTava100A3j53jjS';
  }
};

export const gaTrackingId = () => {
  switch (environmentGet()) {
    case environments.Localhost:
      return 'G-0ZS7T2VBNN';
    case environments.Production:
      return 'G-R79RERN71R';
    case environments.Test:
      return 'G-LNGQ1PWHTN';
    case environments.Development:
    default:
      return 'G-143VZQ0SFR';
  }
};

export const gaTrackingIdConversion = () => {
  switch (environmentGet()) {
    case environments.Localhost:
      return 'G-EYVRWL3R1Y';
    case environments.Production:
      return 'G-282W68P0FQ';
    case environments.Test:
      return 'G-9XTBJYGQFL';
    case environments.Development:
    default:
      return 'G-CGVEP7873B';
  }
};

export const authExpiryCookieName = () => {
  switch (environmentGet()) {
    case environments.Localhost:
      return '.localhost.deadlinetracker.AuthExpiry';
    case environments.Production:
      return 'deadlinetracker.AuthExpiry';
    case environments.Test:
      return '.test.deadlinetracker.AuthExpiry';
    case environments.Development:
    default:
      return '.dev.deadlinetracker.AuthExpiry';
  }
};

export const DATE_FORMAT = 'ddd Do MMM YYYY';

export function hasDateOverride() {
  return localStorage.getItem('DeadlineTracker-TestDate') !== null;
}

export function today() {
  let date = new Date();
  const testDate = localStorage.getItem('DeadlineTracker-TestDate');
  if (testDate) {
    date = new Date(testDate);
  }
  date.setHours(0, 0, 0, 0);

  return date;
}

export function todayYear() {
  var date = today();
  return date.getFullYear();
}

export function todayFormatted() {
  const m = moment(today());

  return m.format('DD MMM YYYY');
}

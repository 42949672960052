import React, { useContext } from 'react';
import PageTitle from '../Common/PageTitle';
import { UserContext } from '../../Contexts/UserContext';

const SubscriptionExpired = () => {
  const { licenseFreeTrial } = useContext(UserContext);
  const type = licenseFreeTrial ? 'Free Trial' : 'Subscription';

  return (
    <div className="w-full">
      <PageTitle title={`${type} - Expired`} />
      <div className="card mb-4 p-4">Your Deadline Tracker {type} has expired, please contact your System Admin.</div>
    </div>
  );
};

export default SubscriptionExpired;

import { apiUrl } from '../config';
import axios from 'axios';

export const configureAxios = () => {
  const UNAUTHORIZED = 401;
  const INTERNAL_SERVER_ERROR = 500;
  const MFA_REQUIRED = 412;
  const UPGRADE_REQUIRED = 426;
  const GATEWAY_TIMEOUT = 504;

  axios.interceptors.request.use((config) => {
    const testDate = localStorage.getItem('DeadlineTracker-TestDate') || '';
    config.headers['test-date'] = testDate;
    config.headers['Content-Type'] = 'application/json';
    config.baseURL = apiUrl();
    config.withCredentials = true;

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.response) {
        window.location.href = '/errors/500';
        return Promise.reject(error);
      }

      const { status } = error.response;

      if (status === MFA_REQUIRED) {
        window.location.href = '/mfa';
      }

      if (status === INTERNAL_SERVER_ERROR) {
        window.location.href = '/errors/500';
      }

      if (status === UPGRADE_REQUIRED) {
        window.location.href = '/errors/426';
      }

      if (status === UNAUTHORIZED) {
        window.location.href = '/';
      }

      if (status === 400) {
        console.error(error.response);
        return Promise.reject(error.response);
      }

      if (status === GATEWAY_TIMEOUT) {
        window.location.href = '/errors/500';
        return Promise.reject(error);
      }

      return Promise.reject(error);
    }
  );
};

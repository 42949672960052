import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiResetPassword } from '../../APIs/auth';
import PageTitle from '../Common/PageTitle';
import BlockButton from '../Common/BlockButton';
import CardErrors from '../Common/CardErrors';
import InputText from '../Common/InputText';

const ResetPassword = ({ history }) => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(undefined);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
        .required('Required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase (A-Z) character')
        .matches(/[a-z]/, 'Password must contain at least one lowercase (a-z) character')
        .matches(/[0-9]/, 'Password must contain at least one numeruc (0-9) character')
        .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special (symbol) character'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      setError(undefined);

      const result = await apiResetPassword(values.email, values.password);

      if (result.successful) {
        setDone(true);
      } else {
        setError(['Password reset failed, common reasons are:', ' - The email address you entered does not match the email address from the reset email', ' - The reset link has already been used', ' - The reset email is more than a few hours old']);
      }
    },
  });

  const handleReturnToLogin = () => {
    history.push('/');
  };

  return (
    <div className="flex absolute w-screen top-0 left-0 h-screen align-middle">
      <div className="m-auto w-full sm:w-3/4 md:w-1/2">
        <PageTitle title="Reset Password" withLogoCard />
        <div className="card">
          {done ? (
            <form method="get" action="/login">
              <p className="px-2 py-4">Your password has been reset, please now login.</p>
              <BlockButton text="Return to Login" />
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <InputText formik={formik} label="Confirm Email Address" field="email" icon="at" />
              <InputText formik={formik} label="Password" field="password" icon="unlock-alt" type="password" autoComplete="new-password" />
              <InputText formik={formik} label="Confirm Password" field="confirmPassword" icon="check-double" type="password" autoComplete="new-password" />

              <CardErrors errors={error} />
              <div className="inline-flex w-full space-x-1">
                <BlockButton text="Reset Password" groupPosition="left" />
                <BlockButton text="Return to Login" type="button" buttonType="secondary" onClick={handleReturnToLogin} groupPosition="right" />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React from 'react';

const StatusCard = ({ count, title, className, selected, onClick, showAlways }) => {
  const cursorClass = onClick ? 'cursor-pointer' : '';
  const selectedClass = selected ? 'border-2 border-indigo-700' : '';

  if (!showAlways && count === 0 && !selected) {
    return <></>;
  }

  return (
    <div className={`dashboard-status flex-1 ${className} ${cursorClass} ${selectedClass}`} onClick={onClick}>
      <div className="title">{title}</div>
      <div className="body">{count}</div>
    </div>
  );
};

export default StatusCard;

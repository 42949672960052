import React from 'react';
import moment from 'moment';
import { today, todayYear } from '../config';
import overdueSvg from '../assets/overdue.svg';
import deletedSvg from '../assets/deleted.svg';
import inprogressSvg from '../assets/inprogress.svg';
import completedSvg from '../assets/completed.svg';
import snoozedSvg from '../assets/snoozed.svg';
import upcomingSvg from '../assets/upcoming.svg';
import warnedSvg from '../assets/warned.svg';

export const GetStatus = (deadline) => {
  let date = today();

  if (deadline.completedDate) {
    return 'Completed';
  } else if (deadline.deletedDate) {
    return 'Deleted';
  } else if (deadline.snoozeDate && date < new Date(deadline.snoozeDate)) {
    return 'Snoozed';
  } else if (date > new Date(deadline.deadlineDate)) {
    return 'Overdue';
  } else if (date >= new Date(deadline.warnDate)) {
    return 'Warned';
  } else if (date >= new Date(deadline.openDate)) {
    return 'InProgress';
  } else {
    return 'Upcoming';
  }
};

export const formatDate = (date) => {
  const m = moment(date);

  if (m.year() === todayYear()) {
    return m.format('DD MMM');
  }

  return m.format('DD MMM YY');
};

export const formatDateWithYear = (date) => {
  const m = moment(date);

  return m.format('DD MMM YY');
};

export const GetDeadlineDate = (deadline) => {
  return formatDate(deadline.deadlineDate);
};

export const GetSnoozeDate = (deadline) => {
  return formatDate(deadline.snoozeDate);
};

export const GetOpenDate = (deadline) => {
  return formatDate(deadline.openDate);
};

export const GetStatusColourStyle = (deadline) => {
  switch (GetStatus(deadline)) {
    case 'Completed':
      return 'text-green-700';
    case 'Deleted':
      return 'text-red-700';
    case 'Overdue':
      return 'text-red-700';
    case 'Warned':
      return 'text-orange-500';
    case 'InProgress':
      return 'text-green-700';
    case 'Snoozed':
      return 'text-gray-600';
    default:
      return 'text-gray-600';
  }
};

export const GetStatusImage = (deadline) => {
  const className = 'w-12 py-1';

  switch (GetStatus(deadline)) {
    case 'Completed':
      return <img src={completedSvg} alt="Completed" className={className} />;
    case 'Deleted':
      return <img src={deletedSvg} alt="Deleted" className={className} />;
    case 'Overdue':
      return <img src={overdueSvg} alt="Overdue" className={className} />;
    case 'Warned':
      return <img src={warnedSvg} alt="Warned" className={className} />;
    case 'InProgress':
      return <img src={inprogressSvg} alt="Inprogress" className={className} />;
    case 'Snoozed':
      return <img src={snoozedSvg} alt="Snoozed" className={className} />;
    default:
      return <img src={upcomingSvg} alt="Upcoming" className={className} />;
  }
};

export const GetStatusIcon = (deadline) => {
  let icon = '';

  switch (GetStatus(deadline)) {
    case 'Completed':
      icon = 'fa-check-double';
      break;
    case 'Deleted':
      icon = 'fa-trash-alt';
      break;
    case 'Overdue':
      icon = 'fa-siren-on';
      break;
    case 'Warned':
      icon = 'fa-alarm-exclamation';
      break;
    case 'InProgress':
      icon = 'fa-alarm-clock';
      break;
    case 'Snoozed':
      icon = 'fa-alarm-snooze';
      break;
    default:
      icon = 'fa-clock';
      break;
  }

  return <i className={`fad fa-fw fa-2x ${icon}`} />;
};

const DeadlineProgressOrder = (deadline) => {
  switch (GetStatus(deadline)) {
    case 'Overdue':
      return 1;
    case 'Warned':
    case 'InProgress':
      if (deadline.deadlineDate === deadline.openDate) {
        return 1;
      }

      return moment(today()).diff(deadline.openDate, 'days') / moment(deadline.deadlineDate).diff(deadline.openDate, 'days');

    default:
      return 0;
  }
};

const DeadlineStatusOrder = (deadline) => {
  switch (GetStatus(deadline)) {
    case 'Overdue':
      return 1;
    case 'Warned':
      return 2;
    case 'InProgress':
      return 3;
    case 'Snoozed':
      return 4;
    case 'Upcoming':
      return 5;
    default:
      return 10;
  }
};

const CompareDeadlines = (a, b) => {
  // Status
  const aStatusOrder = DeadlineStatusOrder(a);
  const bStatusOrder = DeadlineStatusOrder(b);

  if (aStatusOrder > bStatusOrder) {
    return 1;
  }
  if (aStatusOrder < bStatusOrder) {
    return -1;
  }

  // Deadline Date
  if (a.deadlineDate > b.deadlineDate) {
    return 1;
  }

  if (a.deadlineDate < b.deadlineDate) {
    return -1;
  }

  // Progress
  const aProgressOrder = DeadlineProgressOrder(a);
  const bProgressOrder = DeadlineProgressOrder(b);

  if (aProgressOrder > bProgressOrder) {
    return -1;
  }

  if (aProgressOrder < bProgressOrder) {
    return 1;
  }

  // Name
  if (a.deadlineName.toUpperCase() > b.deadlineName.toUpperCase()) {
    return 1;
  }
  return -1;
};

export const SortDeadlines = (deadlines) => {
  return deadlines.sort(CompareDeadlines);
};

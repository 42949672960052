import moment from 'moment';
import { today } from '../config';

export const FormatDate = (stringDate) => {
  const m = moment(stringDate);

  if (m.isValid) {
    return m.format('DD MMM YYYY');
  }
  return stringDate;
};

export const formattedDate = (date) => {
  if (!date) {
    return null;
  }

  if (moment.isMoment(date)) {
    return date.format('YYYY-MM-DD');
  }

  const m = moment([date.getFullYear(), date.getMonth(), date.getDate()]);

  return m.format('YYYY-MM-DD');
};

export const ExpiryDateDisplayDate = (date, noExpiryText = '') => {
  const m = moment(date);
  const maxDate = moment('2999-12-31');

  if (m.isSame(maxDate)) {
    return noExpiryText;
  }

  return m.format('DD MMM YYYY');
};

export const ExpiryDateDisplay = (stringDate) => {
  if (!stringDate) {
    return 'MISSING';
  }

  if (stringDate === '31/12/2999') {
    return '';
  }

  return stringDate;
};

export const ExpiryDateClass = (stringDate) => {
  if (!stringDate) {
    return 'font-semibold';
  }

  const m = moment(stringDate, 'DD/MM/YYYY');
  const recent = moment(today()).subtract(7, 'days');

  if (m.isAfter(recent)) {
    return 'font-semibold';
  }

  return '';
};

export const AddDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

import moment from 'moment';
import React from 'react';
import { FormatDate } from '../../Helpers/dateHelpers';
import DashboardItem2 from './DashboardItem2';
import { dayFilteredData2 } from './helpers/calendarViewHelpers';

const CalendarDayView2 = ({ day, month, data, filter, bankHolidays, holidays, setNotesDeadline, setOptionsDeadline, handleProcessAction }) => {
  const instances = dayFilteredData2(day, month, data, filter);
  const bh = bankHolidays.filter((x) => moment(x).isSame(new Date(month.getFullYear(), month.getMonth(), day)));
  const h = holidays.filter((x) => moment(new Date(month.getFullYear(), month.getMonth(), day)).isBetween(x.fromDate, x.toDate, 'day', '[]'));

  if (instances.length === 0 && bh.length === 0 && h.length === 0) {
    return null;
  }

  return (
    <>
      {instances.length > 0 && (
        <div className="card mt-4 sm:mt-2">
          <table className="w-full headless">
            <thead>
              <tr>
                <th className="w-auto"></th>
                <th className="w-auto"></th>
                <th className="w-full"></th>
                <th className="w-auto"></th>
                <th className="w-auto"></th>
                <th className="w-auto"></th>
                <th className="w-auto"></th>
              </tr>
            </thead>
            <tbody>
              {instances.map((item, row) => (
                <DashboardItem2 row={row} key={item.deadlineInstanceId} item={item} tab="All" filter={filter} byMode="AssignedTo" notesClicked={setNotesDeadline} optionsClicked={setOptionsDeadline} processAction={handleProcessAction} />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {bh.length > 0 && (
        <div className="card mt-4 sm:mt-2 flex items-center justify-start">
          <div className="text-red-700 fad fa-gifts p-0 ml-2" />
          <div className="px-2 text-indigo-600 text-lg font-semibold uppercase tracking-tight">Bank Holiday</div>
        </div>
      )}
      {h.length > 0 && (
        <div className="card mt-4 sm:mt-2 ">
          <div className="flex items-center justify-start mb-4 sm:mt-2">
            <div className="text-red-700 fad fa-gifts p-0 ml-2" />
            <div className="px-2 text-indigo-600 text-lg font-semibold uppercase tracking-tight">Holidays</div>
          </div>
          <table className="w-full stripe headless">
            <thead>
              <tr>
                <td className="w-full"></td>
                <th className="w-auto hidden sm:table-cell"></th>
                <th className="w-auto hidden sm:table-cell"></th>
                <th className="w-auto hidden sm:table-cell"></th>
              </tr>
            </thead>
            <tbody>
              {h.map((item, index) => {
                return (
                  <tr key={index} className={`${!item.isEnabled && 'text-gray-500'}`}>
                    <td>
                      {item.userDisplayName}
                      <div className="sm:hidden">
                        {FormatDate(item.fromDate)} - {FormatDate(item.toDate)}
                      </div>
                    </td>
                    <td className="whitespace-no-wrap text-center hidden sm:table-cell">{FormatDate(item.fromDate)}</td>
                    <td className="text-center hidden sm:table-cell"> - </td>
                    <td className="whitespace-no-wrap pr-2 text-center hidden sm:table-cell">{FormatDate(item.toDate)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CalendarDayView2;

import React, { useState, useEffect, useRef } from 'react';
import FontAwesomeButton from './FontAwesomeButton';

const OptionsDropdown = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <>
      <div className="relative" ref={wrapperRef}>
        <FontAwesomeButton icon="caret-square-left" size="2x" onClick={() => setIsOpen(!isOpen)} />
        <div className={`text-sm bg-indigo-700 p-2 absolute whitespace-no-wrap ${isOpen ? 'block' : 'hidden'} `} style={{ right: 30, top: 8, zIndex: 1000 }} onClick={() => setIsOpen(false)}>
          {children}
        </div>
      </div>
    </>
  );
};

export default OptionsDropdown;

import React, { useCallback, useEffect, useState } from 'react';
import { apiHolidayDelete, apiHolidays } from '../../APIs/user';
import { FormatDate } from '../../Helpers/dateHelpers';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import LoadingCard from '../Common/LoadingCard';
import OptionsDropdown from '../Common/OptionsDropdown';

const HolidayList = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const LoadData = useCallback(async () => {
    setData(await apiHolidays());
    setLoading(false);
  }, []);

  useEffect(() => {
    LoadData();
  }, [LoadData]);

  const handleDelete = (id) => async () => {
    await apiHolidayDelete(id);
    await LoadData();
  };

  const TitleAddButton = () => {
    return <FontAwesomeLink icon="plus-square" text="New" textAlways href="/holiday/Add" />;
  };

  return (
    <LoadingCard loading={loading} title="Holidays" titleChildren={<TitleAddButton />}>
      {data.length === 0 ? (
        <div className="p-2 text-center">None found</div>
      ) : (
        <table className="w-full stripe headless">
          <thead>
            <tr>
              <th className="w-12"></th>
              <td className="w-full"></td>
              <th className="w-auto hidden sm:table-cell"></th>
              <th className="w-auto hidden sm:table-cell"></th>
              <th className="w-auto hidden sm:table-cell"></th>
              <th className="w-12"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index} className={`${!item.isEnabled && 'text-gray-500'}`}>
                  <td className="whitespace-no-wrap py-3">
                    <FontAwesomeLink icon="pen-square" size="2x" href={`/holiday/${item.id}`} />
                  </td>
                  <td>
                    {item.userDisplayName}
                    <div className="sm:hidden">
                      {FormatDate(item.fromDate)} - {FormatDate(item.toDate)}
                    </div>
                  </td>
                  <td className="whitespace-no-wrap text-center hidden sm:table-cell">{FormatDate(item.fromDate)}</td>
                  <td className="text-center hidden sm:table-cell"> - </td>
                  <td className="whitespace-no-wrap pr-2 text-center hidden sm:table-cell">{FormatDate(item.toDate)}</td>
                  <td>
                    <OptionsDropdown>
                      <button className="text-gray-100 focus:outline-none block p-2" onClick={handleDelete(item.id)}>
                        Delete
                      </button>
                    </OptionsDropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </LoadingCard>
  );
};

export default HolidayList;

import React, { useContext } from 'react';
import BlockButton from '../Common/BlockButton';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import PageTitle from '../Common/PageTitle';
import HelpLevelsCreate from './HelpLevelsCreate';
import { UserContext } from '../../Contexts/UserContext';

const HelpLevels = () => {
  const { level1Name, level2Name } = useContext(UserContext);

  return (
    <>
      <PageTitle title="Help : Categories" />
      <div className="help-card">
        <article className="help">
          <div className="help-title">Setting Category Names</div>
          <div>To add names to your category levels:</div>
          <div>
            1. In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-sliders-v" />
              Admin
            </div>
            then
            <div className="help-nav">
              <i className="fad fa-chart-network" />
              My Organisation
            </div>
          </div>
          <div>2. Under Level 1 and Level 2 Names type the name of the Category Levels.</div>
          <div>
            3. Click
            <div className="help-button-small">
              <BlockButton text="Save" icon="save" type="button" mt={0} buttonSize="small" />
            </div>
          </div>
          <div className="help-note help-note-bottom">
            <span>NB: </span>These names can be changed at a later date but this will overwrite the previous name.
          </div>
        </article>
      </div>
      <HelpLevelsCreate />
      <div className="help-card">
        <article className="help">
          <div className="help-title">Editing Categories</div>
          <div>To change the name, add notes or enable / disable a category:</div>
          <div>
            1. In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-sliders-v" />
              Admin
            </div>
            then
            <div className="help-nav">
              <i className="fad fa-list" />
              {level1Name || '[Level 1]'} List
            </div>
            or
            <div className="help-nav">
              <i className="fad fa-list fa-swap-opacity" />
              {level2Name || '[Level 2]'} List
            </div>
          </div>
          <div>
            2. Select the category by clicking the edit button <FontAwesomeLink icon="pen-square" href="#" m={0} />
          </div>
          <div>
            3. Update the category name and any associated notes, if required. <br />
          </div>
          <div>4. Tick the Enabled box, to the right of the name, on or off to disable or enable this category.</div>
          <div>
            5. Click
            <div className="help-button-small">
              <BlockButton text="Save" icon="save" type="button" mt={0} buttonSize="small" />
            </div>
            to save this category and return to the category list.
          </div>
          <div className="help-note help-note-bottom">
            <span className="">NB:</span> Changing the name overwrites the existing name in all deadlines for this category.
          </div>
        </article>
      </div>
    </>
  );
};

export default HelpLevels;

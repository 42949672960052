import * as Yup from 'yup';

export const deadlineValidation = Yup.object({
  name: Yup.string().required('Required'),
  fromDate: Yup.date().required('Required').min('2020/01/01', 'Invalid').typeError('Invalid'),
  toDate: Yup.date().nullable().min(Yup.ref('fromDate'), 'Invalid').typeError('Invalid'),
  repeatStep: Yup.number().integer('Invalid').nullable().min(1, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  day: Yup.number().integer().nullable('Invalid').min(1, 'Invalid').max(31, 'Invalid').typeError('Invalid'),
  warnDays: Yup.number().integer('Invalid').min(0, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  warnMonths: Yup.number().integer('Invalid').min(0, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  openDays: Yup.number().integer('Invalid').min(0, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  openMonths: Yup.number().integer('Invalid').min(0, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  adjustDays: Yup.number().integer('Invalid').min(-999, 'Invalid').max(999, 'Invalid').typeError('Invalid'),
});

export const templateValidation = Yup.object({
  name: Yup.string().required('Required'),
  repeatStep: Yup.number().integer('Invalid').nullable().min(1, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  day: Yup.number().integer().nullable('Invalid').min(1, 'Invalid').max(31, 'Invalid').typeError('Invalid'),
  warnDays: Yup.number().integer('Invalid').min(0, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  warnMonths: Yup.number().integer('Invalid').min(0, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  openDays: Yup.number().integer('Invalid').min(0, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  openMonths: Yup.number().integer('Invalid').min(0, 'Invalid').max(500, 'Invalid').typeError('Invalid'),
  adjustDays: Yup.number().integer('Invalid').min(-999, 'Invalid').max(999, 'Invalid').typeError('Invalid'),
});

import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { apiLicenseCheck } from '../../APIs/license';
import { UserContext } from '../../Contexts/UserContext';
import { stripeSubscriptionStatusOk, stripeSubscriptionStatusIncomplete, stripeSubscriptionStatusMissing } from '../../Helpers/constants';
import PageTitle from '../Common/PageTitle';
import CardForm from './CardForm';
import SubscriptionOption from './SubscriptionOption';
import { stripeAPIKey } from '../../config';

const stripePromise = loadStripe(stripeAPIKey());

const SubscriptionIssue = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('Checking....');
  const [showCardForm, setShowCardForm] = useState(false);
  const [license, setLicense] = useState(undefined);
  const { LoadUserSettings, GetLicense, userIsAdmin } = useContext(UserContext);

  useEffect(() => {
    const LoadData = async () => {
      var result = await apiLicenseCheck();

      switch (result.status) {
        case stripeSubscriptionStatusOk:
          await LoadUserSettings();
          toast('License Updated');
          history.push('/');
          return;
        case stripeSubscriptionStatusIncomplete:
        case stripeSubscriptionStatusMissing:
          setStatus(result.status);
          setShowCardForm(true);
          break;
        default:
          setStatus(result.status);
          break;
      }

      setLicense(GetLicense());

      setLoading(false);
    };

    if (userIsAdmin) {
      LoadData();
    } else {
      setStatus('Contact Admin');
      setShowCardForm(false);
      setLoading(false);
    }
  }, [history, LoadUserSettings, GetLicense, userIsAdmin]);

  const getStatusDetails = () => {
    if (loading) {
      return { title: 'Subscription - Checking.....', text: 'Checking the status of your subscription, please wait.' };
    }

    switch (status) {
      case 'Contact Admin':
        return { title: 'Subscription - Contact Admin', text: 'There is an issue with your Deadline Tracker subscription, please contact your System Admin.' };
      case stripeSubscriptionStatusIncomplete:
        return { title: 'Subscription - Payment Incomplete', text: 'The previous attempt to set up the subscription payment failed.  Please try again below.' };
      case stripeSubscriptionStatusMissing:
        return { title: 'Subscription - Not Found', text: 'No Subscription found.  This is most likely caused by an incomplete previous payment that has now expired.  Please complete the details below to purchase a subscription.' };
      default:
        return { title: 'Subscription', text: null };
    }
  };

  const handleOnOk = () => {
    history.push('/');
  };

  return (
    <div className="w-full">
      <PageTitle title={getStatusDetails().title} />
      <div className="card mb-4 p-4">{getStatusDetails().text}</div>
      {license && <SubscriptionOption data={license} selected={license} extraClasses="mb-4" />}
      {showCardForm && (
        <Elements stripe={stripePromise}>
          <CardForm licenseType={license} onOk={handleOnOk} fullWidth />
        </Elements>
      )}
    </div>
  );
};

export default SubscriptionIssue;

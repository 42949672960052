import { apiUrl } from '../config';
import axios from 'axios';

export const apiLevels = async (levelNumber, mustInclude, all) => {
  const result = await axios({
    method: 'get',
    url: `v1/Manager/Levels/${levelNumber}`,
    baseURL: apiUrl(),
  });

  return result.data.filter((x) => x.isEnabled || x.id === mustInclude || all);
};

export const apiLevel = async (id) => {
  const result = await axios({
    method: 'get',
    url: `v1/Admin/Level/${id}`,
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiLevelSave = async (id, levelNumber, name, groupName, notes, isEnabled) => {
  if (notes === '') {
    notes = null;
  }

  if (groupName === '') {
    groupName = null;
  }

  const result = await axios({
    method: 'post',
    url: `v1/Admin/Level/Save`,
    data: { id, levelNumber, name, groupName, notes, isEnabled },
    baseURL: apiUrl(),
  });

  if (result && result.data) {
    return result.data;
  }

  return id;
};

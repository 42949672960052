import 'moment/locale/en-gb';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import Calendar from 'react-calendar';
import { apiUsers } from '../../APIs/referenceData';
import { today } from '../../config';
import { UserContext } from '../../Contexts/UserContext';
import { GetDeadlineDate, GetStatus } from '../../Helpers/deadlineHelpers';
import FontAwesomeButton from '../Common/FontAwesomeButton';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import Modal from '../Common/Modal';

const InstanceOptions = ({ deadline, show, hide, processAction }) => {
  const status = GetStatus(deadline);
  const [showSnooze, setShowSnooze] = useState(false);
  const [snoozeDate, setSnoozeDate] = useState(today());
  const [showExtend, setShowExtend] = useState(false);
  const [extendDate, setExtendDate] = useState(today());
  const [showAssign, setShowAssign] = useState(false);
  const [assignAll, setAssignAll] = useState(true);
  const [assignTo, setAssignTo] = useState(null);
  const [users, setUsers] = useState({});
  const { userCount, userIsManager } = useContext(UserContext);
  const [showTag, setShowTag] = useState(false);
  const [tag, setTag] = useState('');

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  useEffect(() => {
    setSnoozeDate(addDays(today(), 7));
    setExtendDate(addDays(deadline.deadlineDate, 7));
    setAssignTo(deadline.assignedTo);
  }, [deadline, userIsManager, userCount]);

  const action = (actionName) => {
    switch (actionName) {
      case 'Snooze':
        processAction(deadline, actionName, snoozeDate);
        break;
      case 'Extend':
        processAction(deadline, actionName, extendDate);
        break;
      case 'Assign':
        if (assignAll) {
          processAction(deadline, 'AssignAll', assignTo);
        } else {
          processAction(deadline, 'AssignOne', assignTo);
        }
        break;
      case 'Tag':
        processAction(deadline, 'Tag', tag);
        break;
      default:
        processAction(deadline, actionName);
        break;
    }
    setShowSnooze(false);
    setShowExtend(false);
    setShowAssign(false);
    setShowTag(false);
  };

  const assignedToChanged = (e) => {
    setAssignTo(e.target.value);
  };

  const handleShowAssign = (all) => async () => {
    if (Object.keys(users).length === 0) {
      setUsers(await apiUsers());
    }

    setAssignAll(all);
    setShowAssign(true);
  };

  const openTagModel = () => {
    setTag(deadline.tag || '');
    setShowTag(true);
  };

  return (
    <>
      {show && deadline && (
        <>
          <Modal title={`${deadline.deadlineName} - ${GetDeadlineDate(deadline)}`} subtitle={deadline.hasLevels && deadline.levelName} show={show} hide={hide} modalStyle="w-full max-w-lg">
            <div className="w-full">
              {status === 'Upcoming' && <FontAwesomeButton text="Start Now" textAlways icon="hourglass-start" onClick={() => action('StartNow')} fullWidth />}
              <FontAwesomeButton text="Mark As Done" textAlways icon="clipboard-check" onClick={() => action('Done')} fullWidth />
              <FontAwesomeButton text="Extend Deadline" textAlways icon="alarm-plus" onClick={() => setShowExtend(true)} fullWidth />
              <FontAwesomeButton text="Snooze Deadline" textAlways icon="alarm-snooze" onClick={() => setShowSnooze(true)} fullWidth />
              {status === 'Snoozed' && <FontAwesomeButton text="Unsnooze Deadline" textAlways icon="clock" onClick={() => action('Unsnooze')} fullWidth />}
              <FontAwesomeButton text="Delete This Instance Only" textAlways icon="trash-alt" onClick={() => action('DeleteOne')} fullWidth />
              {userIsManager && (
                <>
                  <FontAwesomeButton text="Delete All Instances" textAlways icon="dumpster-fire" onClick={() => action('DeleteAll')} fullWidth />
                  <FontAwesomeLink text="Edit Deadline" textAlways icon="edit" href={`/deadline/${deadline.deadlineId}`} fullWidth />
                  {userCount > 1 && (
                    <>
                      <FontAwesomeButton text="Assign This Instance Only" textAlways icon="user-circle" onClick={handleShowAssign(false)} fullWidth />
                      <FontAwesomeButton text="Assign All Instances" textAlways icon="user-circle" onClick={handleShowAssign(true)} fullWidth />
                    </>
                  )}
                </>
              )}
              <FontAwesomeButton text="Tag" textAlways icon="tags" onClick={openTagModel} fullWidth />
            </div>
          </Modal>
          <Modal title="Snooze" show={showSnooze} hide={() => setShowSnooze(false)}>
            <Calendar value={snoozeDate} onChange={setSnoozeDate} locale="en-GB" className="inline" minDate={new Date('2020/01/01')} maxDate={new Date('2100/12/31')} />
            <div className="w-full">
              <FontAwesomeButton text="Snooze Deadline" textAlways icon="alarm-snooze" onClick={() => action('Snooze')} fullWidth />
            </div>
          </Modal>
          <Modal title="Extend" show={showExtend} hide={() => setShowExtend(false)}>
            <Calendar value={extendDate} onChange={setExtendDate} locale="en-GB" className="inline" minDate={new Date('2020/01/01')} maxDate={new Date('2100/12/31')} />
            <div className="w-full">
              <FontAwesomeButton text="Extend Deadline" textAlways icon="alarm-plus" onClick={() => action('Extend')} fullWidth />
            </div>
          </Modal>
          <Modal title="Tag" show={showTag} hide={() => setShowTag(false)}>
            <div className="input-text">
              <input type="text" value={tag} onChange={(e) => setTag(e.target.value)} className="focus:outline-none" maxLength="20" />
            </div>
            <div className="w-full">
              <FontAwesomeButton text="Apply" textAlways icon="tags" onClick={() => action('Tag')} fullWidth />
            </div>
          </Modal>
          <Modal title="Assign To" show={showAssign} hide={() => setShowAssign(false)}>
            <div className="w-full">
              <div className="relative">
                <select
                  className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  defaultValue={assignTo}
                  onChange={assignedToChanged}>
                  <option value="">(Not Assigned)</option>
                  {Object.keys(users).map((key) => (
                    <Fragment key={key}>{!users[key].includes('(Disabled') && <option value={key}>{users[key]}</option>}</Fragment>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
              <FontAwesomeButton text={assignAll ? 'Assign All Instances' : 'Assign This Instance Only'} textAlways icon="user-circle" onClick={() => action('Assign')} fullWidth />
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default InstanceOptions;

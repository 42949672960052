import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiForgotPassword } from '../../APIs/auth';
import PageTitle from '../Common/PageTitle';
import BlockButton from '../Common/BlockButton';
import InputText from '../Common/InputText';

const ForgotPassword = ({ history }) => {
  const [done, setDone] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await apiForgotPassword(values.email, values.password);
      setLoading(false);

      setDone(true);
    },
  });

  const handleReturnToLogin = () => {
    history.push('/');
  };

  return (
    <div className="flex absolute w-screen top-0 left-0 h-screen align-middle">
      <div className="m-auto w-full sm:w-3/4 md:w-1/2">
        <PageTitle title="Forgot Password" withLogoCard />
        <div className="card">
          {done ? (
            <form method="get" action="/login">
              <p className="px-2 py-4">Please check your email for instructions on how to reset your password.</p>
              <BlockButton text="Return to Login" />
            </form>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <InputText formik={formik} label="Email Address" field="email" icon="at" />

              <div className="inline-flex w-full space-x-1">
                <BlockButton text="Send Reset" iconSpin={loading} icon={loading ? 'hurricane' : null} disabled={loading} groupPosition="left" />
                <BlockButton text="Return to Login" type="button" buttonType="secondary" onClick={handleReturnToLogin} groupPosition="right" />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

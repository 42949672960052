import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { apiDeadlineInstancesMonth } from '../../APIs/dashboard';
import { UserContext } from '../../Contexts/UserContext';
import FontAwesomeButton from '../Common/FontAwesomeButton';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import PageTitle from '../Common/PageTitle';
import CalendarDayView2 from './CalendarDayView2';
import FilterPanel from './FilterPanel';
import { anyHolidays, calculateDaysInMonth, calculateSpacerDays, Counts2, getDayClass, getDayDataClass, getSpacerClass } from './helpers/calendarViewHelpers';
import { processAction } from './helpers/generalHelpers';
import InstanceOptions from './InstanceOptions';
import NotesModel from './NotesModel';

const CalendarView2 = () => {
  const [loading, setLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [data, setData] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [bankHolidays, setBankHolidays] = useState([]);
  const [month, setMonth] = useState(moment().startOf('month').toDate());
  const [day, setDay] = useState(undefined);
  const [filter, setFilter] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const { userIsManager, canFilter } = useContext(UserContext);
  const [notesDeadline, setNotesDeadline] = useState(undefined);
  const [optionsDeadline, setOptionsDeadline] = useState({});

  const GetData = useCallback(async () => {
    if (isSubscribed && month) {
      setLoading(true);
      const result = await apiDeadlineInstancesMonth(month);

      if (result && isSubscribed) {
        setData(result.deadlineInstances);
        setHolidays(result.holidays);
        setBankHolidays(result.bankHolidays);
      }
    }
    setLoading(false);
  }, [isSubscribed, month]);

  useEffect(() => {
    return () => setIsSubscribed(false);
  }, []);

  useEffect(() => {
    try {
      const filterJSON = localStorage.getItem('DT_Filter') || undefined;
      if (filterJSON) {
        setFilter(JSON.parse(filterJSON));
      }
    } catch {
      localStorage.removeItem('DT_Filter');
      localStorage.removeItem('DT_FilterOptions');
      setFilter();
    }

    if (month) {
      GetData();
      setLoading(false);
    }
  }, [month, GetData]);

  const hasFilter = () => {
    if (!filter) {
      return false;
    }

    try {
      if (filter.level1s && filter.level1s.length > 0) {
        return true;
      }
      if (filter.level2s && filter.level2s.length > 0) {
        return true;
      }
      if (filter.bys && filter.bys.length > 0) {
        return true;
      }
    } catch {
      localStorage.removeItem('DT_Filter');
      localStorage.removeItem('DT_FilterOptions');
      setFilter();
    }

    return false;
  };

  const moveMonth = (adjust) => {
    setLoading(true);
    const m = moment(month).add(adjust, 'M');
    setMonth(m.toDate());
    setDay(undefined);
  };

  const handleProcessAction = async (deadline, actionName, extra) => {
    await processAction(deadline, actionName, extra);
    GetData();
    setOptionsDeadline({});
  };

  return (
    <>
      <PageTitle title="Calendar">
        {userIsManager && <FontAwesomeLink icon="plus-square" text="New" textAlways href="/deadline/Add" />}
        {canFilter && <FontAwesomeButton icon="filter" text={`Filter ${hasFilter() ? ' (ON)' : ''}`} textAlways onClick={() => setShowFilter(!showFilter)} />}
      </PageTitle>
      <NotesModel notesDeadline={notesDeadline} close={() => setNotesDeadline(null)} />
      <FilterPanel showFilter={showFilter} setShowFilter={setShowFilter} data={data} setFilter={setFilter} filter={filter} />
      <InstanceOptions deadline={optionsDeadline} show={optionsDeadline?.deadlineDate} hide={() => setOptionsDeadline({})} processAction={handleProcessAction} />
      {month && !loading && (
        <>
          <div className="card mt-4 sm:mt-2">
            <div className="flex justify-between mb-4">
              <span className="cal-nav cursor-pointer" onClick={() => moveMonth(-1)}>
                &lt; Prev
              </span>
              <span className="cal-title">{moment(month).format('MMMM YYYY')}</span>
              <span className="cal-nav cursor-pointer" onClick={() => moveMonth(1)}>
                Next &gt;
              </span>
            </div>
            <div className="grid grid-cols-12 space-x-0.5 space-y-0.5 sm:space-x-1 sm:space-y-1">
              <div className="day-header">Mon</div>
              <div className="day-header">Tue</div>
              <div className="day-header">Wed</div>
              <div className="day-header">Thu</div>
              <div className="day-header">Fri</div>
              <div className="weekend-header">
                S<span className="hidden sm:inline">at</span>
              </div>
              <div className="weekend-header">
                S<span className="hidden sm:inline">un</span>
              </div>
              {Array.from({ length: calculateSpacerDays(month) }, (_, i) => i + 1).map((x, k) => (
                <div key={k} className={getSpacerClass(x)} />
              ))}
              {Array.from({ length: calculateDaysInMonth(month) }, (_, i) => i + 1).map((d, k) => (
                <div key={k} className={`${getDayClass(d, month, day)} cursor-pointer`} onClick={() => setDay(d)}>
                  <div className="day-number">{d}</div>
                  <div className="day-holiday">{anyHolidays(d, month, holidays, bankHolidays) && <div className="text-red-700 fad fa-gifts p-0" />}</div>
                  <div className={getDayDataClass(d, month, day)}>
                    <Counts2 {...{ d, data, month, filter }} />
                    {anyHolidays(d, month, holidays, bankHolidays) && <div className="sm:hidden fad fa-gifts p-0 px-0 sm:px-1 h-6 flex items-center text-red-700" />}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {day && <CalendarDayView2 {...{ day, data, month, filter, bankHolidays, holidays, setNotesDeadline, setOptionsDeadline, handleProcessAction }} />}
        </>
      )}
    </>
  );
};

export default CalendarView2;

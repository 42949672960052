import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Login from '../Components/Auth/Login';
import DashboardLayout from './DashboardLayout';
import Logout from '../Components/Auth/Logout';
import Error404 from '../Components/errors/404';
import Error500 from '../Components/errors/500';
import ForgotPassword from '../Components/Auth/ForgotPassword';
import ResetPassword from '../Components/Auth/ResetPassword';
import NewUser from '../Components/Auth/NewUser';
import SignUp from '../Components/Auth/SignUp';
import SignUpComplete from '../Components/Auth/SignUpComplete';
import Error426 from '../Components/errors/426';
import TermsAndConditions from '../Components/Auth/TermsAndConditions';
import Help from '../Components/help/Help';
import HelpAsUser from '../Components/help/HelpAsUser';
import MFACheck from '../Components/Auth/MFACheck';

const Layout = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/signup/:level" component={SignUp} />
        <Route path="/signup" component={SignUp} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
        <Route path="/signedup/:level" component={SignUpComplete} />
        <Route path="/signedup" component={SignUpComplete} />
        <Route path="/login" component={Login} />
        <Route path="/mfa" component={MFACheck} />
        <Route path="/logout" component={Logout} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/invite" component={NewUser} />
        <Route path="/help" exact component={Help} />
        <Route path="/help/as/user" exact component={HelpAsUser} />
        <Route path="/errors/404" component={Error404} />
        <Route path="/errors/426" component={Error426} />
        <Route path="/errors/500" component={Error500} />
        <Route component={DashboardLayout} />
      </Switch>
    </Router>
  );
};

Layout.propTypes = { location: PropTypes.object.isRequired };

export default withRouter(Layout);

import React, { useState } from 'react';
import NavSimple from '../Nav2/NavSimple';
import HelpDeadlines from './HelpDeadlines';
import HelpRoles from './HelpRoles';
import HelpSettings from './HelpSettings';
import HelpSupport from './HelpSupport';
import HelpTab from './HelpTab';

const tabs = ['Deadlines', 'Settings', 'Roles', 'Support'];

const HelpAsUser = () => {
  const [tab, setTab] = useState(tabs[0]);

  const selectTab = (e) => {
    setTab(e.target.value);
  };

  const CurrentHelp = () => {
    switch (tab) {
      case 'Roles':
        return <HelpRoles />;
      case 'Settings':
        return <HelpSettings />;
      case 'Support':
        return <HelpSupport />;
      case 'Deadlines':
      default:
        return <HelpDeadlines />;
    }
  };

  return (
    <NavSimple>
      <div className="w-full shadow-md md:hidden">
        <select className="mb-4 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 sm:text-sm sm:leading-5" value={tab} onChange={selectTab}>
          {tabs.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>

      <div className="md:grid md:grid-cols-5 md:gap-x-4">
        <div className="md:col-span-1 hidden md:block">
          <ul>
            {tabs.map((item) => (
              <HelpTab key={item} title={item} selected={tab === item} onClick={() => setTab(item)} />
            ))}
          </ul>
        </div>
        <div className="md:col-span-4">
          <CurrentHelp />
        </div>
      </div>
    </NavSimple>
  );
};

export default HelpAsUser;

import moment from 'moment';
import 'moment/locale/en-gb';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import BlockButton from '../Common/BlockButton';
import PageTitle from '../Common/PageTitle';

function AdjustDate({ history }) {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var stored = localStorage.getItem('DeadlineTracker-TestDate');

    if (stored) {
      setDate(new Date(stored));
    }
  }, []);

  const handleClear = () => {
    localStorage.removeItem('DeadlineTracker-TestDate');
    history.push('/');
  };

  const handleSet = () => {
    var m = moment(date);
    localStorage.setItem('DeadlineTracker-TestDate', m.format('YYYY-MM-DD'));
    history.push('/');
  };

  return (
    <>
      <PageTitle title="Adjust Date"></PageTitle>
      <div className="card rdtFullPage">
        <Calendar value={date} onChange={setDate} locale="en-GB" className="inline" minDate={new Date('2020/01/01')} maxDate={new Date('2100/12/31')} />
        <div className="inline-flex w-full space-x-1">
          <BlockButton text="Set" onClick={() => handleSet()} icon="calendar-star" mt={2} buttonType="primary" groupPosition="left" />
          <BlockButton text="Today" onClick={() => handleClear()} icon="calendar-times" mt={2} buttonType="secondary" groupPosition="right" />
        </div>
      </div>
    </>
  );
}

export default AdjustDate;

export const getFilterOptions = (data) => {
  const level1s = [];
  const level2s = [];
  const byNames = [];

  const savedFilterOptions = localStorage.getItem('DT_FilterOptions');

  if (savedFilterOptions) {
    try {
      const filterOptions = JSON.parse(savedFilterOptions);

      if (filterOptions && filterOptions.level1s) {
        filterOptions.level1s.forEach((x) => {
          level1s[x.id] = x.name;
        });
      }

      if (filterOptions && filterOptions.level2s) {
        filterOptions.level2s.forEach((x) => {
          level2s[x.id] = x.name;
        });
      }

      if (filterOptions && filterOptions.byNames) {
        filterOptions.byNames.forEach((x) => {
          byNames[x.id] = x.name;
        });
      }
    } catch {}
  }

  data
    .filter((x) => x.level1Id)
    .forEach((x) => {
      level1s[x.level1Id] = x.level1Name;
    });

  data
    .filter((x) => x.level1GroupName)
    .forEach((x) => {
      level1s[x.level1GroupName] = `${x.level1GroupName} [Group]`;
    });

  data
    .filter((x) => x.level2Id)
    .forEach((x) => {
      level2s[x.level2Id] = x.level2Name;
    });

  data
    .filter((x) => x.level2GroupName)
    .forEach((x) => {
      level2s[x.level2GroupName] = `${x.level2GroupName} [Group]`;
    });

  data.filter((x) => x.assignedTo).forEach((x) => (byNames[x.assignedTo] = x.assignedToDisplayName));
  data.filter((x) => x.completedBy).forEach((x) => (byNames[x.completedBy] = x.completedByDisplayName));
  data.filter((x) => x.deletedBy).forEach((x) => (byNames[x.deletedBy] = x.deletedByDisplayName));

  return { level1s: toSortedList(level1s), level2s: toSortedList(level2s), byNames: toSortedList(byNames) };
};

const compareList = (a, b) => {
  if (a.name.toUpperCase() > b.name.toUpperCase()) {
    return 1;
  }
  return -1;
};

const toSortedList = (data) => {
  const list = [];

  for (const [k, v] of Object.entries(data)) {
    list.push({ id: k, name: v });
  }

  list.sort(compareList);

  return list;
};

import React, { useContext } from 'react';
import BlockButton from '../Common/BlockButton';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import { UserContext } from '../../Contexts/UserContext';

const HelpLevelsCreate = ({ inline = false }) => {
  const { level1Name, level2Name } = useContext(UserContext);

  return (
    <div className={inline ? '' : 'help-card'}>
      <article className="help">
        <div className="help-title">Adding Categories</div>
        <div>To add categories:</div>
        <div>
          <div className="inline-block">1. In left navigation menu, select</div>
          <div className="help-nav">
            <i className="fad fa-sliders-v" />
            Admin
          </div>
          then
          <div className="help-nav">
            <i className="fad fa-list" />
            {level1Name || '[Level 1]'} List
          </div>
          or
          <div className="help-nav">
            <i className="fad fa-list fa-swap-opacity" />
            {level2Name || '[Level 2]'} List
          </div>
        </div>
        <div>
          2. Click on the <FontAwesomeLink icon="plus-square" text="New" textAlways href="#" m={0} />
          link under the page title.
        </div>
        <div>3. Enter the category name and any associated notes, if required</div>
        <div>
          4. Click
          <div className="help-button-small">
            <BlockButton text="Save" icon="save" type="button" mt={0} buttonSize="small" />
          </div>
          to save this category and return to the category list.
        </div>
        <div>
          5. Click
          <div className="help-button-small">
            <BlockButton text="Save + New" icon="save" type="button" mt={0} buttonSize="small" />
          </div>
          to save this category and add another.
        </div>
        <div>
          6. Click
          <div className="help-button-small">
            <BlockButton text="Cancel" icon="arrow-square-left" type="button" buttonType="secondary" mt={0} buttonSize="small" />
          </div>
          to not save this category and return to the category list.
        </div>
      </article>
    </div>
  );
};

export default HelpLevelsCreate;

import React, { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { apiLicenseSetupIntent, apiLicenseChangePaymentMethod } from '../../APIs/license';
import BlockButton from '../Common/BlockButton';

const options = {
  style: {
    base: {
      color: '#4c51bf',
      iconColor: '#4c51bf',
      fontFamily: 'Inter',
      fontSmoothing: 'antialiased',
      fontSize: '20px',
      '::placeholder': {
        color: '#7f9cf5',
      },
    },
    invalid: {
      color: '#e53e3e',
      iconColor: '#e53e3e',
    },
  },
};

const CardChangeForm = ({ onOk, fullWidth = false }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setError(null);
    setSaving(true);

    var clientSecret = await apiLicenseSetupIntent();

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      setError(result.error.message);
      setSaving(false);
      return;
    } else {
      setSaving(true);
      await apiLicenseChangePaymentMethod(result.setupIntent.payment_method);
      setSaving(false);
      onOk();
      return;
    }
  };

  const handleChange = (e) => {
    if (e.error && e.error.code) {
      setError(e.error.message);
      setIsComplete(false);
    } else {
      setIsComplete(e.complete);
      setError(null);
    }
  };

  const widthClasses = fullWidth ? 'w-full' : 'max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8';
  const widthClassesInner = fullWidth ? 'w-full' : 'max-w-md mx-auto md:max-w-5xl';

  return (
    <form onSubmit={handleSubmit} className={widthClasses}>
      <div className={widthClassesInner}>
        <div className="card mb-4 p-3 bg-white">
          <span className="font-extrabold">Note: </span> By entering new card details, your next subscription payment will use these details instead of the previously registered one. Stripe is used as our 3rd party provider for online payments and
          card details are not stored in our system.
        </div>
        <div className="border-indigo-700 border-4 p-2 rounded-md bg-white mb-4">
          <CardElement options={options} onChange={handleChange} />
        </div>
        {error && <div className="input-error">{error}</div>}
        <div className="-mt-2">
          <BlockButton text="Save Card" icon="hurricane" iconSpin={saving} disabled={saving || !stripe || !isComplete} />
        </div>
      </div>
    </form>
  );
};

export default CardChangeForm;

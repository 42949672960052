import React from 'react';
import FontAwesomeButton from '../Common/FontAwesomeButton';
import { GetOpenDate, GetSnoozeDate, GetStatus, GetStatusColourStyle, GetDeadlineDate, GetStatusImage } from '../../Helpers/deadlineHelpers';
import UserBadge from '../Common/UserBadge';

const DashboardItem2 = ({ item, tab, byMode, notesClicked, processAction, optionsClicked, filter }) => {
  const status = GetStatus(item);
  const statusColour = GetStatusColourStyle(item);
  const onHoliday = item.assignedToOnHoliday;

  const showRow = () => {
    if (!filter) {
      return true;
    }

    if (filter.level1s.length > 0 && !filter.level1s.includes(item.level1Id) && !filter.level1s.includes(item.level1GroupName)) {
      return false;
    }

    if (filter.level2s.length > 0 && !filter.level2s.includes(item.level2Id) && !filter.level2s.includes(item.level2GroupName)) {
      return false;
    }

    if (filter.bys.length > 0) {
      switch (byMode) {
        case 'Completed':
          if (filter.bys.includes(item.deletedBy) || filter.bys.includes(item.completedBy)) {
            return true;
          }
          break;
        default:
          if (!item.assignedTo && filter.bys.includes('(Unassigned)')) {
            return true;
          }
          if (filter.bys.includes(item.assignedTo)) {
            return true;
          }
          break;
      }

      return false;
    }

    return true;
  };

  const tabShow = tab === 'All' || tab === status || (tab === 'Active' && (status === 'InProgress' || status === 'Warned' || status === 'Overdue'));

  return (
    <>
      {(tabShow || (tab === 'Holiday' && onHoliday)) && showRow() && (
        <>
          <tr className={`dashboard-row text-indigo-800 sm:hidden border-b border-indigo-400`}>
            <td colSpan={7}>
              <div className="flex flex-row items-center my-2">
                <div className="w-12 px-1 flex-none"> {GetStatusImage(item)}</div>
                <div className="flex flex-col flex-grow">
                  <div className={`${statusColour} whitespace-no-wrap flex flex-row items-center`}>
                    <span className="font-semibold whitespace-no-wrap">{GetDeadlineDate(item)}</span>
                    {status === 'Snoozed' && (
                      <div className="text-xs ml-2">
                        <i className="fad fa-snooze mr-1" />
                        {GetSnoozeDate(item)}
                      </div>
                    )}
                    {status === 'Upcoming' && (
                      <div className="text-xs ml-2">
                        <i className="fad fa-hourglass-start mr-1" />
                        {GetOpenDate(item)}
                      </div>
                    )}
                  </div>
                  <div className="font-semibold">{item.deadlineName}</div>
                  <div className="flex-wrap">{item.hasLevels && <div className="text-xs">{item.levelName}</div>}</div>
                  <div>
                    {!item.completedByInitials && !item.deletedByInitials && item.assignedToInitials && (
                      <UserBadge displayName={item.assignedToDisplayName} initials={item.assignedToInitials} onHoliday={item.assignedToOnHoliday} assignedInstance={item.assignedInstance} />
                    )}
                    {item.completedByInitials && <UserBadge displayName={item.completedByDisplayName} initials={item.completedByInitials} prefix="By:" date={item.completedDate} />}
                    {item.deletedByInitials && <UserBadge displayName={item.deletedByDisplayName} initials={item.deletedByInitials} prefix="By:" date={item.deletedDate} />}
                  </div>
                </div>
                <div className="text-right ml-auto flex flex-col">
                  {status !== 'Upcoming' && status !== 'Completed' && status !== 'Deleted' && <FontAwesomeButton text="Done" icon="clipboard-check" onClick={async () => await processAction(item, 'Done')} />}
                  {status === 'Completed' || status === 'Deleted' ? (
                    <FontAwesomeButton text="Undo" icon="undo-alt" onClick={async () => await processAction(item, 'Reactivate')} />
                  ) : (
                    <FontAwesomeButton text="Options" icon="bars" onClick={() => optionsClicked(item)} />
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr className={`dashboard-row text-indigo-800 hidden sm:table-row border-b border-indigo-400`}>
            <td className={`${statusColour} pl-1`}>
              <div className="hidden sm:block w-12"> {GetStatusImage(item)}</div>
            </td>
            <td className={`${statusColour} whitespace-no-wrap text-center`}>
              <span className="font-semibold">{GetDeadlineDate(item)}</span>
              {status === 'Snoozed' && (
                <div className="text-xs">
                  <i className="fad fa-snooze mr-1" />
                  {GetSnoozeDate(item)}
                </div>
              )}
              {status === 'Upcoming' && (
                <div className="text-xs">
                  <i className="fad fa-hourglass-start mr-1" />
                  {GetOpenDate(item)}
                </div>
              )}
            </td>
            <td className="pl-1 md:pl-3">
              <div className="font-semibold">{item.deadlineName}</div>
              {item.hasLevels && <div className="text-xs">{item.levelName}</div>}
            </td>
            <td>{item.notes && <FontAwesomeButton icon="sticky-note" onClick={() => notesClicked(item)} />}</td>
            <td>
              {!item.completedByInitials && !item.deletedByInitials && item.assignedToInitials && (
                <UserBadge displayName={item.assignedToDisplayName} initials={item.assignedToInitials} onHoliday={item.assignedToOnHoliday} assignedInstance={item.assignedInstance} />
              )}
              {item.completedByInitials && <UserBadge displayName={item.completedByDisplayName} initials={item.completedByInitials} prefix="By:" date={item.completedDate} />}
              {item.deletedByInitials && <UserBadge displayName={item.deletedByDisplayName} initials={item.deletedByInitials} prefix="By:" date={item.deletedDate} />}
            </td>
            <td>{status !== 'Upcoming' && status !== 'Completed' && status !== 'Deleted' && <FontAwesomeButton text="Done" icon="clipboard-check" onClick={async () => await processAction(item, 'Done')} />}</td>
            <td className="text-right">
              {status === 'Completed' || status === 'Deleted' ? (
                <FontAwesomeButton text="Undo" icon="undo-alt" onClick={async () => await processAction(item, 'Reactivate')} />
              ) : (
                <FontAwesomeButton text="Options" icon="bars" onClick={() => optionsClicked(item)} />
              )}
            </td>
          </tr>
        </>
      )}
    </>
  );
};

export default DashboardItem2;

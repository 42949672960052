import React from 'react';

const HelpTab = ({ title, selected, onClick }) => {
  const colourClass = selected ? 'bg-indigo-600 text-gray-50 hover:text-gray-100 border-indigo-500' : 'bg-white text-gray-900 hover:text-gray-600 border-gray-200';
  const tabClass = selected ? 'bg-indigo-300' : 'bg-indigo-600';

  return (
    <li className="col-span-1 flex shadow-sm rounded-md mb-2 cursor-pointer h-16" onClick={onClick}>
      <div className={`flex-shrink-0 flex items-center justify-center w-2 ${tabClass} rounded-l-md`}></div>
      <div className={`flex-1 flex items-center justify-between border-t border-r border-b ${colourClass} rounded-r-md`}>
        <div className="flex-1 px-4 py-1 text-sm leading-5 break-words">{title}</div>
        <div className="flex-shrink-0 pr-2">
          <button className="w-4 h-8 inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:text-gray-500 focus:bg-gray-100 transition ease-in-out duration-150">
            <span className="fad fa-chevron-right"></span>
          </button>
        </div>
      </div>
    </li>
  );
};

export default HelpTab;

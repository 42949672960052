import { today } from '../config';
import { AddDays } from './dateHelpers';

export const daysOfWeek = [
  { id: 1, name: 'Monday' },
  { id: 2, name: 'Tuesday' },
  { id: 3, name: 'Wednesday' },
  { id: 4, name: 'Thursday' },
  { id: 5, name: 'Friday' },
  { id: 6, name: 'Saturday' },
  { id: 0, name: 'Sunday' },
];

export const workingDayRule = [
  { id: 0, name: 'Previous Working Day' },
  { id: 1, name: 'Next Working Day' },
];

export const defaultNewDeadline = {
  addMode: true,
  id: undefined,
  name: '',
  level1Id: '',
  level2Id: '',
  repeatTypeCode: 'NO',
  repeatStep: 1,
  day: undefined,
  dayOfWeek: undefined,
  adjustDays: 0,
  openDays: 1,
  openMonths: 0,
  warnDays: 1,
  warnMonths: 0,
  fromDate: AddDays(today(), 1),
  toDate: undefined,
  notes: '',
  assignedTo: '',
  workingDayRule: 0,
};

export const defaultNewTemplate = {
  addMode: true,
  id: undefined,
  name: '',
  level1Id: '',
  level2Id: '',
  repeatTypeCode: 'NO',
  repeatStep: 1,
  day: undefined,
  dayOfWeek: undefined,
  adjustDays: 0,
  openDays: 1,
  openMonths: 0,
  warnDays: 1,
  warnMonths: 0,
  notes: '',
  workingDayRule: 0,
};

export const licenseTypes = [
  { id: '1', name: 'Free', price: '0', details: 'Ideal to give it a try', features: ['Single user', '10 deadlines'], userLimit: 1, deadlineLimit: 10 },
  { id: '2', name: 'Lite', price: '10', details: 'Ideal for individuals', features: ['Single user', '100 deadlines', 'Categories'], userLimit: 1, deadlineLimit: 100, categories: true },
  { id: '3', name: 'Standard', price: '18', details: 'Ideal for small teams', features: ['5 users', 'Unlimited deadlines', 'Categories'], userLimit: 5, categories: true },
  { id: '4', name: 'Pro', price: '30', details: 'Ideal for growing businesses', features: ['Unlimited users', 'Unlimited deadlines', 'Categories'], categories: true },
];

export const countries = [
  { id: 'GB-EAW', name: 'England and Wales' },
  { id: 'GB-SCT', name: 'Scotland' },
  { id: 'GB-NIR', name: 'Northern Ireland' },
];

export const stripeSubscriptionStatusOk = 'OK';
export const stripeSubscriptionStatusError = 'Error';
export const stripeSubscriptionStatusIncomplete = 'Incomplete';
export const stripeSubscriptionStatusMissing = 'Missing';
export const stripeSubscriptionStatusCancelled = 'Cancelled';
export const stripeSubscriptionStatusRequiresAction = 'RequiresAcion';

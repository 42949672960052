import { apiLevels } from '../APIs/levels';
import { apiRepeatTypes, apiDeadlineTypes, apiUsers } from '../APIs/referenceData';

export const getReferenceData = async (loadLevel1, loadLevel2, includeLevel1Unassigned, includeLevel2Unassigned, level1Id, level2Id, loadUsers) => {
  let level1s = [];
  let level2s = [];
  if (loadLevel1) {
    level1s = await apiLevels(1, level1Id);
    if (includeLevel1Unassigned) {
      level1s = [{ id: '', name: '(unassigned)' }, ...level1s];
    }
  }
  if (loadLevel2) {
    level2s = await apiLevels(2, level2Id);
    if (includeLevel2Unassigned) {
      level2s = [{ id: '', name: '(unassigned)' }, ...level2s];
    }
  }

  const repeatTypes = await apiRepeatTypes();

  const deadlineTypes = await apiDeadlineTypes();

  const users = [];
  if (loadUsers) {
    const usersObject = await apiUsers();

    for (const [key, value] of Object.entries(usersObject)) {
      if (!value.includes('(Disabled')) {
        users.push({ id: key, name: value });
      }
    }
  }

  return { level1s, level2s, repeatTypes, deadlineTypes, users };
};

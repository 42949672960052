import React from 'react';
import { withRouter } from 'react-router-dom';

const NavFooterItem = ({ icon, text, error = false, children, history, link, className }) => {
  const errorClass = error ? 'bg-red-800' : '';

  const handleClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <span className={`p-2 inline-block whitespace-no-wrap ${errorClass} ${link ? 'cursor-pointer' : ''} ${className}`} onClick={handleClick}>
      <i className={`fad fa-${icon} fa-lg fa-fw mr-1`}></i>
      {text}
      {children}
    </span>
  );
};

export default withRouter(NavFooterItem);

import React from 'react';
import BlockButton from '../Common/BlockButton';
import PageTitle from '../Common/PageTitle';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import HelpUsersCreate from './HelpUsersCreate';

const HelpUsers = () => {
  return (
    <>
      <PageTitle title="Help : Users" />
      <HelpUsersCreate />

      <div className="help-card">
        <article className="help">
          <div className="help-title">Disable / Re-Enable a User</div>
          <div>To disable a user so they cannot access your organisation:</div>
          <div>
            1. In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-sliders-v" />
              Admin
            </div>
            then
            <div className="help-nav">
              <i className="fad fa-users" />
              Users
            </div>
          </div>
          <div>
            2. For the user you want to enabled or disable, click on <FontAwesomeLink icon="caret-square-left" href="#" m={0} /> and then the option you want
          </div>
        </article>
      </div>

      <div className="help-card">
        <article className="help">
          <div className="help-title">Changing a User</div>
          <div>To change a user’s display name, role and category permissions:</div>
          <div>
            1. In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-sliders-v" />
              Admin
            </div>
            then
            <div className="help-nav">
              <i className="fad fa-users" />
              Users
            </div>
          </div>
          <div>
            2. For the user you want to change, click <FontAwesomeLink icon="pen-square" href="#" m={0} />.
          </div>
          <div>3. Change the user's details.</div>
          <div className="help-note">
            <span>NB: </span> The email address cannot be changed after the user has been initially created.
          </div>
          <div>
            4. Click
            <div className="help-button-small">
              <BlockButton text="Save" type="button" mt={0} buttonSize="small" />
            </div>
          </div>
        </article>
      </div>
    </>
  );
};

export default HelpUsers;

import React from 'react';
import BlockButton from '../Common/BlockButton';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import { Link } from 'react-router-dom';

const HelpUsersCreate = ({ inline = false }) => {
  return (
    <div className={inline ? '' : 'help-card'}>
      <article className="help">
        <div className="help-title">Create a New User</div>
        <div>To add an admin / manager / user to your organisation:</div>
        <div>
          1. In left navigation menu, select
          <div className="help-nav">
            <i className="fad fa-sliders-v" />
            Admin
          </div>
          then
          <div className="help-nav">
            <i className="fad fa-users" />
            Users
          </div>
        </div>
        <div>
          2. Click on the <FontAwesomeLink icon="plus-square" text="New" textAlways href="#" m={0} />
          link under the page title.
        </div>
        <div>3. Enter the user's details.</div>
        <div className="help-note">
          <span>NB: </span> The email address entered here will be used for logging in, to set / reset their password and for email notifications.
        </div>
        <div>
          4. Under role select either Admin, Manager or User (See
          <Link to="/help/roles" target="_blank" className="mx-1 text-indigo-700">
            roles
          </Link>
          to determine which role you should choose){' '}
        </div>
        <div>5. If you have selected Manager or User a list will appear where you can select what categories this individual can access</div>
        <div>
          6. Click
          <div className="help-button-small">
            <BlockButton text="Save" type="button" mt={0} buttonSize="small" />
          </div>
        </div>
      </article>
    </div>
  );
};

export default HelpUsersCreate;

import { apiUrl } from '../config';
import axios from 'axios';
import { formattedDate } from '../Helpers/dateHelpers';

export const apiUserSettings = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/User/UserSettings',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiUserSettingsSave = async (emailMarketing, emailNotifications) => {
  await axios({
    method: 'post',
    url: 'v1/User/UserSettings',
    baseURL: apiUrl(),
    data: { emailMarketing, emailNotifications },
  });
};

export const apiUsers = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/Manager/Users',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiUser = async (id) => {
  const result = await axios({
    method: 'get',
    url: `v1/Admin/User/${id}`,
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiRoles = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/Admin/Roles',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiUserSave = async (id, displayName, email, role, level1Permissions, level2Permissions) => {
  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Admin/User/Save',
      baseURL: apiUrl(),
      data: { id, displayName, email, role, level1Permissions, level2Permissions },
    });

    if (result.status === 400) {
      return result.data;
    } else {
      return { isValid: true };
    }
  } catch (err) {
    return err.data;
  }
};

export const apiUserEnableDisable = async (id, enable) => {
  await axios({
    method: 'post',
    url: 'v1/Admin/User/EnableDisable',
    baseURL: apiUrl(),
    data: { id, enable },
  });
};

export const apiChangePassword = async (currentPassword, newPassword) => {
  const result = await axios({
    method: 'post',
    url: 'v1/User/PasswordChange',
    baseURL: apiUrl(),
    data: {
      currentPassword,
      newPassword,
    },
  });

  if (result.status === 400) {
    return result.data;
  } else {
    return { successful: true };
  }
};

export const apiSignUp = async (displayName, emailAddress, organisationName, licenseTypeId, level1Name, level2Name, bankHolidayCountry) => {
  var result = {
    successful: false,
    errors: [],
  };

  await axios({
    method: 'post',
    url: 'v1/SignUp',
    baseURL: apiUrl(),
    data: { displayName, emailAddress, organisationName, licenseTypeId, level1Name, level2Name, bankHolidayCountry },
  })
    .then(function () {
      result.successful = true;
    })
    .catch(function (err) {
      result.successful = false;

      if (err.status === 400) {
        if (typeof err.data === 'string') {
          result.errors = [err.data];
        } else {
          result.errors = err.data.errors;
        }
      }
    });

  return result;
};

export const apiHolidays = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/User/Holidays',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiHoliday = async (id) => {
  const result = await apiHolidays();

  return result.find((x) => x.id === id);
};

export const apiHolidayDelete = async (id) => {
  await axios({
    method: 'delete',
    url: 'v1/User/Holiday',
    baseURL: apiUrl(),
    data: { id },
  });
};

export const apiHolidaySave = async (id, forUserId, fromDate, toDate) => {
  //If no To Date then assume single day and from/to date should be the same
  if (!toDate) {
    toDate = fromDate;
  }
  fromDate = formattedDate(fromDate);
  toDate = formattedDate(toDate);

  await axios({
    method: 'post',
    url: 'v1/User/Holiday',
    baseURL: apiUrl(),
    data: { id, forUserId, fromDate, toDate },
  });
};

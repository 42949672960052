import React from 'react';
import FontAwesomeButton from './FontAwesomeButton';
import DatePicker from 'react-date-picker';

const InputDate2 = ({ formik, label, field, outerClassName = '', show = true, hintText }) => {
  const { value, onChange } = formik.getFieldProps(field);

  const onFieldChange = (value) => {
    onChange({ target: { name: field, value: value } });
  };

  return (
    <div className={outerClassName}>
      {show && (
        <>
          <label className="input-label" htmlFor={field}>
            {label}
          </label>
          <div className="flex items-center">
            <div className="input-text w-full">
              <DatePicker
                value={value}
                onChange={onFieldChange}
                clearIcon={null}
                calendarIcon={null}
                locale="en-GB"
                className="pb-4"
                minDate={new Date('2020/01/01')}
                maxDate={new Date('2100/12/31')}
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
              />
            </div>
            <FontAwesomeButton icon="eraser" type="button" onClick={() => onChange({ target: { name: field, value: undefined } })} />
          </div>
          {hintText && <div className="text-xs text-indigo-700 text-right -mt-3 mb-3">{hintText}</div>}
          {formik.errors[field] ? <div className="input-error">{formik.errors[field]}</div> : null}
        </>
      )}
    </div>
  );
};

export default InputDate2;

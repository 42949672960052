import { apiUrl } from '../config';
import axios from 'axios';

export const apiDeadlineMaxReached = async (deadlineLimit) => {
  if (deadlineLimit) {
    const result = await axios({
      method: 'get',
      url: 'v1/Manager/Deadline/Count',
      baseURL: apiUrl(),
    });

    return result.data >= parseInt(deadlineLimit);
  }

  return false;
};

export const apiLicenseSubscribe = async (licenseTypeId, paymentMethodId) => {
  try {
    const result = await axios({
      method: 'post',
      url: `v1/Admin/License`,
      data: { licenseTypeId, paymentMethodId },
      baseURL: apiUrl(),
    });

    return result.data;
  } catch {
    return null;
  }
};

export const apiLicenseCheck = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/Admin/License/Check',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiLicense3DSecureOK = async () => {
  await axios({
    method: 'post',
    url: `v1/Admin/License/3DSecureOk`,
    baseURL: apiUrl(),
  });
};

export const apiLicenseCancel = async () => {
  await axios({
    method: 'post',
    url: `v1/Admin/License/Cancel`,
    baseURL: apiUrl(),
  });
};

export const apiLicenseReactivate = async () => {
  await axios({
    method: 'post',
    url: `v1/Admin/License/Reactivate`,
    baseURL: apiUrl(),
  });
};

export const apiLicenseChange = async (newLicenseTypeId) => {
  await axios({
    method: 'post',
    url: `v1/Admin/License/Change`,
    data: { newLicenseTypeId },
    baseURL: apiUrl(),
  });
};

export const apiLicenseSetupIntent = async () => {
  var result = await axios({
    method: 'post',
    url: `v1/Admin/License/SetupIntent`,
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiLicenseChangePaymentMethod = async (paymentMethodId) => {
  var result = await axios({
    method: 'post',
    url: 'v1/Admin/License/ChangePaymentMethod',
    data: { paymentMethodId },
    baseURL: apiUrl(),
  });

  return result.data;
};

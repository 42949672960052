import React, { useState, useContext, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { apiLicenseSubscribe, apiLicense3DSecureOK } from '../../APIs/license';
import BlockButton from '../Common/BlockButton';
import { UserContext } from '../../Contexts/UserContext';
import { stripeSubscriptionStatusOk, stripeSubscriptionStatusRequiresAction } from '../../Helpers/constants';

const options = {
  style: {
    base: {
      color: '#4c51bf',
      iconColor: '#4c51bf',
      fontFamily: 'Inter',
      fontSmoothing: 'antialiased',
      fontSize: '20px',
      '::placeholder': {
        color: '#7f9cf5',
      },
    },
    invalid: {
      color: '#e53e3e',
      iconColor: '#e53e3e',
    },
  },
};

const CardForm = ({ licenseType, onOk, fullWidth = false }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(true);
  const [paying, setPaying] = useState(false);
  const [error, setError] = useState(null);
  const [isComplete, setIsComplete] = useState(false);
  const [isFree, setIsFree] = useState(null);
  const { LoadUserSettings } = useContext(UserContext);

  useEffect(() => {
    setIsFree(licenseType.price === '0');
    setLoading(false);
  }, [licenseType]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isFree) {
      setError(null);
      setPaying(true);
      await apiLicenseSubscribe(licenseType.id, null);
      await LoadUserSettings();
      setPaying(false);
      onOk();
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setError(null);
    setPaying(true);
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    var result = await apiLicenseSubscribe(licenseType.id, payload.paymentMethod.id);
    setPaying(false);

    if (result.status === stripeSubscriptionStatusOk) {
      await LoadUserSettings();
      setPaying(false);
      onOk();
    }

    if (result.status === stripeSubscriptionStatusRequiresAction) {
      const result3d = await stripe.confirmCardPayment(result.clientSecret, { payment_method: result.paymentMethodId });

      if (result3d.error) {
        setError(result3d.error.message);
        return;
      } else {
        setPaying(true);
        await apiLicense3DSecureOK();
        await LoadUserSettings();
        setPaying(false);
        onOk();
        return;
      }
    }

    if (result.status === 'OK') {
      onOk();
    } else {
      setError(result.errorMessage);
      setPaying(false);
    }
  };

  const handleChange = (e) => {
    if (e.error && e.error.code) {
      setError(e.error.message);
      setIsComplete(false);
    } else {
      setIsComplete(e.complete);
      setError(null);
    }
  };

  const widthClasses = fullWidth ? 'w-full' : 'max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8';
  const widthClassesInner = fullWidth ? 'w-full' : 'max-w-md mx-auto md:max-w-5xl';

  if (loading) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit} className={widthClasses}>
      <div className={widthClassesInner}>
        {!isFree && (
          <>
            <div className="card mb-4 p-3 bg-white">
              <span className="font-extrabold">Note: </span> Stripe is used as our 3rd party provider for online payments and card details are not stored in our system.
            </div>
            <div className="border-indigo-700 border-2 p-2 rounded-md bg-white mb-4">
              <CardElement options={options} onChange={handleChange} />
            </div>
          </>
        )}
        {error && <div className="input-error">{error}</div>}
        <div className="-mt-2">
          {licenseType.price === '0' ? <BlockButton text="Purchase Now" icon="hurricane" iconSpin={paying} disabled={paying} /> : <BlockButton text="Pay Now" icon="hurricane" iconSpin={paying} disabled={paying || !stripe || !isComplete} />}
        </div>
      </div>
    </form>
  );
};

export default CardForm;

import React from 'react';

const Error404 = () => {
  return (
    <div className="flex absolute w-screen top-0 left-0 h-screen inline-block align-middle">
      <div className="max-w-sm m-auto">404</div>
    </div>
  );
};

export default Error404;

import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { apiSignUp } from '../../APIs/user';
import { countries, licenseTypes } from '../../Helpers/constants';
import withRedirect from '../../hoc/withRedirect';
import BlockButton from '../Common/BlockButton';
import CardErrors from '../Common/CardErrors';
import { InputCheckbox, InputSelect, InputText } from '../Common/Inputs';
import PageTitle from '../Common/PageTitle';
import logo from '../../assets/logo-dark.svg';
import { Helmet } from 'react-helmet-async';
import { gaTrackingIdConversion } from '../../config';

const initialValues = {
  email: '',
  name: '',
  organisationName: '',
  level1Name: '',
  level2Name: '',
  level: '1',
  termsAndConditions: false,
  isoCountryCode: undefined,
};

const SignUp = ({ setRedirect, setRedirectUrl, match }) => {
  const [loading, setLoading] = useState(true);
  const [running, setRunning] = useState(false);
  const [serverErrors, setServerError] = useState(undefined);
  const [data, setData] = useState(initialValues);
  const [hideLevel, setHideLevel] = useState(false);

  useEffect(() => {
    if (match && match.params && match.params.level) {
      setData((p) => ({ ...p, level: match.params.level }));
      setHideLevel(true);
    }

    setLoading(false);
  }, [match]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      organisationName: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      termsAndConditions: Yup.boolean().required('Terms and Conditions of Use must be accepted').oneOf([true], 'Terms and Conditions of Use must be accepted'),
    }),
    onSubmit: async (values) => {
      setRunning(true);
      var result = await apiSignUp(values.name, values.email, values.organisationName, values.level, values.level1Name, values.level2Name, values.isoCountryCode);
      setRunning(false);

      if (result.successful) {
        setRedirectUrl(`/signedup/${values.level}`);
        setRedirect(true);
      } else {
        setServerError(result.errors);
      }
    },
  });

  return (
    <>
      <Helmet>
        <script>
          {`
            gtag('event', 'page_view', {
              'page_title': 'Deadline Tracker',
              'page_path': '/signup/${match?.params?.level || ''}',
              'send_to': '${gaTrackingIdConversion()}'
              });
          `}
        </script>
      </Helmet>

      {!loading && (
        <div className="flex absolute w-screen top-0 left-0 h-screen align-middle bg-white">
          <div className="m-auto w-full sm:w-4/5 md:w-11/12 lg:w-3/4 max-w-6xl p-2 bg-white">
            <div className="md:flex gap-x-8">
              <div className="flex-1">
                <div className="flex-1 flex justify-start">
                  <img src={logo} title="Deadline Tracker" alt="Deadline Tracker" className="mb-6 max-h-20" />
                </div>
                <div className="flex-1 text-3xl sm:text-4xl tracking-tight font-semibold leading-10">
                  <div>Deadline Tracking</div>
                  <div>Software for Busy Bees...</div>
                </div>
                <div className="mt-6 text-3xl font-semibold text-indigo-800 flex items-center gap-x-4">
                  Get Your Free Trial <span className="md:hidden fad fa-lg fa-hand-point-down"></span>
                  <span className="hidden md:inline fad fa-lg fa-hand-point-right -mt-1"></span>
                </div>
                <div className="mt-6 text-2xl font-semibold">Deadline Tracker will help you:</div>
                <div className="text-base sm:text-lg mb-6">
                  <ul className="list-disc list-inside">
                    <li>Quickly see when you can actually work</li>
                    <li>Get notifications configured to your workflow</li>
                    <li>Easily manage all your deadlines in one place</li>
                  </ul>
                </div>
              </div>
              <div className="flex-1 bg-indigo-800 p-4 rounded-lg">
                <PageTitle title="Sign Up" />
                <div className="card">
                  <form onSubmit={formik.handleSubmit}>
                    <InputText formik={formik} label="Name" field="name" />
                    <InputText formik={formik} label="Email Address" field="email" />
                    <InputText formik={formik} label="Organisation Name" field="organisationName" />
                    <InputSelect formik={formik} label="Level" field="level" data={licenseTypes} valueField="id" optional={false} outerClassName={hideLevel ? 'hidden' : ''} />
                    {formik.values.level !== '1' && (
                      <>
                        <InputText formik={formik} label="Level 1 Name" field="level1Name" hintText="For example 'Client', leave blank if not required" />
                        <InputText formik={formik} label="Level 2 Name" field="level2Name" hintText="For example 'Task', leave blank if not required" />
                      </>
                    )}
                    <InputSelect formik={formik} label="Country" field="isoCountryCode" data={countries} valueField="id" optional hintText="For Bank Holidays" optionalLabel="None" />

                    <InputCheckbox formik={formik} field="termsAndConditions">
                      I accept the
                      <a target="_blank" href="/terms-and-conditions" className="underline ml-1">
                        Terms and Conditions of Use
                      </a>
                    </InputCheckbox>

                    <CardErrors errors={serverErrors} />
                    <BlockButton text="Give Us a Try" iconSpin={running} icon={running ? 'hurricane' : null} disabled={running} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default withRouter(withRedirect(SignUp));

import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiChangePassword } from '../../APIs/user';
import withRedirect from '../../hoc/withRedirect';
import BlockButton from '../Common/BlockButton';
import CardErrors from '../Common/CardErrors';
import PageTitle from '../Common/PageTitle';
import InputText from '../Common/InputText';

const ChangePassword = ({ setRedirect, setRedirectUrl }) => {
  const [done, setDone] = useState(false);
  const [serverErrors, setServerErrors] = useState(undefined);

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required('Required'),
      newPassword: Yup.string()
        .required('Required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase (A-Z) character')
        .matches(/[a-z]/, 'Password must contain at least one lowercase (a-z) character')
        .matches(/[0-9]/, 'Password must contain at least one numeruc (0-9) character')
        .matches(/[^A-Za-z0-9]/, 'Password must contain at least one special (symbol) character'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: async (values) => {
      setServerErrors(undefined);

      const result = await apiChangePassword(values.currentPassword, values.newPassword);

      if (result.successful) {
        setDone(true);
      } else {
        setServerErrors(result);
      }
    },
  });

  const backToDashboard = () => {
    setRedirectUrl('/');
    setRedirect(true);
  };

  return (
    <>
      <PageTitle title="Change Password" />

      <div className="card">
        {done ? (
          <form method="get">
            <p className="px-2 py-4">Your password has been changed sucessfully.</p>

            <BlockButton text="Back to Dashboard" type="button" onClick={() => backToDashboard()} />
          </form>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <InputText formik={formik} label="Current Password" field="currentPassword" type="password" icon="unlock" autoComplete="current-password" />
            <InputText formik={formik} label="New Password" field="newPassword" type="password" icon="unlock-alt" autoComplete="new-password" />
            <InputText formik={formik} label="Confirm Password" field="confirmPassword" type="password" icon="check-double" autoComplete="new-password" />

            <CardErrors errors={serverErrors} />
            <BlockButton text="Change Password" icon="unlock-alt" />
          </form>
        )}
      </div>
    </>
  );
};

export default withRedirect(ChangePassword);

import React from 'react';

const InputMonthDays = ({ formik, label, monthsField, daysField, disabled = false, outerClassName = '' }) => {
  return (
    <div className={outerClassName}>
      <label className="input-label" htmlFor={monthsField}>
        {label}
      </label>
      <div className="flex items-start space-x-2">
        <div className="w-full">
          <div className="input-text">
            <input className="focus:outline-none text-right" type="number" placeholder="" min={0} max={500} {...formik.getFieldProps(monthsField)} disabled={disabled} />
            <label className="input-label" htmlFor={monthsField}>
              Months
            </label>
          </div>
          {formik.touched[monthsField] && formik.errors[monthsField] ? <div className="input-error">{formik.errors[monthsField]}</div> : null}
        </div>
        <div className="w-full">
          <div className="input-text">
            <input className="focus:outline-none text-right" type="number" placeholder="" min={0} max={500} {...formik.getFieldProps(daysField)} disabled={disabled} />
            <label className="input-label" htmlFor={daysField}>
              Days
            </label>
          </div>
          {formik.touched[daysField] && formik.errors[daysField] ? <div className="input-error">{formik.errors[daysField]}</div> : null}
        </div>
      </div>
    </div>
  );
};

export default InputMonthDays;

import { apiUrl } from '../config';
import axios from 'axios';

export const apiLogin = async (email, password) => {
  const result = await axios({
    method: 'post',
    url: 'v1/Login',
    baseURL: apiUrl(),
    data: {
      EmailAddress: email,
      Password: password,
    },
  });

  return result.data;
};

export const apiMFAVerify = async (accessToken) => {
  const result = await axios({
    method: 'post',
    url: 'v1/User/MFAVerify',
    baseURL: apiUrl(),
    data: {
      accessToken,
    },
  });

  return result.data;
};

export const apiMFAResend = async () => {
  const result = await axios({
    method: 'post',
    url: 'v1/User/MFAResend',
    baseURL: apiUrl(),
    data: {},
  });

  return result.data;
};

export const apiLogout = async () => {
  try {
    await axios({
      method: 'post',
      url: 'v1/Logout',
      baseURL: apiUrl(),
    });
  } catch {}
};

export const apiForgotPassword = async (emailAddress) => {
  await axios({
    method: 'post',
    url: 'v1/PasswordForgot',
    baseURL: apiUrl(),
    data: {
      emailAddress,
    },
  });
};

export const apiResetPassword = async (emailAddress, newPassword, emailMarketing, emailNotifications) => {
  const params = new URLSearchParams(window.location.search);
  const resetToken = params.get('code');

  var result = {
    successful: false,
    errors: [],
  };

  await axios({
    method: 'post',
    url: 'v1/PasswordReset',
    baseURL: apiUrl(),
    data: {
      emailAddress,
      resetToken,
      newPassword,
      emailMarketing,
      emailNotifications,
    },
  })
    .then(function () {
      result.successful = true;
    })
    .catch(function (err) {
      result.successful = false;

      if (err.status === 404) {
        result.errors = undefined;
      }

      if (err.status === 400) {
        result.errors = err.data;
      }
    });

  return result;
};

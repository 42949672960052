import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import AdjustDate from '../Components/adjustDate/AdjustDate';
import LevelEdit from '../Components/admin/levels/LevelEdit';
import LevelList from '../Components/admin/levels/LevelList';
import OrganisationSettingsEdit from '../Components/admin/organisation-settings/OrganisationSettingsEdit';
import OrganisationEdit from '../Components/admin/organisations/OrganisationEdit';
import OrganisationList from '../Components/admin/organisations/OrganisationList';
import UserEdit from '../Components/admin/users/UserEdit';
import UserList from '../Components/admin/users/UserList';
import { ProtectedRoute } from '../Components/Auth/ProtectedRoute';
import CalendarView2 from '../Components/Dashboard/CalendarView2';
import Completed2 from '../Components/Dashboard/Completed2';
import Dashboard2 from '../Components/Dashboard/Dashboard2';
import DeadlineEdit from '../Components/deadline/DeadlineEdit';
import HelpDeadlines from '../Components/help/HelpDeadlines';
import HelpLevels from '../Components/help/HelpLevels';
import HelpRoles from '../Components/help/HelpRoles';
import HelpSettings from '../Components/help/HelpSettings';
import HelpSubscriptionChange from '../Components/help/HelpSubscriptionChange';
import HelpSubscriptionChoose from '../Components/help/HelpSubscriptionChoose';
import HelpSupport from '../Components/help/HelpSupport';
import HelpUsers from '../Components/help/HelpUsers';
import Navbar from '../Components/Nav2/Navbar';
import SubscriptionChange from '../Components/subscription/SubscriptionChange';
import SubscriptionChangeCard from '../Components/subscription/SubscriptionChangeCard';
import SubscriptionChoose from '../Components/subscription/SubscriptionChoose';
import SubscriptionDetails from '../Components/subscription/SubscriptionDetails';
import SubscriptionExpired from '../Components/subscription/SubscriptionExpired';
import SubscriptionIssue from '../Components/subscription/SubscriptionIssue';
import SubscriptionUpgradeRequired from '../Components/subscription/SubscriptionUpgradeRequired';
import TemplateEdit from '../Components/templates/TemplateEdit';
import TemplateList from '../Components/templates/TemplateList';
import ChangePassword from '../Components/user/ChangePassword';
import HolidayEdit from '../Components/user/HolidayEdit';
import HolidayList from '../Components/user/HolidayList';
import Settings from '../Components/user/Settings';
import { UserProvider } from '../Contexts/UserContext';

const DashboardLayout = () => {
  return (
    <>
      <UserProvider>
        <Navbar>
          <div className="px-4 py-3 mb-10">
            <Switch>
              <ProtectedRoute path="/" exact component={Dashboard2} />
              <ProtectedRoute path="/completed" exact component={Completed2} />
              <ProtectedRoute path="/calendar" exact component={CalendarView2} />
              <ProtectedRoute path="/help/categories" exact component={HelpLevels} admin />
              <ProtectedRoute path="/help/deadlines" exact component={HelpDeadlines} />
              <ProtectedRoute path="/help/roles" exact component={HelpRoles} />
              <ProtectedRoute path="/help/settings" exact component={HelpSettings} />
              <ProtectedRoute path="/help/subscription-change" exact component={HelpSubscriptionChange} admin />
              <ProtectedRoute path="/help/subscription-choose" exact component={HelpSubscriptionChoose} admin />
              <ProtectedRoute path="/help/support" exact component={HelpSupport} />
              <ProtectedRoute path="/help/users" exact component={HelpUsers} admin />
              <ProtectedRoute path="/settings" exact component={Settings} />
              <ProtectedRoute path="/change-password" exact component={ChangePassword} />
              <ProtectedRoute path="/holidays" exact component={HolidayList} />
              <ProtectedRoute path="/holiday/Add" exact component={HolidayEdit} />
              <ProtectedRoute path="/holiday/:id" exact component={HolidayEdit} />
              <ProtectedRoute path="/adjust-date" exact component={AdjustDate} allowOnExpired />
              <ProtectedRoute path="/deadline/multi/:level" exact component={DeadlineEdit} manager />
              <ProtectedRoute path="/deadline/Add" exact component={DeadlineEdit} manager />
              <ProtectedRoute path="/deadline/fromtemplate/:templateId" exact component={DeadlineEdit} manager />
              <ProtectedRoute path="/deadline/multifromtemplate/:level/:templateId" exact component={DeadlineEdit} manager />
              <ProtectedRoute path="/deadline/:id" exact component={DeadlineEdit} manager />
              <ProtectedRoute path="/admin/organisations" exact component={OrganisationList} globalAdmin />
              <ProtectedRoute path="/admin/organisation/Add" exact component={OrganisationEdit} globalAdmin />
              <ProtectedRoute path="/admin/organisation/:id" exact component={OrganisationEdit} globalAdmin />
              <ProtectedRoute path="/admin/levels/:level" exact component={LevelList} admin />
              <ProtectedRoute path="/admin/level/:level/Add" exact component={LevelEdit} admin />
              <ProtectedRoute path="/admin/level/:level/:id" exact component={LevelEdit} admin />
              <ProtectedRoute path="/admin/organisation-settings" exact component={OrganisationSettingsEdit} admin />
              <ProtectedRoute path="/admin/users" exact component={UserList} admin />
              <ProtectedRoute path="/admin/user/Add" exact component={UserEdit} admin />
              <ProtectedRoute path="/admin/user/:id" exact component={UserEdit} admin />
              <ProtectedRoute path="/subscription/upgrade-required/:reason" exact component={SubscriptionUpgradeRequired} />
              <ProtectedRoute path="/subscription/change" admin exact component={SubscriptionChange} />
              <ProtectedRoute path="/subscription/payment-method" admin exact component={SubscriptionChangeCard} />
              <ProtectedRoute path="/subscription/choose" admin exact component={SubscriptionChoose} allowOnExpired />
              <ProtectedRoute path="/subscription/issue" exact component={SubscriptionIssue} allowOnExpired />
              <ProtectedRoute path="/subscription/expired" exact component={SubscriptionExpired} allowOnExpired />
              <ProtectedRoute path="/subscription" admin exact component={SubscriptionDetails} allowOnExpired />
              <ProtectedRoute path="/template/Add" exact component={TemplateEdit} manager />
              <ProtectedRoute path="/template/:id" exact component={TemplateEdit} manager />
              <ProtectedRoute path="/templates" exact component={TemplateList} manager />
              <Redirect to="/errors/404" />
            </Switch>
          </div>
        </Navbar>
      </UserProvider>
    </>
  );
};

export default DashboardLayout;

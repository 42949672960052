import React, { useContext } from 'react';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import PageTitle from '../Common/PageTitle';
import { UserContext } from '../../Contexts/UserContext';
import BlockButton from '../Common/BlockButton';
import HelpDeadlinesCreate from './HelpDeadlinesCreate';

const HelpDeadlines = ({ forceShowAll = false }) => {
  const { userIsManager } = useContext(UserContext);

  const showManager = userIsManager || forceShowAll;

  return (
    <>
      <PageTitle title="Help : Deadlines" />
      <HelpDeadlinesCreate forceShowAll={forceShowAll} />
      {showManager && (
        <div className="help-card">
          <article className="help">
            <div className="help-title">Editing a Deadline</div>
            <div>
              1. In left navigation menu, select
              <div className="help-nav">
                <i className="fad fa-home" />
                Deadlines
              </div>
              to go to you Deadline dashboard.
            </div>
            <div>
              2. Select
              <FontAwesomeLink icon="edit" text="Edit Deadline" textAlways href="#" m={1} />
              from the
              <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
              menu.
            </div>
            <div>3. Change the deadline as necessary.</div>
            <div>
              4. Click the
              <div className="help-button">
                <BlockButton text="Calculate Preview" icon="calculator" type="button" buttonType="secondary" mt={0} buttonSize="small" />
              </div>
              button to preview the next 3 instances (if applicable) when this deadline will become open, send a warning and the deadline date.
            </div>
            <div className="help-note">
              <span>NB: </span>Deadlines are synced to the working week so if you have selected the end of the month it will move to the last working day if that happens to fall on a weekend.
            </div>
            <div>
              5. If these are not correct you can change the dates or repeat type and click
              <div className="help-button">
                <BlockButton text="Calculate Preview" icon="calculator" type="button" buttonType="secondary" mt={0} buttonSize="small" />
              </div>
              again to see an updated version.
            </div>
            <div>
              6. By clicking the
              <div className="help-button-small">
                <BlockButton text="Save" icon="save" type="button" mt={0} buttonSize="small" />
              </div>
              button, this will save the deadline and take you back to the deadline dashboard.
            </div>
            <div>
              7. By clicking the
              <div className="help-button-small">
                <BlockButton text="Cancel" icon="arrow-square-left" type="button" buttonType="secondary" mt={0} buttonSize="small" />
              </div>
              button this will cancel this deadline and take you back to the deadline dashboard.
            </div>
          </article>
        </div>
      )}
      <div className="help-card">
        <article className="help">
          <div className="help-title">Working with Deadlines</div>
          <div>
            In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-home" />
              Deadlines
            </div>
            to go to you Deadline dashboard.
          </div>
          <div>Here you are able to work with your deadlines, like completing, snoozing or extending them.</div>
        </article>
      </div>
      {showManager && (
        <div className="help-card">
          <article className="help">
            <div className="help-title">Assign a User to a Deadline</div>
            <div>
              - Select
              <FontAwesomeLink icon="user-circle" text="Assign Deadline" textAlways href="#" m={1} />
              from the
              <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
              menu.
            </div>
            <div>- Select the user you want to assign to the deadline.</div>
            <div>
              - Click
              <FontAwesomeLink icon="user-circle" text="Assign Deadline" textAlways href="#" m={1} />
            </div>
          </article>
        </div>
      )}
      <div className="help-card">
        <article className="help">
          <div className="help-title">Completing a Deadline</div>
          <div>To mark a deadline as complete and remove it, you can either:</div>
          <div>
            - Click <FontAwesomeLink icon="clipboard-check" text="Done" href="#" m={0} /> on the right-hand side of the deadline.
          </div>
          <div>or</div>
          <div>
            - Select
            <FontAwesomeLink icon="clipboard-check" text="Mark As Done" textAlways href="#" m={0} />
            from the
            <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
            menu.
          </div>
        </article>
      </div>
      <div className="help-card">
        <article className="help">
          <div className="help-title">Start a Deadline Early</div>
          <div>To start a deadline early and mark it as In Progress:</div>
          <div>
            - Select
            <FontAwesomeLink icon="hourglass-start" text="Start Now" textAlways href="#" m={0} />
            from the
            <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
            menu.
          </div>
        </article>
      </div>
      <div className="help-card">
        <article className="help">
          <div className="help-title">Snooze a Deadline</div>
          <div>
            If a deadline can't be started yet you had the ability to snooze it until it can be started, maybe you're waiting on paperwork from a client. This does not affact the deadline date, just when it'll become active again. To do this:
          </div>
          <div>
            - Select
            <FontAwesomeLink icon="alarm-snooze" text="Snooze Deadline" textAlways href="#" m={0} />
            from the
            <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
            menu.
          </div>
          <div>- Pick the date to snooze it until.</div>
          <div>
            - Click <FontAwesomeLink icon="alarm-snooze" text="Snooze Deadline" textAlways href="#" m={0} />
          </div>
        </article>
      </div>
      <div className="help-card">
        <article className="help">
          <div className="help-title">Unsnooze a Deadline</div>
          <div>To remove a snooze froma deadline:</div>
          <div>- Select the Snoozed filter option at the top of the dashboard.</div>
          <div>
            - Select
            <FontAwesomeLink icon="clock" text="Unsnooze Deadline" textAlways href="#" m={0} />
            from the
            <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
            menu.
          </div>
        </article>
      </div>
      <div className="help-card">
        <article className="help">
          <div className="help-title">Extend a Deadline</div>
          <div>Deadlines can be extended beyond their orginal deadline date. This will change the status of an overdue deadline back to a warned status. To do this:</div>
          <div>
            - Select
            <FontAwesomeLink icon="alarm-plus" text="Extend Deadline" textAlways href="#" m={0} />
            from the
            <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
            menu.
          </div>
          <div>- Pick the date to extend the deadline until.</div>
          <div>
            - Click <FontAwesomeLink icon="alarm-plus" text="Extend Deadline" textAlways href="#" m={0} />
          </div>
        </article>
      </div>
      <div className="help-card">
        <article className="help">
          <div className="help-title">Delete a Deadline Instance</div>
          <div>To delete an instance of a deadline:</div>
          <div>
            - Select
            <FontAwesomeLink icon="trash-alt" text="Delete This Instance Only" textAlways href="#" m={0} />
            from the
            <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
            menu.
          </div>
          <div className="help-note help-note-bottom">
            <span className="">NB:</span> Ensure that this is the correct occurrence of the deadline and not just the earliest one.
          </div>
        </article>
      </div>
      {showManager && (
        <div className="help-card">
          <article className="help">
            <div className="help-title">Delete All Instances of a Deadline</div>
            <div>
              - Select
              <FontAwesomeLink icon="dumpster-fire" text="Delete All Instances" textAlways href="#" m={1} />
              from the
              <FontAwesomeLink icon="bars" text="Options" href="#" m={0} />
              menu.
            </div>
            <div className="help-note help-note-bottom">
              <span>NB: </span> This will delete all occurrences and will have to create a new deadline if you want this back.
            </div>
          </article>
        </div>
      )}
      <div className="help-card">
        <article className="help">
          <div className="help-title">Undo a Recent Action</div>
          <div>If you delete or completed an instance of a deadline by accident, you can Undo it. To do this:</div>
          <div>- Select the Recent filter option at the top of the dashboard.</div>
          <div>
            - Click <FontAwesomeLink icon="undo-alt" text="Undo" href="#" m={0} /> on the right-hand side of the deadline.{' '}
          </div>
        </article>
      </div>
    </>
  );
};

export default HelpDeadlines;

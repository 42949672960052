import React, { useState, useEffect, useContext } from 'react';
import Modal from '../../Common/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputText, InputTextArea } from '../../Common/Inputs';
import BlockButton from '../../Common/BlockButton';
import { apiLevelSave } from '../../../APIs/levels';
import { UserContext } from '../../../Contexts/UserContext';

const LevelAddModal = ({ level, show, hideCallback }) => {
  const [title, setTitle] = useState('New');
  const { level1Name, level2Name } = useContext(UserContext);

  useEffect(() => {
    if (level === 1) {
      setTitle(level1Name);
    } else {
      setTitle(level2Name);
    }
  }, [level, level1Name, level2Name]);

  const formik = useFormik({
    initialValues: {
      name: '',
      notes: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      var id = await apiLevelSave(null, level, values.name, null, values.notes, true);

      formik.values.name = '';
      formik.values.notes = '';

      hideCallback(id);
    },
  });

  return (
    <Modal title={`New ${title}`} show={show} hide={hideCallback} modalStyle="w-full max-w-md" showClose={false}>
      <div className="m-3">
        <form onSubmit={formik.handleSubmit}>
          <InputText formik={formik} label="Name" field="name" />
          <InputTextArea formik={formik} label="Notes" field="notes" />
          <div className="inline-flex w-full space-x-1">
            <BlockButton text="Save" icon="save" type="submit" groupPosition="left" />
            <BlockButton text="Cancel" icon="arrow-square-left" buttonType="secondary" groupPosition="right" type="button" onClick={() => hideCallback()} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default LevelAddModal;

import React, { useState, useContext, useEffect } from 'react';
import logo from '../../assets/logo.svg';
import Sidebar from './Sidebar';
import { UserContext } from '../../Contexts/UserContext';
import { hasDateOverride, todayFormatted, showDebugFooter } from '../../config';
import NavFooterItem from './NavFooterItem';
import { FormatDate } from '../../Helpers/dateHelpers';
import { withRouter, Link } from 'react-router-dom';

const Navbar = ({ children, history }) => {
  const [showNav, setShowNav] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const { settingsLoaded, UserIsAuthenticated, organisationName, userEmail, userRole, userIsAdmin, licenseExpiryDate, licenseFreeTrial, HasLicenseExpired, HasLicenseExpiryDate, HasLicensePaymentIssue } = useContext(UserContext);

  useEffect(() => {
    const isAuthed = UserIsAuthenticated();

    if (!isAuthed) {
      history.push(`/login?returnTo=${encodeURIComponent(window.location.pathname)}`);
    }

    return () => setIsSubscribed(false);
  }, [isSubscribed, UserIsAuthenticated, history]);

  const handleCloseMenu = () => {
    setShowNav(false);
  };

  const StatusBar = () => {
    if (licenseFreeTrial) {
      if (HasLicenseExpired()) {
        return <NavFooterItem icon="file-certificate" text={`Free Trial - Expired`} error link={userIsAdmin ? '/subscription' : null} />;
      } else {
        return <NavFooterItem icon="file-certificate" text={`Free Trial - Expires ${FormatDate(licenseExpiryDate)}`} link={userIsAdmin ? '/subscription' : null} />;
      }
    }

    if (HasLicensePaymentIssue()) {
      if (HasLicenseExpired()) {
        return <NavFooterItem icon="star-exclamation" text="Subscription (Expired) Issue" error link="/subscription/issue" />;
      } else {
        return <NavFooterItem icon="star-exclamation" text="Subscription Issue" error link="/subscription/issue" />;
      }
    }

    if (HasLicenseExpiryDate()) {
      if (HasLicenseExpired()) {
        return <NavFooterItem icon="file-certificate" text="Subscription Expired" error />;
      } else {
        return <NavFooterItem icon="file-certificate" text={`Subscription Expires ${FormatDate(licenseExpiryDate)}`} />;
      }
    }

    return null;
  };

  if (!settingsLoaded) {
    return null;
  }

  return (
    <>
      <div className="h-screen flex overflow-hidden bg-gray-200 pb-10">
        {/* Off-canvas menu for mobile */}
        {showNav && (
          <div className="lg:hidden">
            <div className="fixed inset-0 flex z-40" style={{ zIndex: 999 }}>
              <div className="fixed inset-0">
                <div className="absolute inset-0 bg-cool-gray-600 opacity-75"></div>
              </div>
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-primary">
                <div className="absolute top-0 right-0 -mr-14 p-1">
                  <button className="flex items-center justify-center h-12 w-12 rounded-full focus:outline-none focus:bg-cool-gray-600" aria-label="Close sidebar" onClick={handleCloseMenu}>
                    <svg className="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                <Sidebar closeMenu={handleCloseMenu} />
              </div>
              <div className="flex-shrink-0 w-14"></div>
            </div>
          </div>
        )}

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-grow bg-primary overflow-y-auto">
              <div className="flex-1 flex flex-col overflow-y-auto">
                <Sidebar closeMenu={handleCloseMenu} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 overflow-auto focus:outline-none" tabIndex={0}>
          <div className="relative w-full z-10 flex-shrink-0 flex h-16 bg-primary border-b border-gray-200 lg:border-none lg:hidden bg-gradient-to-tr from-indigo-800 to-indigo-700">
            <button className="px-4 border-r border-cool-gray-200 text-cool-gray-400 focus:outline-none focus:bg-cool-gray-100 focus:text-cool-gray-600 lg:hidden" aria-label="Open sidebar" onClick={() => setShowNav(true)}>
              <svg className="h-6 w-6 transition ease-in-out duration-150" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
              </svg>
            </button>
            <Link to="/">
              <img src={logo} title="Deadline Tracker" alt="Deadline Tracker" className="h-12 m-2" />
            </Link>
          </div>
          {children}
        </div>
      </div>
      <div className="bg-gray-200 fixed bottom-0 w-full right-0 z-50">
        <div className="text-gray-100 bg-primary text-xxs sm:text-xs text-right space-x-2 py-1">
          <NavFooterItem text="Support" icon="ticket-alt" className="uppercase hidden sm:inline-block" link="/help/support" />
          {settingsLoaded && (
            <>
              {showDebugFooter() && (
                <>
                  <NavFooterItem icon="calendar-star" text={hasDateOverride() ? todayFormatted() : 'Today'} link="/adjust-date" />
                  <NavFooterItem icon="at" text={userEmail} />
                  <NavFooterItem icon="key" text={userRole} />
                  <NavFooterItem icon="expand">
                    <span className="inline-block sm:hidden">XS</span>
                    <span className="hidden sm:inline-block md:hidden">SM</span>
                    <span className="hidden md:inline-block lg:hidden">MD</span>
                    <span className="hidden lg:inline-block xl:hidden">LG</span>
                    <span className="hidden xl:inline-block">XL</span>
                  </NavFooterItem>
                  <NavFooterItem icon="sitemap" text={organisationName} />
                </>
              )}
              <StatusBar />
            </>
          )}
          <span className="p-2 inline-block whitespace-no-wrap">&copy; {new Date().getFullYear()} Deadline Tracker Limited. All rights reserved.</span>
        </div>
      </div>
    </>
  );
};

export default withRouter(Navbar);

import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { apiLevel, apiLevelSave } from '../../../APIs/levels';
import { withRouter } from 'react-router-dom';
import InputText from '../../Common/InputText';
import InputTextArea from '../../Common/InputTextArea';
import LoadingCard from '../../Common/LoadingCard';
import BlockButton from '../../Common/BlockButton';
import InputCheckbox from '../../Common/InputCheckbox';
import { UserContext } from '../../../Contexts/UserContext';

const LevelEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [levelName, setLevelName] = useState('');
  const [data, setData] = useState({ isEnabled: true });
  const { level1Name, level2Name } = useContext(UserContext);
  const level = match.params.level;
  const id = match.params.id || null;
  const addMode = !match.params.id;

  const GetData = useCallback(async () => {
    setLoading(true);

    const result = await apiLevel(match.params.id);

    setData(result);
    setLoading(false);
  }, [match]);

  useEffect(() => {
    setLevelName(level === '1' ? level1Name : level2Name);

    if (addMode) {
      setData({ isEnabled: true });
      setLoading(false);
    } else {
      GetData();
    }
  }, [level, GetData, addMode, level1Name, level2Name]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || '',
      notes: data.notes || '',
      groupName: data.groupName || '',
      isEnabled: data.isEnabled,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      await apiLevelSave(id, level, values.name, values.groupName, values.notes, values.isEnabled);

      if (values.SubmitType === 'Save') {
        history.push(`/admin/levels/${level}`);
      } else {
        formik.setFieldValue('name', '', false);
        formik.setFieldValue('notes', '', false);
      }
    },
  });

  const cancel = () => {
    history.push(`/admin/levels/${level}`);
  };

  const handleSubmitButton = (type) => () => {
    formik.setFieldValue('SubmitType', type, false);
    formik.handleSubmit();
  };

  return (
    <LoadingCard loading={loading} title={`${addMode ? 'New' : 'Edit'} ${levelName}`} help="/help/categories">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex">
          <InputText formik={formik} label="Name" field="name" outerClassName="flex-grow" />
          <InputCheckbox formik={formik} label="Enabled" field="isEnabled" outerClassName="flex-grow-0" />
        </div>
        <InputText formik={formik} label="Group Name" field="groupName" />
        <InputTextArea formik={formik} label="Notes" field="notes" />

        <div className="inline-flex w-full space-x-1">
          <BlockButton text="Save" icon="save" type="button" groupPosition="left" onClick={handleSubmitButton('Save')} />
          {addMode && <BlockButton text="Save + New" icon="save" type="button" groupPosition="center" onClick={handleSubmitButton('SaveAndNew')} wrapperClass="hidden sm:block" />}
          <BlockButton text="Cancel" icon="arrow-square-left" buttonType="secondary" groupPosition="right" type="button" onClick={() => cancel()} />
        </div>
      </form>
    </LoadingCard>
  );
};

export default withRouter(LevelEdit);

import React, { useState } from 'react';

const colourClasses = 'text-indigo-50 bg-primary';

const SidebarGroup = ({ title, icon, children, show = true, initialIsOpen = false }) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);

  const toggleOpen = () => {
    setIsOpen((p) => !p);
  };

  if (!show) {
    return null;
  }

  return (
    <div>
      <button className={`mt-1 group w-full flex items-center pr-2 py-2 text-sm leading-5 font-medium rounded-md ${colourClasses} focus:outline-none transition ease-in-out duration-150`} onClick={toggleOpen}>
        {/* Expanded: "text-gray-400 rotate-90", Collapsed: "text-gray-300" */}
        <svg className={`mr-2 h-5 w-5 transform transition-colors ease-in-out duration-150 ${isOpen ? 'rotate-90' : ''}`} viewBox="0 0 20 20">
          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
        </svg>
        {icon && <i className={`fad fa-${icon} fa-fw fa-lg mr-3`}></i>}
        {title}
      </button>
      {isOpen && <div className="mt-1 space-y-1 ml-3">{children}</div>}
    </div>
  );
};

export default SidebarGroup;

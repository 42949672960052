import React, { useContext } from 'react';
import PageTitle from '../Common/PageTitle';
import { UserContext } from '../../Contexts/UserContext';

const HelpSupport = () => {
  const { userIsAdmin } = useContext(UserContext);

  return (
    <>
      <PageTitle title="Help : Support" />
      {userIsAdmin ? (
        <>
          <div className="help-card">
            <article className="help">
              <div className="help-title">Troubles</div>
              If you are having troubles with Deadline Tracker then please feel free to email us at{' '}
              <a className="text-primary underline" href="mailto:support@deadlinetracker.co.uk">
                support@deadlinetracker.co.uk
              </a>{' '}
              and we'll do our best to help you.
            </article>
          </div>
          <div className="help-card">
            <article className="help">
              <div className="help-title">Onboarding</div>
              <div>
                If you would like help setting up Deadline Tracker or just someone to enter your deadlines then we are here to help. Just email us at
                <a className="text-primary underline" href="mailto:support@deadlinetracker.co.uk">
                  {' '}
                  support@deadlinetracker.co.uk
                </a>
                .
              </div>
            </article>
          </div>
        </>
      ) : (
        <div className="help-card">
          <article className="help">
            <div className="help-title">Troubles</div>
            Please contact your Deadline Tracker admin for help.
          </article>
          <article className="help mt-4">
            If you are unsure who this is or you need more help then please feel free to email us at{' '}
            <a className="text-primary underline" href="mailto:support@deadlinetracker.co.uk">
              support@deadlinetracker.co.uk
            </a>{' '}
            and we'll do our best to help you.
          </article>
        </div>
      )}
    </>
  );
};

export default HelpSupport;

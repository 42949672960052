import { useFormik } from 'formik';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { apiLogin } from '../../APIs/auth';
import withRedirect from '../../hoc/withRedirect';
import BlockButton from '../Common/BlockButton';
import CardErrors from '../Common/CardErrors';
import InputText from '../Common/InputText';
import PageTitle from '../Common/PageTitle';

const Login = ({ setRedirect, setRedirectUrl }) => {
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    localStorage.removeItem('DT_Filter');
    localStorage.removeItem('DT_FilterOptions');
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      var result = await apiLogin(values.email, values.password);
      setLoading(false);

      if (result.successful) {
        var params = qs.parse(location.search, { ignoreQueryPrefix: true });
        setRedirectUrl(params.returnTo || '/');

        setRedirect(true);
        return;
      }

      if (result.expired) {
        setError('License Expired, please contact your System Admin.');
        return;
      }

      setError('Login Failed!');
    },
  });

  return (
    <div className="flex absolute w-screen top-0 left-0 h-screen align-middle">
      <div className="m-auto w-full sm:w-3/4 md:w-1/2">
        <PageTitle title="Login" withLogoCard />
        <div className="card">
          <form onSubmit={formik.handleSubmit}>
            <InputText formik={formik} label="Email Address" field="email" icon="at" />
            <InputText formik={formik} label="Password" field="password" icon="unlock-alt" type="password" />

            <div className="text-right text-primary">
              <Link to="/forgot-password">Forgot Password?</Link>
            </div>

            <CardErrors errors={error} />
            <BlockButton text="Log In" iconSpin={loading} icon={loading ? 'hurricane' : null} disabled={loading} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRedirect(Login);

import React from 'react';

const InputTextArea = ({ formik, label, field, rows = 5, outerClassName = '', disabled = false }) => {
  return (
    <div className={outerClassName}>
      <label className="input-label" htmlFor={field}>
        {label}
      </label>
      <div className="input-textarea">
        <textarea className="focus:outline-none" id={field} {...formik.getFieldProps(field)} rows={rows} disabled={disabled} />
      </div>
      {formik.touched[field] && formik.errors[field] ? <div className="input-error">{formik.errors[field]}</div> : null}
    </div>
  );
};

export default InputTextArea;

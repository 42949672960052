import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { apiOrganisationMine, apiOrganisationMineSave } from '../../../APIs/organisation';
import { UserContext } from '../../../Contexts/UserContext';
import { countries } from '../../../Helpers/constants';
import BlockButton from '../../Common/BlockButton';
import CardErrors from '../../Common/CardErrors';
import { InputCheckbox, InputSelect, InputText } from '../../Common/Inputs';
import LoadingCard from '../../Common/LoadingCard';

const OrganisationSettingsEdit = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [serverErrors, setServerErrors] = useState(null);
  const { LoadUserSettings, licenseLevelsEnabled } = useContext(UserContext);

  const GetData = useCallback(async () => {
    setLoading(true);

    const result = await apiOrganisationMine();

    setData(result);

    setLoading(false);
  }, []);

  useEffect(() => {
    GetData();
  }, [GetData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data.name || '',
      level1Name: data.level1Name || '',
      level2Name: data.level2Name || '',
      bankHolidayCountry: data.bankHolidayCountry || '',
      mfaEnabled: data.mfaEnabled || false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setServerErrors(null);

      var result = await apiOrganisationMineSave(values.name, values.level1Name, values.level2Name, values.bankHolidayCountry, values.mfaEnabled);

      if (result.isValid) {
        await LoadUserSettings();
        history.push('/');
      } else {
        setServerErrors(result.errors);
      }
    },
  });

  const cancel = () => {
    history.push('/');
  };

  return (
    <LoadingCard loading={loading} title="My Organisation">
      <form onSubmit={formik.handleSubmit}>
        <InputText formik={formik} label="Organisation Name" field="name" />
        {licenseLevelsEnabled && (
          <>
            <InputText formik={formik} label="Level 1 Name" field="level1Name" hintText="Please use singular, e.g. Client rather than Clients" />
            <InputText formik={formik} label="Level 2 Name" field="level2Name" hintText="Please use singular, e.g. Task rather than Tasks" />
          </>
        )}
        <InputSelect formik={formik} label="Country" field="bankHolidayCountry" data={countries} valueField="id" optional hintText="For Bank Holidays" optionalLabel="None" />
        <InputCheckbox formik={formik} label="Multi-Factor Authentication Enabled" field="mfaEnabled" />

        <CardErrors errors={serverErrors} />
        <div className="inline-flex w-full space-x-1">
          <BlockButton text="Save" icon="save" type="submit" groupPosition="left" />
          <BlockButton text="Cancel" icon="arrow-square-left" buttonType="secondary" groupPosition="right" type="button" onClick={() => cancel()} />
        </div>
      </form>
    </LoadingCard>
  );
};

export default withRouter(OrganisationSettingsEdit);

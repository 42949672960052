import React from 'react';
import logo from '../../assets/logo.svg';
import FontAwesomeLink from './FontAwesomeLink';

const PageTitle = ({ title, withLogoCard = false, help, children }) => {
  return (
    <div className="w-full mb-4 card text-center text-indigo-700 font-extrabold uppercase tracking-tight relative">
      {help && (
        <div className="absolute top-0 right-0 mt-2 mr-2">
          <FontAwesomeLink icon="life-ring" href={help} m={0} inNewTab text="Help" />
        </div>
      )}
      {withLogoCard && (
        <div className="bg-primary p-2 -m-2 mb-2 rounded-t-lg ">
          <img src={logo} title="Deadline Tracker" alt="Deadline Tracker" className="h-12 inline-block" />
        </div>
      )}
      <div className="text-2xl">{title}</div>
      {children}
    </div>
  );
};

export default PageTitle;

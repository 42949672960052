import { useFormik } from 'formik';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { apiLevels } from '../../../APIs/levels';
import { apiRoles, apiUser, apiUserSave } from '../../../APIs/user';
import { UserContext } from '../../../Contexts/UserContext';
import { BuildPermissionsString, PermissionsToObject } from '../../../Helpers/permissionsHelper';
import BlockButton from '../../Common/BlockButton';
import CardErrors from '../../Common/CardErrors';
import InputCheckbox from '../../Common/InputCheckbox';
import InputSelect from '../../Common/InputSelect';
import InputText from '../../Common/InputText';
import LoadingCard from '../../Common/LoadingCard';

const UserEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    addMode: true,
    displayName: '',
    email: '',
    role: '',
    level1All: false,
    level2All: false,
  });
  const [roles, setRoles] = useState([]);
  const [level1s, setLevel1s] = useState([]);
  const [level2s, setLevel2s] = useState([]);
  const [serverErrors, setServerErrors] = useState(null);
  const { level1Name, level2Name, hasLevel1, hasLevel2 } = useContext(UserContext);
  const addMode = !match.params.id;

  const GetRolesAndLevels = useCallback(async () => {
    const rolesResult = await apiRoles();
    setRoles(rolesResult);

    const level1Results = await apiLevels(1);
    setLevel1s(level1Results);

    const level2Results = await apiLevels(2);
    setLevel2s(level2Results);
  }, []);

  const GetData = useCallback(async () => {
    setLoading(true);

    const result = await apiUser(match.params.id);

    //Work out Default Action
    var defaultAction = false;
    if (!hasLevel1 && !hasLevel2 && result.role !== 'Admin' && result.role !== 'GlobalAdmin') {
      defaultAction = result.level1Permissions === '*';
    }

    setData({ ...result, defaultAction, addMode: false, password: '', ...PermissionsToObject(result.level1Permissions, result.level2Permissions) });

    setLoading(false);
  }, [match, hasLevel1, hasLevel2]);

  useEffect(() => {
    GetRolesAndLevels();

    if (addMode) {
      setLoading(false);
    } else {
      GetData();
    }
  }, [GetData, GetRolesAndLevels, addMode]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: Yup.object({
      displayName: Yup.string().required('Required'),
      email: Yup.string()
        .email('Invalid')
        .when('addMode', {
          is: true,
          then: Yup.string().required('Required'),
        }),
      role: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setServerErrors(null);

      var level1Permissions = '';
      var level2Permissions = '';
      if (hasLevel1 || hasLevel2 || values.role === 'Admin' || values.role === 'GlobalAdmin') {
        level1Permissions = hasLevel1 ? BuildPermissionsString(values.role, values, '1') : '*';
        level2Permissions = hasLevel2 ? BuildPermissionsString(values.role, values, '2') : '*';
      } else {
        level1Permissions = values.defaultAction ? '*' : '';
        level2Permissions = values.defaultAction ? '*' : '';
      }

      const id = match.params.id || null;
      var result = await apiUserSave(id, values.displayName, values.email, values.role, level1Permissions, level2Permissions);

      if (result.isValid) {
        history.push('/admin/users');
      } else {
        setServerErrors(result);
      }
    },
  });

  const cancel = () => {
    history.push('/admin/users');
  };

  return (
    <LoadingCard loading={loading} title={`${addMode ? 'New' : 'Edit'} User`} help="/help/users">
      {data && (
        <form onSubmit={formik.handleSubmit}>
          <InputText formik={formik} label="Display Name" field="displayName" />
          <InputText formik={formik} label="Email Address" field="email" disabled={!addMode} />
          <InputSelect formik={formik} label="Role" field="role" data={roles} />

          {(formik.values.role === 'User' || formik.values.role === 'Manager') && (
            <>
              {hasLevel1 || hasLevel2 ? (
                <div className="md:flex">
                  {hasLevel1 && (
                    <div className="w-full md:w-1/2 md:pr-1">
                      <div className="input-label border-b-2 text-center">{level1Name} Access</div>
                      <InputCheckbox formik={formik} label="All" field="level1All" />
                      {!formik.values.level1All && (
                        <>
                          <InputCheckbox formik={formik} label="Unassigned" field="level1_Unassigned" />
                          {level1s.map((item) => (
                            <InputCheckbox key={item.id} formik={formik} label={item.name} field={`level1_${item.id}`} />
                          ))}
                        </>
                      )}
                    </div>
                  )}
                  {hasLevel2 && (
                    <div className="w-full md:w-1/2 md:pl-1">
                      <div className="input-label border-b-2 text-center">{level2Name} Access</div>
                      <InputCheckbox formik={formik} label="All" field="level2All" />
                      {!formik.values.level2All && (
                        <>
                          <InputCheckbox formik={formik} label="Unassigned" field="level2_Unassigned" />
                          {level2s.map((item) => (
                            <InputCheckbox key={item.id} formik={formik} label={item.name} field={`level2_${item.id}`} />
                          ))}
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <InputCheckbox formik={formik} label="Access all Deadlines (checking this off with mean the user can only access assigned deadlines)" field="defaultAction" />
              )}
            </>
          )}

          <CardErrors errors={serverErrors} />
          <div className="inline-flex w-full space-x-1">
            <BlockButton text="Save" icon="save" type="submit" groupPosition="left" />
            <BlockButton text="Cancel" icon="arrow-square-left" buttonType="secondary" groupPosition="right" type="button" onClick={() => cancel()} />
          </div>
        </form>
      )}
    </LoadingCard>
  );
};

export default withRouter(UserEdit);

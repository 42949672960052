import React from 'react';

const Error500 = () => {
  return (
    <div className="flex absolute w-screen top-0 left-0 h-screen inline-block align-middle">
      <div className="max-w-sm m-auto">500</div>
    </div>
  );
};

export default Error500;

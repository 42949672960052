import React from 'react';
import { Link } from 'react-router-dom';

const colourClasses = 'text-indigo-50 bg-primary';

const SidebarItem = ({ to, title, icon, show = true, closeMenu }) => {
  if (!show) {
    return null;
  }

  const handleOnClick = () => {
    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <Link to={to} onClick={handleOnClick} className={`group w-full flex items-center pl-7 pr-2 py-2 text-sm leading-5 font-medium rounded-md ${colourClasses} focus:outline-none transition ease-in-out duration-150`}>
      <i className={`fad fa-${icon} fa-fw fa-lg mr-3`}></i>
      {title}
    </Link>
  );
};

export default SidebarItem;

import { useFormik } from 'formik';
import moment from 'moment';
import 'moment/locale/en-gb';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { apiDeadlinePreview } from '../../APIs/deadline';
import { apiTemplate, apiTemplateSaveFormik } from '../../APIs/templates';
import { DATE_FORMAT } from '../../config';
import { UserContext } from '../../Contexts/UserContext';
import { daysOfWeek, defaultNewTemplate, workingDayRule } from '../../Helpers/constants';
import { getReferenceData } from '../../Helpers/referenceDataHelpers';
import { templateValidation } from '../../Helpers/validationHelpers';
import BlockButton from '../Common/BlockButton';
import CardErrors from '../Common/CardErrors';
import { InputCheckbox, InputMonthDays, InputSelect, InputText, InputTextArea } from '../Common/Inputs';
import LoadingCard from '../Common/LoadingCard';
import PageTitle from '../Common/PageTitle';

const TemplateEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ ...defaultNewTemplate });
  const addMode = !match.params.id;
  const [serverErrors, setServerErrors] = useState(null);
  const [showRepeatStep, setShowRepeatStep] = useState(false);
  const [showDeadlineType, setShowDeadlineType] = useState(false);
  const [showDay, setShowDay] = useState(false);
  const [showDayOfWeek, setShowDayOfWeek] = useState(false);
  const [previewDates, setPreviewDates] = useState(undefined);
  const [referenceData, setReferenceData] = useState(undefined);
  const { settingsLoaded, hasLevel1, hasLevel2, userAllowedLevel1Unassigned, userAllowedLevel2Unassigned, level1Name, level2Name, licenseTypeId } = useContext(UserContext);
  const oneLevel1 = match.params.level !== '1' || licenseTypeId <= 2;
  const oneLevel2 = match.params.level !== '2' || licenseTypeId <= 2;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: templateValidation,
    onSubmit: async (values) => {
      setServerErrors(null);

      var result = await apiTemplateSaveFormik(values);

      if (result.isValid || typeof result === 'string') {
        history.push('/templates');
      } else {
        setServerErrors(result.errors);
      }
    },
  });

  const calculatePreviewDates = useCallback(async () => {
    const fromDate = new Date();
    const toDate = new Date(fromDate.getFullYear() + 1, fromDate.getMonth(), fromDate.getDay());

    const result = await apiDeadlinePreview(
      fromDate,
      toDate,
      formik.values.repeatTypeCode,
      formik.values.repeatStep,
      formik.values.deadlineTypeCode,
      formik.values.day,
      formik.values.dayOfWeek,
      formik.values.openMonths,
      formik.values.openDays,
      formik.values.warnMonths,
      formik.values.warnDays,
      formik.values.adjustDays,
      formik.values.workingDayRule
    );

    setPreviewDates(result);
  }, [formik.values]);

  const GetData = useCallback(async () => {
    setLoading(true);

    if (!settingsLoaded) {
      return;
    }

    if (!match.params.id) {
      const refData = await getReferenceData(hasLevel1, hasLevel2, userAllowedLevel1Unassigned && oneLevel1, userAllowedLevel2Unassigned && oneLevel2, undefined, undefined, licenseTypeId > 2);
      setReferenceData(refData);

      setShowDeadlineType(false);
      setShowRepeatStep(false);
      setShowDay(false);
      setShowDayOfWeek(false);

      setData({ ...defaultNewTemplate });
    } else {
      const result = await apiTemplate(match.params.id);

      if (result) {
        result.fromDate = new Date(result.fromDate);
        if (result.toDate) {
          result.toDate = new Date(result.toDate);
        }
      }

      const refData = await getReferenceData(hasLevel1, hasLevel2, userAllowedLevel1Unassigned, userAllowedLevel2Unassigned, result.level1Id, result.level2Id, licenseTypeId > 2);
      setReferenceData(refData);

      setData({ ...result, addMode: false, id: match.params.id });

      var foundRT = refData.repeatTypes.find((x) => x.code === result.repeatTypeCode);
      setShowDeadlineType(foundRT && foundRT.showDeadlineType);
      setShowRepeatStep(foundRT && foundRT.askAmount);

      var foundDT = refData.deadlineTypes.find((x) => x.code === result.deadlineTypeCode);
      setShowDay(foundDT && foundDT.showAs === 'Day');
      setShowDayOfWeek((foundRT && foundRT.unitType === 'W') || (foundDT && foundDT.showAs === 'DayOfWeek'));
    }
    setLoading(false);
  }, [match, settingsLoaded, hasLevel1, hasLevel2, userAllowedLevel1Unassigned, userAllowedLevel2Unassigned, oneLevel1, oneLevel2, licenseTypeId]);

  useEffect(() => {
    GetData();
  }, [GetData]);

  useEffect(() => {
    setPreviewDates(undefined);
  }, [
    data,
    formik.values.fromDate,
    formik.values.toDate,
    formik.values.deadlineTypeCode,
    formik.values.repeatTypeCode,
    formik.values.repeatStep,
    formik.values.dayOfWeek,
    formik.values.day,
    formik.values.openMonths,
    formik.values.openDays,
    formik.values.warnMonths,
    formik.values.warnDays,
    formik.values.adjustDays,
    formik.values.workingDayRule,
  ]);

  const updateFields = (values) => {
    var foundRT = referenceData.repeatTypes.find((x) => x.code === values.repeatTypeCode);

    // DEADLINE TYPE
    if (foundRT && foundRT.showDeadlineType) {
      setShowDeadlineType(true);
      if (!formik.values.deadlineTypeCode) {
        const firstDT = referenceData.deadlineTypes[0].code;
        formik.handleChange({ target: { name: 'deadlineTypeCode', value: firstDT } });
        values.deadlineTypeCode = firstDT;
        formik.handleChange({ target: { name: 'adjustDays', value: 0 } });
        values.adjustDays = 0;
      }
    } else {
      setShowDeadlineType(false);
      formik.handleChange({ target: { name: 'deadlineTypeCode', value: undefined } });
      values.deadlineTypeCode = undefined;
      values.adjustDays = 0;
    }

    // REPEAT STEP
    if (foundRT && foundRT.askAmount) {
      setShowRepeatStep(true);
      if (!formik.values.repeatStep) {
        formik.handleChange({ target: { name: 'repeatStep', value: 1 } });
      }
    } else {
      setShowRepeatStep(false);
      formik.handleChange({ target: { name: 'repeatStep', value: 1 } });
    }

    var foundDT = referenceData.deadlineTypes.find((x) => x.code === values.deadlineTypeCode);

    // DAY
    if (foundDT && foundDT.showAs === 'Day') {
      setShowDay(true);
      if (!formik.values.day) {
        formik.handleChange({ target: { name: 'day', value: 1 } });
      }
    } else {
      setShowDay(false);
      formik.handleChange({ target: { name: 'day', value: undefined } });
    }

    // DAY OF WEEK
    if ((foundRT && foundRT.unitType === 'W') || (foundDT && foundDT.showAs === 'DayOfWeek')) {
      setShowDayOfWeek(true);
      if (!formik.values.dayOfWeek) {
        formik.handleChange({ target: { name: 'dayOfWeek', value: 1 } });
      }
    } else {
      setShowDayOfWeek(false);
      formik.handleChange({ target: { name: 'dayOfWeek', value: undefined } });
    }
  };

  const updateRepeatType = (e) => {
    formik.values.repeatTypeCode = e.target.value;
    updateFields(formik.values);
  };

  const updateDeadlineType = (e) => {
    formik.values.deadlineTypeCode = e.target.value;
    updateFields(formik.values);
  };

  const cancel = () => {
    history.push('/templates');
  };

  const handleSubmitButton = (type) => () => {
    formik.setFieldValue('SubmitType', type, false);
    formik.handleSubmit();
  };

  return (
    <>
      <LoadingCard loading={loading} title={`${addMode ? 'New' : 'Edit'} Template`}>
        {data && referenceData && (
          <>
            <form>
              <InputText formik={formik} label="Template Name" field="name" outerClassName="px-1" />

              {(hasLevel1 || hasLevel2) && (
                <div className="sm:flex">
                  <>
                    {hasLevel1 && (
                      <>
                        {oneLevel1 ? (
                          <InputSelect formik={formik} label={level1Name} field="level1Id" outerClassName="w-full md:w-1/2 px-1 sm:w-1/2" data={referenceData.level1s} optional={false} />
                        ) : (
                          <div className="w-full md:w-1/2 mb-4">
                            <div className="input-label pl-1">{level1Name}</div>
                            <div className="max-h-32 md:max-h-56 overflow-auto border-b border-primary">
                              {referenceData.level1s.map((item) => (
                                <InputCheckbox key={item.id} formik={formik} label={item.name} field={`level1_${item.id}`} />
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {hasLevel2 && (
                      <>
                        {oneLevel2 ? (
                          <InputSelect formik={formik} label={level2Name} field="level2Id" outerClassName="w-full md:w-1/2 px-1 sm:w-1/2" data={referenceData.level2s} optional={false} />
                        ) : (
                          <div className="w-full md:w-1/2 mb-4">
                            <div className="input-label text-left pl-1">{level2Name}</div>
                            <div className="max-h-32 md:max-h-56 overflow-auto border-b border-primary">
                              {referenceData.level2s.map((item) => (
                                <InputCheckbox key={item.id} formik={formik} label={item.name} field={`level2_${item.id}`} />
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                </div>
              )}
              <div className="flex flex-wrap">
                <InputSelect formik={formik} label="Repeats" field="repeatTypeCode" outerClassName="w-2/3 md:w-3/4 lg:w-2/6 px-1" data={referenceData.repeatTypes} optional={false} valueField="code" onChangeExtra={(e) => updateRepeatType(e)} />

                {showRepeatStep && <InputText formik={formik} label="Every" field="repeatStep" outerClassName="w-1/3 md:w-1/4 lg:w-1/6 px-1" type="number" min={1} max={500} rightJustify />}
              </div>

              <div className="flex flex-wrap">
                {showDeadlineType && (
                  <InputSelect
                    formik={formik}
                    label="Deadline Type"
                    field="deadlineTypeCode"
                    outerClassName="w-2/3 md:w-1/2 lg:w-2/6 px-1"
                    data={referenceData.deadlineTypes}
                    optional={false}
                    valueField="code"
                    onChangeExtra={(e) => updateDeadlineType(e)}
                  />
                )}

                {showDay && <InputText formik={formik} label="Day" field="day" outerClassName="w-1/3 md:w-1/4 lg:w-1/6 px-1" type="number" min={1} max={31} rightJustify />}

                {showDayOfWeek && <InputSelect formik={formik} label="Day Of Week" field="dayOfWeek" outerClassName="w-1/3 md:w-1/4 lg:w-1/6 px-1" data={daysOfWeek} optional={false} />}

                {(showDeadlineType || showDayOfWeek) && <InputText formik={formik} label="Adjust Days (+/-)" field="adjustDays" outerClassName="w-1/2 md:w-1/4 lg:w-1/6 px-1" type="number" min={-999} max={999} rightJustify />}
              </div>

              <div className="flex flex-wrap">
                <InputMonthDays formik={formik} label="Open Period" monthsField="openMonths" daysField="openDays" outerClassName="w-full md:w-1/2 px-1 xl:w-1/4 lg:w-1/4" />
                <InputMonthDays formik={formik} label="Warn Period" monthsField="warnMonths" daysField="warnDays" outerClassName="w-full md:w-1/2 px-1 xl:w-1/4 lg:w-1/4" />
              </div>

              <div className="md:flex md:flex-wrap lg:block">
                <InputSelect formik={formik} label="Working Day Rule" field="workingDayRule" outerClassName="w-full md:w-1/2 px-1" data={workingDayRule} optional={false} />
              </div>

              <InputTextArea formik={formik} label="Notes" field="notes" rows={3} outerClassName="px-1" />

              <CardErrors errors={serverErrors} />
              <div className="inline-flex w-full space-x-1">
                <BlockButton text="Save" icon="save" type="button" groupPosition="left" onClick={handleSubmitButton('Save')} />
                <BlockButton text="Cancel" icon="arrow-square-left" buttonType="secondary" groupPosition="right" type="button" onClick={() => cancel()} />
              </div>
            </form>
          </>
        )}
      </LoadingCard>
      {!loading && (
        <div className="mt-4">
          <PageTitle title="Deadline Preview">
            {previewDates ? (
              <div className="preview-panel">
                {previewDates.length === 0 && (
                  <div className="preview-panel-item">
                    <div>None</div>
                  </div>
                )}
                {previewDates.length > 0 && (
                  <>
                    <div>
                      <ul className="mt-3 flex flex-col gap-4 sm:flex-row sm:justify-evenly sm:flex-wrap mb-2">
                        <li className="col-span-1 flex shadow-sm rounded-md">
                          <div className="flex-shrink-0 flex items-center justify-center w-12 bg-indigo-400 text-white text-sm font-medium rounded-l-md">1st</div>
                          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                            <div className="flex-1 px-4 py-2 text-sm truncate">
                              <div className="text-green-700 text-sm font-medium">Open: {moment(previewDates[0].openDate).format(DATE_FORMAT)}</div>
                              <div className="text-orange-500 text-sm font-medium">Warn: {moment(previewDates[0].warnDate).format(DATE_FORMAT)}</div>
                              <div className="text-red-700 font-semibold">Deadline: {moment(previewDates[0].deadlineDate).format(DATE_FORMAT)}</div>
                            </div>
                          </div>
                        </li>
                        {previewDates.length > 1 && (
                          <li className="col-span-1 flex shadow-sm rounded-md">
                            <div className="flex-shrink-0 flex items-center justify-center w-12 bg-indigo-400 text-white text-sm font-medium rounded-l-md">2nd</div>
                            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                              <div className="flex-1 px-4 py-2 text-sm truncate">
                                <div className="text-green-700 text-sm font-medium">Open: {moment(previewDates[1].openDate).format(DATE_FORMAT)}</div>
                                <div className="text-orange-500 text-sm font-medium">Warn: {moment(previewDates[1].warnDate).format(DATE_FORMAT)}</div>
                                <div className="text-red-700 font-semibold">Deadline: {moment(previewDates[1].deadlineDate).format(DATE_FORMAT)}</div>
                              </div>
                            </div>
                          </li>
                        )}
                        {previewDates.length > 2 && (
                          <li className="col-span-1 flex shadow-sm rounded-md">
                            <div className="flex-shrink-0 flex items-center justify-center w-12 bg-indigo-400 text-white text-sm font-medium rounded-l-md">3rd</div>
                            <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                              <div className="flex-1 px-4 py-2 text-sm truncate">
                                <div className="text-green-700 text-sm font-medium">Open: {moment(previewDates[2].openDate).format(DATE_FORMAT)}</div>
                                <div className="text-orange-500 text-sm font-medium">Warn: {moment(previewDates[2].warnDate).format(DATE_FORMAT)}</div>
                                <div className="text-red-700 font-semibold">Deadline: {moment(previewDates[2].deadlineDate).format(DATE_FORMAT)}</div>
                              </div>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <BlockButton text="Calculate" icon="calculator" buttonType="secondary" type="button" onClick={calculatePreviewDates} />
            )}
          </PageTitle>
        </div>
      )}
    </>
  );
};

export default TemplateEdit;

import React from 'react';
import BlockButton from '../Common/BlockButton';
import PageTitle from '../Common/PageTitle';

const HelpSubscriptionChange = () => {
  return (
    <>
      <PageTitle title="Help : Change Subscription" />
      <div className="help-card">
        <article className="help">
          <div className="help-title">Change Subscription Details</div>
          <div>To upgrade, downgrade, change card details or cancel subscription:</div>
          <div>
            1. In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-sliders-v" />
              Admin
            </div>
            then
            <div className="help-nav">
              <i className="fad fa-file-certificate" />
              Subscription Details
            </div>
          </div>
          <div>2. This will take you to a page detailing what subscription package you are on, the monthly charge, when the next payment is due under expiry date and the features of that package.</div>
          <div>
            3. To change the subscription package, click
            <div className="help-button">
              <BlockButton text="Change Subscription" icon="exchange-alt" type="button" mt={0} buttonSize="small" />
            </div>
          </div>
          <div>
            4. Click the
            <div className="help-button-small">
              <BlockButton text="Switch" type="button" mt={0} buttonSize="small" />
            </div>
            button to change to that subscription package.
          </div>
          <div className="help-note">
            <span>NB: </span>If there are limits on those packages such as users or deadlines you will not be able to switch until you met or are under those limits.
          </div>
          <div className="help-note-2">
            <span>NB: </span>The new price will be taken at your next payment date though the features will be available immediately.
          </div>
          <div>
            5. To change the card details that the subscription uses, click
            <div className="help-button">
              <BlockButton text="Change Payment Method" icon="credit-card" type="button" buttonType="secondary" mt={0} buttonSize="small" />
            </div>
          </div>
          <div>6. Enter your new card details and these will be used on you next payment date.</div>
          <div>
            7. To cancel a subscription, click
            <div className="help-button">
              <BlockButton text="Cancel Subscription" icon="power-off" type="button" buttonType="secondary" mt={0} buttonSize="small" />
            </div>
          </div>
          <div>
            8. If you do want to cancel, click
            <div className="help-button-small">
              <BlockButton text="Yes, Cancel" type="button" buttonType="danger" mt={0} buttonSize="small" />
            </div>
          </div>
          <div className="help-note help-note-bottom">
            <span>NB: </span>You will be able to access your organisation up to your next payment date but not after this date.
          </div>
        </article>
      </div>
    </>
  );
};

export default HelpSubscriptionChange;

import React from 'react';
import FontAwesomeButton from './FontAwesomeButton';

const Modal = ({ title, subtitle, children, show, hide, modalStyle, showClose = true }) => {
  return (
    <>
      {show && (
        <div className="fixed pin z-1000 overflow-auto bg-modal flex animated fadeIn">
          <div className={`relative bg-white max-w-screen-sm m-auto flex-col flex animated fadeIn shadow-2xl rounded-lg ${modalStyle}`}>
            <div className="px-4 py-2">
              <div className="text-xl text-indigo-700 mr-20 uppercase tracking-tight font-extrabold whitespace-no-wrap">{title}</div>
              {subtitle && <div className="text-xs text-indigo-700 mr-20 uppercase tracking-tight font-extrabold whitespace-no-wrap">{subtitle}</div>}
            </div>
            <div className="h-1 w-full border-b border-indigo-300"></div>
            <div className="px-2">{children}</div>
            {showClose && (
              <>
                <div className="h-1 w-full border-b border-indigo-300"></div>
                <div className="p-2 text-indigo-600">
                  <FontAwesomeButton text="Close" textAlways icon="times" onClick={hide} fullWidth />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

import React, { useState } from 'react';
import FontAwesomeButton from './FontAwesomeButton';

const HelperCard = ({ children, show = true, more }) => {
  const [showMore, setShowMore] = useState(false);

  if (!show) {
    return null;
  }

  return (
    <div className="card mb-4 p-5 border-2 border-primary">
      {more ? (
        <>
          {showMore ? (
            <>
              {more}
              <FontAwesomeButton text="less..." textAlways icon="life-ring" onClick={() => setShowMore(false)} />
            </>
          ) : (
            <>
              {children}
              <div>
                <FontAwesomeButton text="more..." textAlways icon="life-ring" onClick={() => setShowMore(true)} />
              </div>
            </>
          )}
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default HelperCard;

import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import {
  apiDeadlineAssign,
  apiDeadlineDelete,
  apiDeadlineInstanceAssign,
  apiDeadlineInstanceComplete,
  apiDeadlineInstanceDelete,
  apiDeadlineInstanceExtend,
  apiDeadlineInstanceReactivate,
  apiDeadlineInstances,
  apiDeadlineInstanceSnooze,
  apiDeadlineInstancesRecent,
  apiDeadlineInstanceStartNow,
  apiDeadlineInstanceTag,
} from '../../APIs/dashboard';
import { UserContext } from '../../Contexts/UserContext';
import { GetDeadlineDate, GetStatus, SortDeadlines } from '../../Helpers/deadlineHelpers';
import BlockButton from '../Common/BlockButton';
import FontAwesomeButton from '../Common/FontAwesomeButton';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import HelperCard from '../Common/HelperCard';
import Modal from '../Common/Modal';
import PageTitle from '../Common/PageTitle';
import HelpDeadlinesCreate from '../help/HelpDeadlinesCreate';
import DashboardItem2 from './DashboardItem2';
import FilterPanel from './FilterPanel';
import InstanceOptions from './InstanceOptions';
import StatusFilter from './StatusFilter';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Dashboard2 = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataRecent, setDataRecent] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [showOptions, setShowOptions] = useState(false);
  const [optionsDeadline, setOptionsDeadline] = useState({});
  const [showNotes, setShowNotes] = useState(false);
  const [notesDeadline, setNotesDeadline] = useState({});
  const [tab, setTab] = useState('Active');
  const [filter, setFilter] = useState();
  const { userIsManager, level1Name, level2Name, canFilter } = useContext(UserContext);

  const [showFilter, setShowFilter] = useState(false);

  const GetData = useCallback(async () => {
    if (isSubscribed) {
      setLoading(true);
      const result = await apiDeadlineInstances();

      if (result && isSubscribed) {
        setData(SortDeadlines(result));
      }

      const resultRecent = await apiDeadlineInstancesRecent();

      if (resultRecent && isSubscribed) {
        setDataRecent(SortDeadlines(resultRecent));
      }
    }
    setLoading(false);
    setInitialLoad(false);
  }, [isSubscribed]);

  useEffect(() => {
    try {
      const filterJSON = localStorage.getItem('DT_Filter') || undefined;
      if (filterJSON) {
        setFilter(JSON.parse(filterJSON));
      }
    } catch {
      localStorage.removeItem('DT_Filter');
      localStorage.removeItem('DT_FilterOptions');
      setFilter();
    }

    GetData();

    return () => setIsSubscribed(false);
  }, [GetData]);

  const hasFilter = () => {
    if (!filter) {
      return false;
    }

    try {
      if (filter.level1s && filter.level1s.length > 0) {
        return true;
      }
      if (filter.level2s && filter.level2s.length > 0) {
        return true;
      }
      if (filter.bys && filter.bys.length > 0) {
        return true;
      }
    } catch {
      localStorage.removeItem('DT_Filter');
      localStorage.removeItem('DT_FilterOptions');
      setFilter();
    }

    return false;
  };

  const optionsClicked = (deadline) => {
    setOptionsDeadline(deadline);
    setShowOptions(true);
  };

  const notesClicked = (deadline) => {
    setNotesDeadline(deadline);
    setShowNotes(true);
  };

  const processAction = async (deadline, actionName, extra) => {
    switch (actionName) {
      case 'Done':
        await apiDeadlineInstanceComplete(deadline);
        break;
      case 'StartNow':
        await apiDeadlineInstanceStartNow(deadline);
        break;
      case 'DeleteOne':
        await apiDeadlineInstanceDelete(deadline);
        break;
      case 'Reactivate':
        await apiDeadlineInstanceReactivate(deadline);
        break;
      case 'DeleteAll':
        await apiDeadlineDelete(deadline);
        break;
      case 'Unsnooze':
        await apiDeadlineInstanceSnooze(deadline, null);
        break;
      case 'Snooze':
        await apiDeadlineInstanceSnooze(deadline, extra);
        break;
      case 'Extend':
        await apiDeadlineInstanceExtend(deadline, extra);
        break;
      case 'AssignAll':
        await apiDeadlineAssign(deadline, extra);
        break;
      case 'AssignOne':
        await apiDeadlineInstanceAssign(deadline, extra);
        break;
      case 'Tag':
        await apiDeadlineInstanceTag(deadline, extra);
        break;
      default:
        console.error(`Unknown Action: ${actionName}`);
        break;
    }
    GetData();
    setShowOptions(false);
  };

  return (
    <>
      <PageTitle title="Deadlines" help="/help/deadlines">
        {userIsManager && <FontAwesomeLink icon="plus-square" text="New" textAlways href="/deadline/Add" />}
        {canFilter && <FontAwesomeButton icon="filter" text={`Filter ${hasFilter() ? ' (ON)' : ''}`} textAlways onClick={() => setShowFilter(!showFilter)} />}
      </PageTitle>
      <HelperCard show={!loading && userIsManager && data.length + dataRecent.length === 0} more={<HelpDeadlinesCreate inline />}>
        Please click the <FontAwesomeLink icon="plus-square" text="New" textAlways href="/deadline/Add" m={0} /> link above to add your first Deadline.
      </HelperCard>
      <HelperCard show={!loading && userIsManager && data.length + dataRecent.length === 0}>
        Need help setting up Deadline Tracker or just someone to enter your deadlines then we are here to help. Just email us at
        <a className="text-primary underline ml-1" href="mailto:support@deadlinetracker.co.uk">
          support@deadlinetracker.co.uk
        </a>
        .
      </HelperCard>
      <HelperCard show={!loading && !userIsManager && data.length + dataRecent.length === 0}>Please speak to your Deadline Tracker Manager or Administrator as you have not been assigned any deadlines.</HelperCard>
      <Modal title={`${notesDeadline.deadlineName} - ${GetDeadlineDate(notesDeadline)}`} subtitle={notesDeadline.hasLevels && notesDeadline.levelName} show={showNotes} hide={() => setShowNotes(false)} modalStyle="w-full max-w-md">
        <div className="m-3">
          <label className="input-label mx-0">Notes</label>
          {notesDeadline && notesDeadline.notes}
        </div>
      </Modal>
      <FilterPanel showFilter={showFilter} setShowFilter={setShowFilter} data={data} dataRecent={dataRecent} setFilter={setFilter} filter={filter} />
      <InstanceOptions deadline={optionsDeadline} show={showOptions} hide={() => setShowOptions(false)} processAction={processAction} />
      <StatusFilter data={data} dataRecent={dataRecent} initialLoad={initialLoad} setTab={setTab} tab={tab} />
      <div className="card mt-4">
        <table className="w-full headless">
          <thead>
            <tr>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
              <th className="w-full"></th>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
            </tr>
          </thead>
          <tbody>
            {tab === 'Recent'
              ? dataRecent.map((item, row) => (
                  <DashboardItem2 row={row} key={item.deadlineInstanceId} item={item} tab="All" filter={filter} byMode="Completed" notesClicked={notesClicked} processAction={processAction} optionsClicked={optionsClicked} />
                ))
              : data.map((item, row) => <DashboardItem2 row={row} key={item.deadlineInstanceId} item={item} tab={tab} filter={filter} byMode="AssignedTo" notesClicked={notesClicked} processAction={processAction} optionsClicked={optionsClicked} />)}
          </tbody>
        </table>
        <BlockButton text="Reload" onClick={() => GetData()} icon="hurricane" iconSpin={loading} mt={2} />
        {data && data.length > 0 && (
          <ExcelFile element={<BlockButton text="Export" icon="file-spreadsheet" className="mt-2" />} filename="Deadline Tracker Export">
            <ExcelSheet data={data} name="All">
              <ExcelColumn label="Deadline Name" value="deadlineName" />
              <ExcelColumn label="Deadline Date" value={(col) => new Date(col.deadlineDate.substring(0, 10))} />
              <ExcelColumn label="Open Date" value={(col) => new Date(col.openDate.substring(0, 10))} />
              <ExcelColumn label="Warn Date" value={(col) => new Date(col.warnDate.substring(0, 10))} />
              <ExcelColumn label="Snooze Date" value={(col) => (col.snoozeDate ? new Date(col.snoozeDate.substring(0, 10)) : '')} />
              <ExcelColumn label="Assigned To" value="assignedToDisplayName" />
              <ExcelColumn label="Assigned To On Holiday" value={(col) => (col.assignedToOnHoliday ? 'Yes' : '')} />
              <ExcelColumn label="Notes" value="notes" />
              <ExcelColumn label={level1Name || ''} value="level1Name" />
              <ExcelColumn label={level2Name || ''} value="level2Name" />
            </ExcelSheet>
            <ExcelSheet data={data.filter((x) => GetStatus(x) === 'Overdue')} name="Overdue">
              <ExcelColumn label="Deadline Name" value="deadlineName" />
              <ExcelColumn label="Deadline Date" value={(col) => new Date(col.deadlineDate.substring(0, 10))} />
              <ExcelColumn label="Open Date" value={(col) => new Date(col.openDate.substring(0, 10))} />
              <ExcelColumn label="Warn Date" value={(col) => new Date(col.warnDate.substring(0, 10))} />
              <ExcelColumn label="Assigned To" value="assignedToDisplayName" />
              <ExcelColumn label="Assigned To On Holiday" value={(col) => (col.assignedToOnHoliday ? 'Yes' : '')} />
              <ExcelColumn label="Notes" value="notes" />
              <ExcelColumn label={level1Name || ''} value="level1Name" />
              <ExcelColumn label={level2Name || ''} value="level2Name" />
            </ExcelSheet>
            <ExcelSheet data={data.filter((x) => GetStatus(x) === 'Warned')} name="Warned">
              <ExcelColumn label="Deadline Name" value="deadlineName" />
              <ExcelColumn label="Deadline Date" value={(col) => new Date(col.deadlineDate.substring(0, 10))} />
              <ExcelColumn label="Open Date" value={(col) => new Date(col.openDate.substring(0, 10))} />
              <ExcelColumn label="Warn Date" value={(col) => new Date(col.warnDate.substring(0, 10))} />
              <ExcelColumn label="Assigned To" value="assignedToDisplayName" />
              <ExcelColumn label="Assigned To On Holiday" value={(col) => (col.assignedToOnHoliday ? 'Yes' : '')} />
              <ExcelColumn label="Notes" value="notes" />
              <ExcelColumn label={level1Name || ''} value="level1Name" />
              <ExcelColumn label={level2Name || ''} value="level2Name" />
            </ExcelSheet>
            <ExcelSheet data={data.filter((x) => GetStatus(x) === 'InProgress')} name="In Progress">
              <ExcelColumn label="Deadline Name" value="deadlineName" />
              <ExcelColumn label="Deadline Date" value={(col) => new Date(col.deadlineDate.substring(0, 10))} />
              <ExcelColumn label="Open Date" value={(col) => new Date(col.openDate.substring(0, 10))} />
              <ExcelColumn label="Warn Date" value={(col) => new Date(col.warnDate.substring(0, 10))} />
              <ExcelColumn label="Assigned To" value="assignedToDisplayName" />
              <ExcelColumn label="Assigned To On Holiday" value={(col) => (col.assignedToOnHoliday ? 'Yes' : '')} />
              <ExcelColumn label="Notes" value="notes" />
              <ExcelColumn label={level1Name || ''} value="level1Name" />
              <ExcelColumn label={level2Name || ''} value="level2Name" />
            </ExcelSheet>
            <ExcelSheet data={data.filter((x) => GetStatus(x) === 'Upcoming')} name="Upcoming">
              <ExcelColumn label="Deadline Name" value="deadlineName" />
              <ExcelColumn label="Deadline Date" value={(col) => new Date(col.deadlineDate.substring(0, 10))} />
              <ExcelColumn label="Open Date" value={(col) => new Date(col.openDate.substring(0, 10))} />
              <ExcelColumn label="Warn Date" value={(col) => new Date(col.warnDate.substring(0, 10))} />
              <ExcelColumn label="Assigned To" value="assignedToDisplayName" />
              <ExcelColumn label="Assigned To On Holiday" value={(col) => (col.assignedToOnHoliday ? 'Yes' : '')} />
              <ExcelColumn label="Notes" value="notes" />
              <ExcelColumn label={level1Name || ''} value="level1Name" />
              <ExcelColumn label={level2Name || ''} value="level2Name" />
            </ExcelSheet>
            <ExcelSheet data={data.filter((x) => GetStatus(x) === 'Snoozed')} name="Snoozed">
              <ExcelColumn label="Deadline Name" value="deadlineName" />
              <ExcelColumn label="Deadline Date" value={(col) => new Date(col.deadlineDate.substring(0, 10))} />
              <ExcelColumn label="Open Date" value={(col) => new Date(col.openDate.substring(0, 10))} />
              <ExcelColumn label="Warn Date" value={(col) => new Date(col.warnDate.substring(0, 10))} />
              <ExcelColumn label="Snooze Date" value={(col) => (col.snoozeDate ? new Date(col.snoozeDate.substring(0, 10)) : '')} />
              <ExcelColumn label="Assigned To" value="assignedToDisplayName" />
              <ExcelColumn label="Assigned To On Holiday" value={(col) => (col.assignedToOnHoliday ? 'Yes' : '')} />
              <ExcelColumn label="Notes" value="notes" />
              <ExcelColumn label={level1Name || ''} value="level1Name" />
              <ExcelColumn label={level2Name || ''} value="level2Name" />
            </ExcelSheet>
            <ExcelSheet data={dataRecent} name="Recent">
              <ExcelColumn label="Deadline Name" value="deadlineName" />
              <ExcelColumn label="Deadline Date" value={(col) => new Date(col.deadlineDate.substring(0, 10))} />
              <ExcelColumn label="Open Date" value={(col) => new Date(col.openDate.substring(0, 10))} />
              <ExcelColumn label="Warn Date" value={(col) => new Date(col.warnDate.substring(0, 10))} />
              <ExcelColumn label="Assigned To" value="assignedToDisplayName" />
              <ExcelColumn label="Assigned To On Holiday" value={(col) => (col.assignedToOnHoliday ? 'Yes' : '')} />
              <ExcelColumn label="Completed By" value="completedByDisplayName" />
              <ExcelColumn label="Completed Date" value={(col) => (col.completedDate ? new Date(col.completedDate.substring(0, 10)) : '')} />
              <ExcelColumn label="Deleted By" value="deletedByDisplayName" />
              <ExcelColumn label="Deleted Date" value={(col) => (col.deletedDate ? new Date(col.deletedDate.substring(0, 10)) : '')} />
              <ExcelColumn label="Notes" value="notes" />
              <ExcelColumn label={level1Name || ''} value="level1Name" />
              <ExcelColumn label={level2Name || ''} value="level2Name" />
            </ExcelSheet>
          </ExcelFile>
        )}
      </div>
    </>
  );
};

export default Dashboard2;

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.svg';

const NavSimple = ({ children }) => {
  return (
    <>
      <div className="h-screen flex overflow-hidden bg-gray-200 pb-10">
        <div className="flex-1 overflow-auto focus:outline-none" tabIndex={0}>
          <div className="relative w-full z-10 flex-shrink-0 flex h-16 bg-primary border-b border-gray-200">
            <Link to="/login">
              <img src={logo} title="Deadline Tracker" alt="Deadline Tracker" className="h-12 m-2" />
            </Link>
            <Link className="ml-auto px-4 text-white self-center" to="/login">
              Login
            </Link>
          </div>
          <div className="p-4">{children}</div>
        </div>
      </div>
      <div className="bg-gray-200 fixed bottom-0 w-full right-0 z-50">
        <div className="text-gray-100 bg-primary text-xs text-right space-x-2 py-1">
          <span className="p-2 inline-block whitespace-no-wrap">&copy; {new Date().getFullYear()} Deadline Tracker Limited. All rights reserved.</span>
        </div>
      </div>
    </>
  );
};

export default NavSimple;

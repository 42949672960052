import React from 'react';
import BlockButton from '../Common/BlockButton';
import PageTitle from '../Common/PageTitle';

const HelpSettings = () => {
  return (
    <>
      <PageTitle title="Help : Settings" />
      <div className="help-card">
        <article className="help">
          <div className="help-title">Changing Your Password</div>
          <div>Only you can change your password, to do so:</div>
          <div>
            1. In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-user-cog" />
              Settings
            </div>
          </div>
          <div>
            2. Click
            <div className="help-button">
              <BlockButton text="Change Password" icon="unlock-alt" type="button" buttonType="secondary" mt={0} buttonSize="small" />
            </div>
            on the right.
          </div>
          <div>3. Enter your current password, your new password and your new password again to confirm it.</div>
          <div>
            4. Click
            <div className="help-button">
              <BlockButton text="Change Password" icon="unlock-alt" type="button" mt={0} buttonSize="small" />
            </div>
            to save your new password.
          </div>
          <div className="help-note help-note-bottom">
            <span>NB: </span>Passwords must be at least 8 characters long, having at least 1 upper case letter, 1 lower case letter, number and special symbol.
          </div>
        </article>
      </div>

      <div className="help-card">
        <article className="help">
          <div className="help-title">Changing Your Email Settings</div>
          <div>To change what type of emails you receive:</div>
          <div>
            1. In left navigation menu, select
            <div className="help-nav">
              <i className="fad fa-user-cog" />
              Settings
            </div>
          </div>
          <div>2. Under 'Email Settings' there are 2 check boxes and these will represent the options made at sign up (or most recent email setting change).</div>
          <div>
            4. Click
            <div className="help-button">
              <BlockButton text="Save" icon="save" type="button" mt={0} buttonSize="small" />
            </div>
            when done.
          </div>
          <div className="help-note help-note-bottom">
            <span>NB: </span>If you untick ‘Please send me email notifications about deadlines’ then you will lose some of the benefit of the site.
          </div>
        </article>
      </div>
    </>
  );
};

export default HelpSettings;

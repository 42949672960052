import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { stripeAPIKey } from '../../config';
import React from 'react';
import CardChangeForm from './CardChangeForm';
import PageTitle from '../Common/PageTitle';

const stripePromise = loadStripe(stripeAPIKey());

const SubscriptionChangeCard = ({ history }) => {
  const handleOnOk = () => {
    history.push('/subscription');
  };

  return (
    <>
      <PageTitle title="Subscription - Change Payment Method" />

      <Elements stripe={stripePromise}>
        <CardChangeForm onOk={handleOnOk} />
      </Elements>
    </>
  );
};

export default SubscriptionChangeCard;

import React, { useContext } from 'react';
import FontAwesomeLink from '../Common/FontAwesomeLink';
import { UserContext } from '../../Contexts/UserContext';
import BlockButton from '../Common/BlockButton';

const HelpDeadlinesCreate = ({ inline = false, forceShowAll = false }) => {
  const { userIsManager } = useContext(UserContext);

  const showManager = userIsManager || forceShowAll;

  return (
    <>
      {showManager && (
        <div className={inline ? '' : 'help-card'}>
          <article className="help">
            <div className="help-title">Creating a Deadline</div>
            <div>
              1. In left navigation menu, select
              <div className="help-nav">
                <i className="fad fa-plus" />
                New Deadline
              </div>
            </div>
            <div>2. The ‘DEADLINE NAME’ is the name for the deadline</div>
            <div>
              3. If you have categories as part of your subscription you can pick the category/categories for the deadline. If you need to add a new one then click on the <FontAwesomeLink icon="plus-square" m={0} href="#" /> button.
            </div>
            <div>4. Pick the frequency the deadline repeats.</div>
            <div>5. Depending on which repeat option is chosen you may then need to state the ‘DEADLINE TYPE’ such as how this repeats e.g. Last Monday, 20th of the month, etc...</div>
            <div>6. The 'NOT BEFORE' date is important if you do not what the deadline to start immediately. If you want the deadline to be in a future month you need to change the not before date to the month you want the deadline to start.</div>
            <div>7. The 'NOT AFTER' date is optional and if left blank the deadline will run until it is deleted or edited. If you want the deadline to finish after a certain date then enter that date here.</div>
            <div className="help-note">
              <span>NB: </span>'NOT BEFORE' and 'NOT AFTER' only apply to repeating deadlines.
            </div>
            <div>8. The ‘OPEN PERIOD’ is the date that you are able to start work on a deadline, when it is active from, so need to enter the months and days before the deadline that this is open.</div>
            <div>9. The ‘WARN PERIOD’ is how long before the deadline do you want to be warned about it if it is still active.</div>
            <div>10. In the ‘NOTES’ section you can add notes related to this deadline </div>
            <div>
              11. Click the
              <div className="help-button">
                <BlockButton text="Calculate Preview" icon="calculator" type="button" buttonType="secondary" mt={0} buttonSize="small" />
              </div>
              button to preview the next 3 instances (if applicable) when this deadline will become open, send a warning and the deadline date.
            </div>
            <div className="help-note">
              <span>NB: </span>Deadlines are synced to the working week so if you have selected the end of the month it will move to the last working day if that happens to fall on a weekend.
            </div>
            <div>
              12. If these are not correct you can change the dates or repeat type and click
              <div className="help-button">
                <BlockButton text="Calculate Preview" icon="calculator" type="button" buttonType="secondary" mt={0} buttonSize="small" />
              </div>
              again to see an updated version.
            </div>
            <div>
              13. By clicking the
              <div className="help-button-small">
                <BlockButton text="Save" icon="save" type="button" mt={0} buttonSize="small" />
              </div>
              button, this will save the deadline and take you back to the deadline dashboard.
            </div>
            <div>
              14. By clicking the
              <div className="help-button-small">
                <BlockButton text="Save + New" icon="save" type="button" mt={0} buttonSize="small" />
              </div>
              button, this will save the deadline and allow you to enter another deadline.
            </div>
            <div>
              15. By clicking the
              <div className="help-button-small">
                <BlockButton text="Cancel" icon="arrow-square-left" type="button" buttonType="secondary" mt={0} buttonSize="small" />
              </div>
              button this will cancel this deadline and take you back to the deadline dashboard.
            </div>
          </article>
        </div>
      )}
    </>
  );
};

export default HelpDeadlinesCreate;

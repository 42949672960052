import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { apiOrganisationEnableDisable, apiOrganisations, apiOrganisationSwitchTo } from '../../../APIs/organisation';
import { ExpiryDateClass, ExpiryDateDisplayDate } from '../../../Helpers/dateHelpers';
import BlockButton from '../../Common/BlockButton';
import FontAwesomeLink from '../../Common/FontAwesomeLink';
import OptionsDropdown from '../../Common/OptionsDropdown';
import PageTitle from '../../Common/PageTitle';
import { today } from '../../../config';

const OrganisationList = () => {
  const [loading, setLoading] = useState(true);
  const [licenseFilter, setLicenseFilter] = useState(localStorage.getItem('licenseFilter') || '*');
  const [freeTrialFilter, setFreeTrialFilter] = useState(localStorage.getItem('freeTrialFilter') || '*');
  const [expiryFilter, setExpiryFilter] = useState(localStorage.getItem('expiryFilter') || '*');
  const [data, setData] = useState([]);

  const GetData = useCallback(async () => {
    setLoading(true);
    var result = await apiOrganisations();

    setData(result);
    setLoading(false);
  }, []);

  useEffect(() => {
    GetData();
  }, [GetData]);

  const disableOrganisation = async (id) => {
    await apiOrganisationEnableDisable(id, false);
    GetData();
  };

  const enableOrganisation = async (id) => {
    await apiOrganisationEnableDisable(id, true);
    GetData();
  };

  const switchToOrganisation = async (id) => {
    await apiOrganisationSwitchTo(id);

    // Clear filter
    localStorage.removeItem('DT_Filter');
    localStorage.removeItem('DT_FilterOptions');

    //Hard reload
    window.location.assign('/');
  };

  const filteredData = useCallback(() => {
    const mNow = moment(today());

    return data
      .map((item) => {
        if (licenseFilter !== '*' && `${item.licenseTypeId}` !== licenseFilter) {
          return null;
        }

        if (freeTrialFilter !== '*' && (item.freeTrial ? 'YES' : 'NO') !== freeTrialFilter) {
          return null;
        }

        if (expiryFilter !== '*') {
          let status = 'NoExpiry';
          if (item.licenseExpiryDateFormatted !== '31/12/2999') {
            const mItem = moment(item.licenseExpiryDateFormatted, 'DD/MM/YYYY');

            if (mItem.isAfter(mNow)) {
              status = 'Expiring';
            } else {
              if (mItem.isAfter(mNow.subtract(7, 'days'))) {
                status = 'ExpiredRecently';
              } else {
                status = 'Expired';
              }
            }
          }

          if (status !== expiryFilter) {
            return null;
          }
        }

        return item;
      })
      .filter((x) => x);
  }, [licenseFilter, freeTrialFilter, expiryFilter, data]);

  const freeTrialFilterChange = (e) => {
    const value = e.target.value;
    localStorage.setItem('freeTrialFilter', value);
    setFreeTrialFilter(value);
  };

  const licenseFilterChange = (e) => {
    const value = e.target.value;
    localStorage.setItem('licenseFilter', value);
    setLicenseFilter(value);
  };

  const expiryFilterChange = (e) => {
    const value = e.target.value;
    localStorage.setItem('expiryFilter', value);
    setExpiryFilter(value);
  };

  return (
    <>
      <PageTitle title="Organisations">
        <FontAwesomeLink icon="plus-square" text="New" textAlways href={`/admin/organisation/Add`} />
      </PageTitle>
      <div className="card">
        <div className="flex flex-col sm:flex-row w-full mb-4 sm:space-x-1">
          <div className="w-full shadow-md">
            <select
              className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 sm:text-sm sm:leading-5"
              value={licenseFilter}
              onChange={licenseFilterChange}>
              <option value="*">License: Any</option>
              <option value={1}>License: Free</option>
              <option value={2}>License: Lite</option>
              <option value={3}>License: Standard</option>
              <option value={4}>License: Pro</option>
            </select>
          </div>
          <div className="w-full shadow-md">
            <select
              className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 sm:text-sm sm:leading-5"
              value={freeTrialFilter}
              onChange={freeTrialFilterChange}>
              <option value="*">Trial: Any</option>
              <option value="YES">Free Trail</option>
              <option value="NO">License</option>
            </select>
          </div>
          <div className="w-full shadow-md">
            <select
              className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 sm:text-sm sm:leading-5"
              value={expiryFilter}
              onChange={expiryFilterChange}>
              <option value="*">Expiry: Any</option>
              <option value="Expired">Expired</option>
              <option value="ExpiredRecently">Expired Recently</option>
              <option value="Expiring">Expiring</option>
              <option value="NoExpiry">No Expiry</option>
            </select>
          </div>
        </div>
        <table className="w-full stripe">
          <thead>
            <tr className="bg-indigo-800 text-indigo-50 rounded-md">
              <th className="w-12 rounded-tl-md"></th>
              <th className="w-auto py-2 text-left">Organisation</th>
              <th className="w-auto py-2 whitespace-no-wrap">License</th>
              <th className="w-auto py-2 whitespace-no-wrap hidden sm:table-cell">Trial</th>
              <th className="w-auto py-2 whitespace-no-wrap hidden sm:table-cell">Expiry Date</th>
              <th className="w-auto py-2 text-left whitespace-no-wrap hidden md:table-cell">Level 1</th>
              <th className="w-auto py-2 text-left whitespace-no-wrap hidden md:table-cell">Level 2</th>
              <th className="w-auto py-2 text-left whitespace-no-wrap hidden md:table-cell">MFA</th>
              <th className="w-12 rounded-tr-md"></th>
            </tr>
          </thead>
          <tbody>
            {filteredData().map((item, index) => {
              return (
                <tr key={index} className={`${!item.isEnabled && 'text-gray-500'}`}>
                  <td className="py-2 pr-0">
                    <FontAwesomeLink icon="pen-square" size="2x" href={`/admin/organisation/${item.id}`} />
                  </td>
                  <td>
                    {item.name} {!item.isEnabled && <span>(Disabled)</span>}
                  </td>
                  <td className="text-center">
                    {item.licenseName ? item.licenseName : <span className="font-semibold">MISSING</span>}
                    {item.freeTrial && <div className="sm:hidden whitespace-no-wrap"> Free Trial</div>}
                    <div className={`whitespace-no-wrap sm:hidden ${ExpiryDateClass(item.licenseExpiryDateFormatted)}`}>{ExpiryDateDisplayDate(item.licenseExpiryDate)}</div>
                  </td>
                  <td className="text-center hidden sm:table-cell">{item.freeTrial && 'Yes'}</td>
                  <td className={`text-center whitespace-no-wrap hidden sm:table-cell ${ExpiryDateClass(item.licenseExpiryDateFormatted)}`}>{ExpiryDateDisplayDate(item.licenseExpiryDate)}</td>
                  <td className="hidden md:table-cell">{item.level1Name}</td>
                  <td className="hidden md:table-cell">{item.level2Name}</td>
                  <td className="hidden md:table-cell">{item.mfaEnabled && 'Yes'}</td>
                  <td>
                    <OptionsDropdown>
                      {item.isEnabled ? (
                        <>
                          <button className="text-gray-100 focus:outline-none block p-2" onClick={() => switchToOrganisation(item.id)}>
                            Switch To
                          </button>
                          <button className="text-gray-100 focus:outline-none block p-2" onClick={() => disableOrganisation(item.id)}>
                            Disable
                          </button>
                        </>
                      ) : (
                        <button className="text-gray-100 focus:outline-none block p-2" onClick={() => enableOrganisation(item.id)}>
                          Enable
                        </button>
                      )}
                    </OptionsDropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <BlockButton text="Reload" onClick={() => GetData()} icon="hurricane" iconSpin={loading} mt={2} />
      </div>
    </>
  );
};

export default OrganisationList;

import React, { useState, useEffect, useContext } from 'react';
import LoadingCard from '../Common/LoadingCard';
import { useFormik } from 'formik';
import { InputText, InputTextArea } from '../Common/Inputs';
import { UserContext } from '../../Contexts/UserContext';
import { licenseTypes, stripeSubscriptionStatusCancelled, stripeSubscriptionStatusMissing } from '../../Helpers/constants';
import { ExpiryDateDisplayDate } from '../../Helpers/dateHelpers';
import BlockButton from '../Common/BlockButton';
import swal from 'sweetalert';
import { apiLicenseCancel, apiLicenseCheck, apiLicenseReactivate } from '../../APIs/license';

const defaultData = {
  licenseType: '',
  licensePrice: '',
  licenseFeatures: '',
  licenseExpiryDate: '',
  card: '',
};

const SubscriptionDetails = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(false);
  const [subData, setSubData] = useState(undefined);
  const [featureCount, setFeatureCount] = useState(3);
  const { licenseTypeId, licenseExpiryDate, licenseFreeTrial, LoadUserSettings, HasLicenseExpired, HasLicenseExpiryDate } = useContext(UserContext);

  useEffect(() => {
    const LoadData = async () => {
      setLoading(true);

      let card = '';
      if (!licenseFreeTrial) {
        const ssr = await apiLicenseCheck();
        setSubData(ssr);

        if (ssr.cardLast4) {
          card = `**** **** **** ${ssr.cardLast4} - Expires ${ssr.cardExpiryMonth}/${ssr.cardExpiryYear}`;
        }
      }

      const license = licenseTypes.find((x) => parseInt(x.id) === licenseTypeId);

      if (!license) {
        setError(true);
        return;
      }

      setFeatureCount(license.features.length);

      const plusVAT = license.price === '0' ? '' : '+VAT ';

      setData({
        licenseType: `${license.name}${licenseFreeTrial ? ' - Free Trial' : ''}`,
        licensePrice: `£${license.price}${plusVAT} /mo`,
        licenseExpiryDate: ExpiryDateDisplayDate(licenseExpiryDate, 'No Expiry'),
        licenseFeatures: license.features.join('\r\n'),
        card,
      });

      setLoading(false);
    };

    LoadData();
  }, [licenseTypeId, licenseExpiryDate, licenseFreeTrial]);

  const handlePurchaseSubscription = () => {
    history.push('/subscription/choose');
  };

  const handleChangeSubscription = () => {
    history.push('/subscription/change');
  };

  const handleReactivateSubscription = async () => {
    setLoading(true);
    await apiLicenseReactivate();
    await LoadUserSettings();
    setLoading(false);
  };

  const handleCancelSubscription = () => {
    const text = subData.status === stripeSubscriptionStatusMissing ? null : `You'll still be able to access Deadline Tracker until the end of you current billing period, which is ${ExpiryDateDisplayDate(subData.currentPeriodEnd)}`;

    swal({
      title: 'Are You Sure?',
      text: text,
      icon: 'warning',
      buttons: {
        no: { text: 'No', value: false },
        cancelSub: { text: 'Yes, Cancel', value: true },
      },
    }).then(async (value) => {
      if (value) {
        setLoading(true);
        await apiLicenseCancel();
        await LoadUserSettings();
        setLoading(false);
      }
    });
  };

  const handleChangePaymentMethod = () => {
    history.push('/subscription/payment-method');
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
  });

  const FormButtons = () => {
    if (licenseFreeTrial || HasLicenseExpired()) {
      return <BlockButton text="Purchase Subscription" icon="credit-card" type="button" onClick={handlePurchaseSubscription} />;
    }

    if (subData.status === stripeSubscriptionStatusCancelled) {
      return <BlockButton text="Re-activate Subscription" icon="check" type="button" onClick={handleReactivateSubscription} />;
    }

    if (licenseTypeId === 1 && subData.status === stripeSubscriptionStatusMissing) {
      if (HasLicenseExpiryDate()) {
        return <BlockButton text="Purchase Subscription" icon="credit-card" type="button" onClick={handlePurchaseSubscription} />;
      }

      return (
        <>
          <BlockButton text="Purchase Subscription" icon="credit-card" type="button" onClick={handlePurchaseSubscription} />
          <BlockButton text="Cancel Subscription" icon="power-off" buttonType="secondary" type="button" onClick={handleCancelSubscription} />
        </>
      );
    }

    return (
      <>
        <BlockButton text="Change Subscription" icon="exchange-alt" type="button" onClick={handleChangeSubscription} />
        <BlockButton text="Change Payment Method" icon="credit-card" buttonType="secondary" type="button" onClick={handleChangePaymentMethod} />
        <BlockButton text="Cancel Subscription" icon="power-off" buttonType="secondary" type="button" onClick={handleCancelSubscription} />
      </>
    );
  };

  return (
    <LoadingCard loading={loading} title="Subscription Details" help={licenseFreeTrial || HasLicenseExpired() ? '/help/subscription-choose' : '/help/subscription-change'}>
      {error && <p>Unable to find subscription details, please contact supports.</p>}
      {data && (
        <form>
          <InputText formik={formik} label="Subscription" field="licenseType" disabled />
          <InputText formik={formik} label="Price" field="licensePrice" disabled />
          <InputText formik={formik} label="Expiry Date" field="licenseExpiryDate" disabled />
          <InputTextArea formik={formik} label="Features" field="licenseFeatures" disabled rows={featureCount} />
          {data.card && <InputText formik={formik} label="Card" field="card" disabled />}
          {HasLicenseExpired() && <div className="bg-red-800 text-center text-gray-100 p-2">Your {licenseFreeTrial ? 'Free Trial' : 'Subscription'} Has Expired</div>}
          <FormButtons />
        </form>
      )}
    </LoadingCard>
  );
};

export default SubscriptionDetails;

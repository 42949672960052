import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { configureAxios } from './APIs/axiosHelper';
import { gaTrackingId } from './config';
import Layout from './layouts/Layout';

configureAxios();

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId()}`}></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', '${gaTrackingId()}');
      `}
        </script>
      </Helmet>
      <Router>
        <ToastContainer position="top-right" hideProgressBar />
        <Layout />
      </Router>
    </HelmetProvider>
  );
}

export default App;

import { apiUrl } from '../config';
import axios from 'axios';

export const apiUsers = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/Manager/UsersList',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiRepeatTypes = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/User/RepeatTypes',
    baseURL: apiUrl(),
  });

  return result.data;
};

export const apiDeadlineTypes = async () => {
  const result = await axios({
    method: 'get',
    url: 'v1/User/DeadlineTypes',
    baseURL: apiUrl(),
  });

  return result.data;
};

import { apiUrl } from '../config';
import axios from 'axios';
import { nullToUndefined } from '../Helpers/nullHelpers';
import moment from 'moment';
import { formattedDate } from '../Helpers/dateHelpers';

export const apiDeadlinePreview = async (fromDate, toDate, repeatTypeCode, repeatStep, deadlineTypeCode, day, dayOfWeek, openMonths, openDays, warnMonths, warnDays, adjustDays, workingDayRule) => {
  fromDate = formattedDate(fromDate);
  toDate = formattedDate(toDate);

  const result = await axios({
    method: 'get',
    url: 'v1/Manager/DeadlineInstances/Calculate',
    baseURL: apiUrl(),
    params: { fromDate: parseDate(fromDate), toDate: parseDate(toDate), repeatTypeCode, repeatStep, deadlineTypeCode, day, dayOfWeek, openMonths, openDays, warnMonths, warnDays, adjustDays, workingDayRule },
  });

  return result.data;
};

export const apiDeadlineSaveFormik = async (values) => {
  let fromDate = values.fromDate;
  if (typeof fromDate === 'string') {
    const m = moment(fromDate, ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD MM YYYY', 'DDMMYYYY', 'DD/MM/YY', 'DD-MM-YY', 'DD MM YY', 'DDMMYY'], 'en-gb', true);
    if (m.isValid) {
      fromDate = m;
    }
  }

  let toDate = values.toDate;
  if (toDate && typeof fromDate === 'string') {
    const m = moment(toDate, ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD MM YYYY', 'DDMMYYYY', 'DD/MM/YY', 'DD-MM-YY', 'DD MM YY', 'DDMMYY'], 'en-gb', true);
    if (m.isValid) {
      toDate = m;
    }
  }

  let level1Ids = [];
  if (values.level1Id) {
    level1Ids.push(values.level1Id);
  }
  Object.entries(values).forEach((entity) => {
    const [key, value] = entity;
    if (key.startsWith('level1_') && value) {
      level1Ids.push(key.replace('level1_', ''));
    }
  });

  let level2Ids = [];
  if (values.level2Id) {
    level2Ids.push(values.level2Id);
  }
  Object.entries(values).forEach((entity) => {
    const [key, value] = entity;
    if (key.startsWith('level2_') && value) {
      level2Ids.push(key.replace('level2_', ''));
    }
  });

  return await apiDeadlineSave(
    values.id,
    values.name,
    level1Ids,
    level2Ids,
    fromDate,
    toDate,
    values.repeatTypeCode,
    values.repeatStep,
    values.deadlineTypeCode,
    values.day,
    values.dayOfWeek,
    values.notes,
    values.openDays,
    values.openMonths,
    values.warnDays,
    values.warnMonths,
    values.adjustDays,
    values.assignedTo,
    values.workingDayRule
  );
};

export const apiDeadlineSave = async (id, name, level1Ids, level2Ids, fromDate, toDate, repeatTypeCode, repeatStep, deadlineTypeCode, day, dayOfWeek, notes, openDays, openMonths, warnDays, warnMonths, adjustDays, assignedTo, workingDayRule) => {
  fromDate = formattedDate(fromDate);
  toDate = formattedDate(toDate);

  try {
    const result = await axios({
      method: 'post',
      url: 'v1/Manager/Deadline/Save',
      baseURL: apiUrl(),
      data: {
        id,
        name,
        level1Ids,
        level2Ids,
        fromDate: parseDate(fromDate),
        toDate: parseDate(toDate),
        repeatTypeCode,
        repeatStep,
        deadlineTypeCode,
        day,
        dayOfWeek,
        notes,
        openDays,
        openMonths,
        warnDays,
        warnMonths,
        adjustDays,
        assignedTo,
        workingDayRule,
      },
    });

    return result.data;
  } catch (err) {
    if (typeof err.data === 'string') {
      return { isValid: false, errors: err.data };
    }
    return err.data;
  }
};

export const apiDeadlineGet = async (id) => {
  const result = await axios({
    method: 'get',
    url: `v1/Manager/Deadline/${id}`,
    baseURL: apiUrl(),
  });

  return nullToUndefined(result.data);
};

const parseDate = (input) => {
  if (input instanceof moment) {
    return input.format();
  }
  return input;
};

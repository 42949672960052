import React from 'react';
import { Helmet } from 'react-helmet-async';
import { gaTrackingIdConversion } from '../../config';
import PageTitle from '../Common/PageTitle';

const SignUpComplete = ({ match }) => {
  const getLevel = () => {
    if (match && match.params && match.params.level) {
      switch (match.params.level) {
        case '1':
          return 'Free';
        case '2':
          return 'Lite';
        case '3':
          return 'Standard';
        case '4':
          return 'Pro';
        default:
          return 'Unknown';
      }
    }
    return 'Unknown';
  };

  return (
    <>
      <Helmet>
        <script>
          {`
            gtag('event', 'page_view', {
              'page_title': 'Deadline Tracker',
              'page_path': '/signedup/${match?.params?.level || ''}',
              'send_to': '${gaTrackingIdConversion()}'
            })

            gtag('event', 'sign_up', {
              'event_label': '${getLevel()}',
              'event_category': 'Form',
              'send_to': '${gaTrackingIdConversion()}'
              });
          `}
        </script>
      </Helmet>

      <div className="flex absolute w-screen top-0 left-0 h-screen align-middle">
        <div className="m-auto w-full sm:w-3/4 md:w-1/2">
          <PageTitle title="Sign Up" withLogoCard />
          <div className="card p-4">
            <div className="font-extrabold">Thank you for signing up.</div>
            <p>Please check your email for details of how to log in.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpComplete;

import React from 'react';
import { Link } from 'react-router-dom';

const FontAwesomeLink = ({ href, text, icon, spin, fullWidth, size, colour, m = 2, textAlways = false, inNewTab = false }) => {
  return (
    <Link target={inNewTab ? '_blank' : '_self'} to={href} className={`${colour || 'text-indigo-800'} action-button text-left px-0 mx-${m} ${fullWidth ? 'w-full block' : ''}`}>
      <i className={`fad fa-fw fa-${icon} ${size ? `fa-${size}` : 'fa-lg'} mr-0 ${spin ? 'fa-spin' : ''}`} />
      {text && <span className={textAlways ? 'px-1' : 'hidden md:inline-block md:px-1'}>{text}</span>}
    </Link>
  );
};

export default FontAwesomeLink;

import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { apiHoliday, apiHolidaySave, apiUsers } from '../../APIs/user';
import { UserContext } from '../../Contexts/UserContext';
import BlockButton from '../Common/BlockButton';
import { InputDate, InputSelect } from '../Common/Inputs';
import LoadingCard from '../Common/LoadingCard';

const HolidayEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [users, setUsers] = useState([]);
  const { userIsManager, userId } = useContext(UserContext);
  const addMode = !match.params.id;

  useEffect(() => {
    const LoadData = async () => {
      if (userIsManager) {
        setUsers(await apiUsers());
      }

      if (!addMode) {
        const result = await apiHoliday(match.params.id);

        if (result) {
          result.fromDate = new Date(result.fromDate);
          if (result.toDate) {
            result.toDate = new Date(result.toDate);
          }
        }
        setData(result);
      }

      setLoading(false);
    };

    if (addMode) {
      setData({
        userId: userId,
        fromDate: undefined,
        toDate: undefined,
      });
    }

    LoadData();
  }, [addMode, match.params.id, userId, userIsManager]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: Yup.object({
      fromDate: Yup.date().required('Required').min('2020/01/01', 'Invalid').typeError('Invalid'),
      toDate: Yup.date().nullable().min(Yup.ref('fromDate'), 'Invalid').typeError('Invalid'),
    }),
    onSubmit: async (values) => {
      const id = match.params.id || null;
      await apiHolidaySave(id, values.userId, values.fromDate, values.toDate);

      history.push('/holidays');
    },
  });

  const cancel = () => {
    history.push('/holidays');
  };

  return (
    <LoadingCard loading={loading} title={`${addMode ? 'New' : 'Edit'} Holiday`}>
      {data && (
        <form onSubmit={formik.handleSubmit}>
          {userIsManager && <InputSelect formik={formik} field="userId" data={users} label="For" textField="displayName" />}

          <div className="flex flex-wrap">
            <InputDate formik={formik} label="From" field="fromDate" outerClassName="w-1/2" />
            <InputDate formik={formik} label="To" field="toDate" outerClassName="w-1/2" />
          </div>

          <div className="inline-flex w-full space-x-1">
            <BlockButton text="Save" icon="save" type="submit" groupPosition="left" />
            <BlockButton text="Cancel" icon="arrow-square-left" buttonType="secondary" groupPosition="right" type="button" onClick={() => cancel()} />
          </div>
        </form>
      )}
    </LoadingCard>
  );
};

export default HolidayEdit;

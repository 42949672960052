import React, { useCallback, useContext, useEffect, useState } from 'react';
import { apiUserEnableDisable, apiUsers } from '../../../APIs/user';
import { UserContext } from '../../../Contexts/UserContext';
import { Pascal2Title } from '../../../Helpers/stringHelpers';
import BlockButton from '../../Common/BlockButton';
import FontAwesomeButton from '../../Common/FontAwesomeButton';
import FontAwesomeLink from '../../Common/FontAwesomeLink';
import OptionsDropdown from '../../Common/OptionsDropdown';
import PageTitle from '../../Common/PageTitle';
import { toast } from 'react-toastify';
import HelpUsersCreate from '../../help/HelpUsersCreate';
import HelperCard from '../../Common/HelperCard';

const UserList = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { licenseUserLimit, userIsGlobalAdmin } = useContext(UserContext);

  const activeUserCount = () => {
    return data.filter((x) => x.isEnabled).length;
  };

  const GetData = useCallback(async () => {
    setLoading(true);
    var result = await apiUsers();

    setData(result);
    setLoading(false);
  }, []);

  useEffect(() => {
    GetData();
  }, [GetData]);

  const enableDisableUser = async (id, enable) => {
    try {
      await apiUserEnableDisable(id, enable);

      const user = data.find((x) => x.id === id);
      if (user) {
        user.isEnabled = enable;
        setData([...data]);
      }
    } catch (ex) {
      if (ex.status === 400 && ex.data === 'Last Admin') {
        toast.error('Unable to delete only admin user');
      }
    }
  };

  const handleNewUser = () => {
    if (!activeUserCount) {
      return;
    }

    if (activeUserCount() >= licenseUserLimit && !userIsGlobalAdmin) {
      history.push('/subscription/upgrade-required/user-limit');
    } else {
      history.push('/admin/user/Add');
    }
  };

  const handleEnableDisableUser = (id, enable) => () => {
    if (enable) {
      if (activeUserCount() >= licenseUserLimit && !userIsGlobalAdmin) {
        history.push('/subscription/upgrade-required/user-limit');
        return;
      }
    }
    enableDisableUser(id, enable);
  };

  return (
    <>
      <PageTitle title="User List" help="/help/users">
        <FontAwesomeButton icon="plus-square" text="New" textAlways onClick={handleNewUser} />
      </PageTitle>
      <HelperCard show={!loading && data.length === 1} more={<HelpUsersCreate inline />}>
        Please click the <FontAwesomeLink icon="plus-square" text="New" textAlways m={0} href="#" /> link above to add your first User.
      </HelperCard>

      <div className="card">
        <table className="w-full stripe headless">
          <thead>
            <tr>
              <th className="w-auto"></th>
              <td className="w-full"></td>
              <th className="w-auto"></th>
              <th className="w-auto"></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index} className={`${!item.isEnabled && 'text-gray-500'}`}>
                  <td className="py-3">
                    <FontAwesomeLink icon="pen-square" size="2x" href={`/admin/user/${item.id}`} />
                  </td>
                  <td>
                    {item.displayName} {!item.isEnabled && <span className="hidden sm:inline">(Disabled)</span>}
                  </td>
                  <td className="pl-2 pr-4 whitespace-no-wrap">
                    <span className="hidden md:inline">Role: </span>
                    {Pascal2Title(item.role)}
                  </td>
                  <td>
                    <OptionsDropdown>
                      {item.isEnabled ? (
                        <button className="text-gray-100 focus:outline-none block p-2" onClick={handleEnableDisableUser(item.id, false)}>
                          Disable
                        </button>
                      ) : (
                        <button className="text-gray-100 focus:outline-none block p-2" onClick={handleEnableDisableUser(item.id, true)}>
                          Enable
                        </button>
                      )}
                    </OptionsDropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <BlockButton text="Reload" onClick={() => GetData()} icon="hurricane" iconSpin={loading} mt={2} />
      </div>
    </>
  );
};

export default UserList;

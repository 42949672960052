import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { apiLogout, apiMFAResend, apiMFAVerify } from '../../APIs/auth';
import withRedirect from '../../hoc/withRedirect';
import BlockButton from '../Common/BlockButton';
import CardErrors from '../Common/CardErrors';
import InputText from '../Common/InputText';
import PageTitle from '../Common/PageTitle';

const MFACheck = ({ setRedirect, setRedirectUrl }) => {
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      accessToken: '',
    },
    validationSchema: Yup.object({
      accessToken: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      var result = await apiMFAVerify((values?.accessToken || '').trim());
      setLoading(false);

      if (result.successful) {
        setRedirectUrl('/');
        setRedirect(true);
        return;
      }

      setError('Verification Failed!');
    },
  });

  const logOut = async () => {
    setLoading(true);
    await apiLogout();

    setRedirectUrl('/');
    setRedirect(true);
  };

  const resend = async () => {
    setLoading(true);
    await apiMFAResend();
    setLoading(false);
  };

  return (
    <div className="flex absolute w-screen top-0 left-0 h-screen align-middle">
      <div className="m-auto w-full sm:w-3/4 md:w-1/2">
        <PageTitle title="Access Token Required" withLogoCard />
        <div className="card">
          <p className="p-2 pb-4 text-indigo-900">Please check your email for your access token.</p>
          <form onSubmit={formik.handleSubmit}>
            <InputText formik={formik} label="Access Token" field="accessToken" icon="unlock-alt" />

            <CardErrors errors={error} />
            <BlockButton text="Verify" iconSpin={loading} icon={loading ? 'hurricane' : null} disabled={loading} />
            <BlockButton text="Resend" type="button" onClick={resend} iconSpin={loading} icon={loading ? 'hurricane' : null} disabled={loading} buttonType="secondary" />
            <BlockButton text="Log out" type="button" onClick={logOut} iconSpin={loading} icon={loading ? 'hurricane' : null} disabled={loading} buttonType="secondary" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRedirect(MFACheck);

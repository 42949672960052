import React from 'react';

const BlockButton = ({ type = 'submit', text, onClick, icon, iconSize = 'lg', iconSpin = false, mt = 2, mb = 0, buttonType = 'primary', groupPosition = 'solo', disabled = false, buttonSize = 'normal', wrapperClass = '' }) => {
  let buttonColour = 'text-white bg-indigo-800 hover:bg-indigo-700 focus:border-indigo-900 focus:shadow-outline-indigo active:bg-indigo-900';
  if (buttonType === 'secondary') {
    buttonColour = 'text-white bg-indigo-400 hover:bg-indigo-300 focus:border-indigo-500 focus:shadow-outline-indigo active:bg-indigo-500';
  }
  if (buttonType === 'danger') {
    buttonColour = 'text-white bg-red-700 hover:bg-red-600 focus:border-red-800 focus:shadow-outline-indigo active:bg-red-800';
  }

  if (disabled) {
    buttonColour = `${buttonColour} opacity-50 cursor-not-allowed`;
  }

  let rounding = 'rounded-md';
  switch (groupPosition) {
    case 'left':
      rounding = 'rounded-l-md';
      break;
    case 'right':
      rounding = 'rounded-r-md';
      break;
    case 'center':
      rounding = '';
      break;
    default:
      break;
  }

  return (
    <span className={`inline-flex rounded-md shadow-sm w-full mt-${mt} mb-${mb} ${wrapperClass}`}>
      <button
        disabled={disabled}
        onClick={onClick}
        type={type}
        className={`w-full justify-center inline-flex items-center ${
          buttonSize === 'small' ? 'px-2 py-1 text-sm' : 'px-4 py-2 text-md'
        }  border border-transparent leading-5 font-medium ${rounding} focus:outline-none transition ease-in-out duration-150 ${buttonColour}`}>
        {icon && <i className={`fad fa-fw fa-${icon} fa-${iconSize} mr-1 ${iconSpin ? 'fa-spin' : ''}`} />}
        {text}
      </button>
    </span>
  );
};

export default BlockButton;

import React, { useEffect, useState } from 'react';
import StatusCard from '../Common/StatusCard';
import { GetStatus } from '../../Helpers/deadlineHelpers';
import LoadingSpinner from '../Common/LoadingSpinner';

const CompletedStatusFilter = ({ data, initialLoad, tab, setTab }) => {
  const [allCount, setAllCount] = useState(<LoadingSpinner />);
  const [completedCount, setCompletedCount] = useState(<LoadingSpinner />);
  const [deletedCount, setDeleteCount] = useState(<LoadingSpinner />);

  useEffect(() => {
    if (!data) {
      return;
    }

    let deleted = 0;
    let completed = 0;

    data.forEach((x) => {
      switch (GetStatus(x)) {
        case 'Deleted':
          deleted += 1;
          break;
        default:
          completed += 1;
          break;
      }
    });

    setAllCount(data.length);
    setDeleteCount(deleted);
    setCompletedCount(completed);
  }, [data, tab, setTab]);

  const tabChange = (e) => {
    setTab(e.target.value);
  };

  return (
    <>
      <div className="md:hidden w-full shadow-md mb-2">
        {!initialLoad && (
          <select className="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 sm:text-sm sm:leading-5" value={tab} onChange={tabChange}>
            <option value="All">Status: All ({allCount})</option>
            <option value="Completed">Status: Completed ({completedCount})</option>
            <option value="Deleted">Status: Deleted ({deletedCount})</option>
          </select>
        )}
      </div>
      <div className="hidden md:flex flex-col sm:flex-row w-full mb-2 sm:space-x-2">
        {!initialLoad && (
          <>
            <StatusCard title="All" count={allCount} showAlways className="text-gray-600 ml-0" selected={tab === 'All'} onClick={() => setTab('All')} />
            <StatusCard title="Completed" count={completedCount} showAlways className="text-green-700" selected={tab === 'Completed'} onClick={() => setTab('Completed')} />
            <StatusCard title="Deleted" count={deletedCount} showAlways className="text-red-700" selected={tab === 'Deleted'} onClick={() => setTab('Deleted')} />
          </>
        )}
      </div>
    </>
  );
};

export default CompletedStatusFilter;
